import { Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { UnitType, VariantShortFragment } from "../../lib/graphql";
import { FormItemProps } from "antd/lib/form";
import { get } from "lodash";
import { formatUnitValue } from "../../lib/formats";
import { Form, InputNumber } from "../form";

interface QuantityFormItemProps extends Pick<FormItemProps, "name" | "rules"> {
  variant: Pick<VariantShortFragment, "variationValue" | "variationUnit">;
}

export function QuantityFormItem({
  name,
  variant,
  rules,
}: QuantityFormItemProps) {
  if (!name) return null;

  return (
    <Row gutter={8} align="middle">
      <Col span={10}>
        <Form.Item
          name={name}
          rules={rules}
          required
          compact
          validateTrigger="onBlur"
        >
          <InputNumber
            min={0}
            max={99999999}
            precision={
              variant.variationUnit.unitType == UnitType.Unitary ? 1 : 4
            }
          />
        </Form.Item>
      </Col>
      <Col span={14}>
        <FormattedMessage id="units.lowercase" />{" "}
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, name) != get(next, name)}
        >
          {({ getFieldValue }) => (
            <>
              (
              {formatUnitValue(
                variant.variationValue * (parseFloat(getFieldValue(name)) || 0),
                variant.variationUnit,
                null,
                { maximumFractionDigits: 4 }
              )}
              )
            </>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
}
