import { useContext } from "react";
import { formatInfoTooltip, formatUnitValue } from "../../../../lib/formats";
import { Form, InputNumber, Rules } from "../../../form";
import {
  TableInputProps,
  TableInput,
  ColumnsType,
  ItemSidebarContext,
} from "../../../shared";
import { WorkOrderCostCenter } from "../builder/costCenters";
import { WorkOrderContext } from "../WorkOrderContext";
import { Button } from "antd";
import { ActivityMetricScope } from "../../../../lib/graphql";

export function CostCenterTableInput({
  tableProps,
  columns,
  ...props
}: Omit<TableInputProps<WorkOrderCostCenter>, "name" | "dataSource">) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { setCurrentAction } = useContext(ItemSidebarContext);
  const allColumns = [...columns];

  if (builder.isDataIntake) {
    const metricCols: ColumnsType<WorkOrderCostCenter> =
      workOrder.activity.activityMetrics
        .filter((m) => m.scope == ActivityMetricScope.CostCenter)
        .map((m) => ({
          title: (
            <>
              {m.metric.name} {formatInfoTooltip(m.metric.description)}
            </>
          ),
          width: "12rem",
          render: (_, cc, index) =>
            builder.isReadonly ? (
              formatUnitValue(
                builder.costCenters.metrics.getMetricValue(cc, m.metric.id),
                m.metric.unit
              )
            ) : (
              <Form.Item
                name={builder.costCenters.metrics.metricValueFieldName(
                  index,
                  m.metric.id
                )}
                rules={[Rules.gtEqZero]}
                compact
              >
                <InputNumber
                  step={0.1}
                  min={0}
                  onChange={() =>
                    builder.costCenters.metrics.debouncedDistributeIndividualMetrics(
                      m.metric.id,
                      index
                    )
                  }
                  addonAfter={m.metric.unit.abbr}
                  addonBefore={
                    builder.allowProgress && builder.progressByGroup ? (
                      <Button
                        type="link"
                        size="small"
                        style={{ padding: 0 }}
                        onClick={() => {
                          setCurrentAction("distributeMetric", {
                            metric: m.metric,
                            ccIndex: index,
                          });
                        }}
                      >
                        {builder.isMachinery ? "🚜" : "👨🏻‍🌾"}
                      </Button>
                    ) : undefined
                  }
                />
              </Form.Item>
            ),
        }));

    allColumns.push(...metricCols);
  }

  return (
    <TableInput
      name="costCenters"
      rules={[Rules.required]}
      tableProps={{
        bordered: true,
        ...tableProps,
      }}
      dataSource={workOrder.costCenters}
      rowKey={(f) => f.costCenter.id + f.profitableId || f.id}
      disabled={builder.isReadonly}
      addSorter={(a, b) => builder.costCenters.sorter(a, b)}
      onRemove={(cc) => builder.costCenters.onRemove(cc)}
      addIndexColumn
      allowBulkRemove
      columns={allColumns}
      {...props}
    />
  );
}
