import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderTokens } from "../../WorkOrderTokens";

export function Tokens(
  visible: boolean,
  readonly: boolean,
  inProgress: boolean
) {
  return {
    key: "tokens",
    visible,
    icon: <Icon component={() => <img src="/images/icons/tokens.svg" />} />,
    name: <FormattedMessage id="workOrders.tokens" defaultMessage="tokens" />,
    views: [
      {
        title: inProgress ? (
          <FormattedMessage
            id="workOrders.tokens.payment"
            defaultMessage="payment"
          />
        ) : (
          <FormattedMessage
            id="workOrders.tokens.request"
            defaultMessage="tokens"
          />
        ),
        description: inProgress ? (
          <FormattedMessage
            id="workOrders.tokens.payment.hint"
            defaultMessage="tokens"
          />
        ) : (
          <FormattedMessage
            id="workOrders.tokens.request.hint"
            defaultMessage="tokens"
          />
        ),
        render: () => (
          <WorkOrderTokens readonly={readonly} inProgress={inProgress} />
        ),
      },
    ],
  };
}
