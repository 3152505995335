import { defineMessages, useIntl } from "react-intl";
import { useContinueOnboardingStep } from "../../../lib/hooks/onboarding";
import ProductTour, { IStep } from "../../shared/ProductTour";
import { useCurrentUser } from "../../../lib/hooks";

const onboardingMessages = defineMessages({
  stagesAndVarietiesTitle: {
    id: "onboarding.cropFields.productTour.cropStagesVarieties.title",
    defaultMessage: "Review crop stages and varieties",
  },
  stagesAndVarietiesContent: {
    id: "onboarding.cropFields.productTour.cropStagesVarieties.content",
    defaultMessage:
      "Before creating fields, review and create crop stages and varieties.",
  },
  stagesTitle: {
    id: "onboarding.cropFields.productTour.cropStagesVarieties.title",
    defaultMessage: "Review crop stages and varieties",
  },
  stagesContent: {
    id: "onboarding.cropFields.productTour.cropStagesVarieties.content",
    defaultMessage:
      "Before creating fields, review and create crop stages and varieties.",
  },
  newTitle: {
    id: "onboarding.cropFields.productTour.new.title",
    defaultMessage: "Create a new field",
  },
  newContent: {
    id: "onboarding.cropFields.productTour.new.content",
    defaultMessage: "Click here to create a new field by filing out the form.",
  },
  importTitle: {
    id: "onboarding.cropFields.productTour.import.title",
    defaultMessage: "Import fields",
  },
  importContent: {
    id: "onboarding.cropFields.productTour.import.content",
    defaultMessage:
      "Follow the steps to download an excel template and import your fields.",
  },
  filtersTitle: {
    id: "onboarding.filters.title",
    defaultMessage: "Filters",
  },
  filtersContent: {
    id: "onboarding.cropFields.productTour.filter.content",
    defaultMessage:
      "Use filters to search for records by name or filter by other characteristics such as categories.",
  },
  sortTitle: {
    id: "onboarding.sort.title",
    defaultMessage: "Sort",
  },
  sortContent: {
    id: "onboarding.sort.content",
    defaultMessage: "Sort {entityName}",
  },
});

export function CropFieldsProductTour() {
  const intl = useIntl();
  const { user, currentTenant } = useCurrentUser();

  const steps = [
    {
      selector: "[data-new='true']",
      placement: "bottom-left",
      title: intl.formatMessage(onboardingMessages.newTitle),
      content: intl.formatMessage(onboardingMessages.newContent),
    },
    {
      selector: "[data-action='filters']",
      placement: "bottom-right",
      title: intl.formatMessage(onboardingMessages.filtersTitle),
      content: intl.formatMessage(onboardingMessages.filtersContent),
    },
    {
      selector: ".ant-table-thead",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.sortTitle),
      content: intl.formatMessage(onboardingMessages.sortContent),
    },
  ] as IStep[];

  if (currentTenant?.features?.customCropStages) {
    steps.unshift({
      selector: "[data-action='crop-stages-varieties']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.stagesAndVarietiesTitle),
      content: intl.formatMessage(onboardingMessages.stagesAndVarietiesContent),
    });
  }

  if (user?.permissions?.settings?.import) {
    steps.splice(2, 0, {
      selector: "[data-action='import-btn']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.importTitle),
      content: intl.formatMessage(onboardingMessages.importContent),
    });
  }

  const { loaded, step: continueOnboardingStep } = useContinueOnboardingStep();

  if (!loaded) return null;

  if (continueOnboardingStep) {
    steps.unshift(continueOnboardingStep);
  }

  return (
    <ProductTour
      hotspot={false}
      localKey="onboarding:crop-fields"
      steps={steps}
    />
  );
}
