import { Row, Col, Card, Input, Tooltip, Typography } from "antd";
import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  formatDetailsLink,
  formatActivity,
  formatWeekSuffix,
  formatDate,
  BLANK_SYMBOL,
  useBusinessTime,
  formatUsername,
} from "../../../lib/formats";
import {
  ActivityProgressMeasureType,
  WorkOrderCategory,
  WorkOrderType,
} from "../../../lib/graphql";
import {
  useActivityOptions,
  useCropCycleOptions,
  useCropFieldGroupOptions,
  useCurrentUser,
  useCycleNumberOptions,
  useItemSidebarContext,
  useUserOptions,
} from "../../../lib/hooks";
import routes from "../../../lib/routes";
import DocumentDateFormInput from "../../finance/DocumentDateFormInput";
import { Form, Rules, SelectField } from "../../form";
import ModifiedAtTooltip from "../../shared/ModifiedAtTooltip";
import { WorkOrderContext } from "./WorkOrderContext";

export function WorkOrderBasic({
  workOrderType,
}: {
  workOrderType?: WorkOrderType;
}) {
  const { workOrder, form, builder, rebuild } = useContext(WorkOrderContext);
  const { setCurrentAction } = useItemSidebarContext();
  const { user, currentLocalityId } = useCurrentUser();
  const localityId = currentLocalityId ? [currentLocalityId] : undefined;
  const { resetBusinessTime } = useBusinessTime(workOrder.activity.startTime);

  const [cropCycle, setCropCycle] = useState(workOrder.cropCycle);

  const colProps = { xs: 24, md: 12, xxl: 8 };
  const formItemProps = { labelCol: { lg: 8 }, wrapperCol: { lg: 16 } };

  return (
    <Card size="small">
      <Row gutter={16} style={{ marginBottom: "-20px" }}>
        <Col {...colProps}>
          <Form.Item
            label={<FormattedMessage id="activities.entityName" />}
            required
            {...formItemProps}
          >
            {!builder.canEditActivity ? (
              formatDetailsLink({
                id: workOrder.activity.id,
                route: routes.agro.activities.details,
                title: `${workOrder.activity.category.name} / ${formatActivity(
                  workOrder.activity.name,
                  workOrder.cycleNumber
                )}`,
              })
            ) : (
              <Input.Group compact>
                <Form.Item
                  name={builder.canEditActivity ? "activityId" : undefined}
                  compact
                  rules={[Rules.required]}
                  style={{
                    width: workOrder.activity.trackCycles ? "70%" : "100%",
                  }}
                >
                  <SelectField
                    optionsHook={useActivityOptions}
                    optionsHookParams={{
                      variables: { filter: { localityId, workOrderType } },
                    }}
                    showGroups
                    newItemProps={
                      user?.permissions.activity?.write
                        ? {
                            entityName: (
                              <FormattedMessage id="activities.entityName" />
                            ),
                            onClick: () => setCurrentAction("newActivity"),
                          }
                        : undefined
                    }
                    onChange={(id, opts) => {
                      const activity = opts.find(
                        (o: any) => o.key == id
                      )?.activity;

                      if (!activity) return;

                      form.setFields([
                        { name: "cycleNumber", value: undefined },
                      ]);

                      if (
                        workOrder.activity.workOrderType !==
                        activity.workOrderType
                      ) {
                        builder.costCenters.removeAll();
                      }

                      form.setFieldValue("activity", activity);
                      form.setFieldValue(
                        "progressByEmployee",
                        activity.progressMeasureType ===
                          ActivityProgressMeasureType.ByIndividual
                      );

                      rebuild({ activity });
                    }}
                  />
                </Form.Item>

                {workOrder.activity.trackCycles && (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="workOrders.cycleNumber"
                        defaultMessage="Application #"
                      />
                    }
                  >
                    <Form.Item
                      name={builder.canEditActivity ? "cycleNumber" : undefined}
                      compact
                      rules={[Rules.required]}
                      style={{ width: "30%" }}
                    >
                      <SelectField
                        placeholder={
                          <FormattedMessage
                            id="workOrders.cycleNumberShort"
                            defaultMessage="Appl #"
                          />
                        }
                        optionsHook={useCycleNumberOptions}
                        labelRenderer={(cycleNumber) => `#${cycleNumber}`}
                        onChange={(cycleNumber) => {
                          rebuild({ cycleNumber });
                        }}
                      />
                    </Form.Item>
                  </Tooltip>
                )}
              </Input.Group>
            )}
          </Form.Item>
        </Col>

        <Col {...colProps}>
          {!builder.canEditActivity ? (
            <Form.Item
              label={<FormattedMessage id="documentDate" />}
              {...formItemProps}
            >
              {formatWeekSuffix(
                formatDate(workOrder.documentDate, { timeTooltip: true }),
                workOrder.weekNumber
              )}
            </Form.Item>
          ) : (
            <Form.Item
              key="documentDate"
              noStyle
              dependencies={["activityId", "documentDate"]}
            >
              {({ setFields }) => {
                const hideTime = !workOrder.activity?.trackTime;

                return (
                  <DocumentDateFormInput
                    dateProps={{
                      showWeeks: cropCycle,
                      disableDates: true,
                      onChange: (val) => {
                        let value = val;
                        if (value) {
                          value = resetBusinessTime(value);
                        }

                        setFields([{ name: "documentDate", value }]);
                        rebuild({ documentDate: value });
                      },
                    }}
                    timeProps={{
                      hidden: hideTime,
                      onChange: (value) => {
                        builder.employees.onDocumentDateChanged();
                        rebuild({ documentDate: value });
                      },
                    }}
                    required
                    {...formItemProps}
                  />
                );
              }}
            </Form.Item>
          )}
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={builder.canEditActivity ? "cropCycleId" : undefined}
            label={<FormattedMessage id="cropCycles.entityName" />}
            rules={[Rules.required]}
            {...formItemProps}
          >
            {!builder.canEditActivity ? (
              workOrder.cropCycle?.name
            ) : (
              <SelectField
                optionsHook={useCropCycleOptions}
                optionsHookParams={{
                  variables: { filter: { localityId, closedAt: null } },
                }}
                newItemProps={
                  user?.permissions?.cropCycle?.write
                    ? {
                        entityName: (
                          <FormattedMessage id="cropCycles.entityName" />
                        ),
                        onClick: () => setCurrentAction("newCropCycle"),
                      }
                    : undefined
                }
                onChange={(id, opts) => {
                  const cropCycle = opts.find(
                    (o: any) => o.key == id
                  )?.cropCycle;

                  setCropCycle(cropCycle);
                  form.setFieldValue("cropCycle", cropCycle);

                  rebuild({ cropCycle });
                }}
              />
            )}
          </Form.Item>
        </Col>

        <Form.Item key="cropFieldGroup" noStyle dependencies={["activityId"]}>
          {() => {
            if (
              !builder.isAgricultural &&
              workOrder.activity.workOrderCategory !== WorkOrderCategory.General
            )
              return;

            return (
              <Col {...colProps}>
                <Form.Item
                  name={builder.canEditActivity ? "cropFieldGroup" : undefined}
                  label={<FormattedMessage id="cropFields.group" />}
                  {...formItemProps}
                >
                  {!builder.canEditActivity ? (
                    workOrder.cropFieldGroup || BLANK_SYMBOL
                  ) : (
                    <SelectField
                      optionsHook={useCropFieldGroupOptions}
                      optionsHookParams={{
                        variables: {
                          filter: {
                            localityId,
                          },
                        },
                      }}
                      onChange={(cropFieldGroup) => {
                        builder.costCenters.removeAll();
                        rebuild({ cropFieldGroup });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            );
          }}
        </Form.Item>

        <Col {...colProps}>
          <Form.Item
            name={builder.isReadonly ? undefined : "internalId"}
            label={<FormattedMessage id="internalId" />}
            {...formItemProps}
          >
            {builder.isReadonly ? (
              workOrder.internalId ? (
                <Typography.Text
                  ellipsis={{ tooltip: { title: workOrder.internalId } }}
                >
                  {workOrder.internalId}
                </Typography.Text>
              ) : (
                BLANK_SYMBOL
              )
            ) : (
              <Input />
            )}
          </Form.Item>
        </Col>

        {!builder.noActivity &&
          builder.allowProgress &&
          !builder.tokens.hasItems && (
            <Col {...colProps}>
              <Form.Item
                name={builder.isReadonly ? undefined : "progressByEmployee"}
                label={
                  <FormattedMessage
                    id="workOrders.enterProgress"
                    defaultMessage="Enter Progress by"
                  />
                }
                {...formItemProps}
              >
                {builder.isReadonly ? (
                  workOrder.progressByEmployee ? (
                    <FormattedMessage
                      id="workOrders.progressIndividual"
                      defaultMessage="progressIndividual"
                    />
                  ) : (
                    <FormattedMessage
                      id="workOrders.progressGroup"
                      defaultMessage="progressGroup"
                    />
                  )
                ) : (
                  <SelectField
                    allowClear={false}
                    showSearch={false}
                    options={[
                      {
                        key: false,
                        label: (
                          <FormattedMessage id="workOrders.progressGroup" />
                        ),
                      },
                      {
                        key: true,
                        label: (
                          <FormattedMessage id="workOrders.progressIndividual" />
                        ),
                      },
                    ]}
                    onChange={(progressByEmployee) =>
                      rebuild({ progressByEmployee })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          )}

        <Col {...colProps}>
          <Form.Item
            name={builder.isReadonly ? undefined : "assignedToId"}
            label={
              <FormattedMessage id="assignedTo" defaultMessage="Assigned To" />
            }
            {...formItemProps}
          >
            {builder.isReadonly ? (
              formatUsername(workOrder.assignedTo)
            ) : (
              <SelectField
                optionsHook={useUserOptions}
                allowClear={builder.isOpen}
                placeholder={
                  <FormattedMessage
                    id="select.user"
                    defaultMessage="Select a user"
                  />
                }
                onChange={(id, opts) => {
                  const assignedTo = opts.find((o: any) => o.key === id)?.user;
                  form.setFieldValue("assignedTo", assignedTo);
                  rebuild({ assignedTo });
                }}
              />
            )}
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            label={
              <FormattedMessage id="createdBy" defaultMessage="Created By" />
            }
            {...formItemProps}
          >
            <ModifiedAtTooltip
              modifiedAt={workOrder.createdAt}
              author={workOrder.createdBy}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}
