import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderBuilder } from "..";
import { WorkOrderMachineMaintenance } from "../../WorkOrderMachineMaintenance";
import { WorkOrderMachines } from "../../WorkOrderMachines";
import { createCostCenterSection } from "./CostCenters";

export function Machines(visible: boolean) {
  return {
    key: "machines",
    visible,
    icon: (
      <Icon component={() => <img src="/images/icons/fleet-machinery.svg" />} />
    ),
    name: (
      <FormattedMessage id="workOrders.machines" defaultMessage="machines" />
    ),
    views: [
      {
        title: (
          <FormattedMessage
            id="workOrders.machines"
            defaultMessage="machines"
          />
        ),
        description: (
          <FormattedMessage
            id="workOrders.machines.hint"
            defaultMessage="tools"
          />
        ),
        render: () => <WorkOrderMachines />,
      },
    ],
  };
}

export function UsedMachines(visible: boolean) {
  return {
    key: "machines",
    visible,
    icon: <Icon component={() => <img src="/images/icons/odometer.svg" />} />,
    name: (
      <FormattedMessage
        id="workOrders.usedMachines"
        defaultMessage="usedMachines"
      />
    ),
    views: [
      {
        title: (
          <FormattedMessage
            id="workOrders.usedMachines"
            defaultMessage="usedMachines"
          />
        ),
        description: (
          <FormattedMessage
            id="workOrders.machines.hint"
            defaultMessage="tools"
          />
        ),
        render: () => <WorkOrderMachines />,
      },
    ],
  };
}

export function MachineMaintenance(builder: WorkOrderBuilder) {
  return createCostCenterSection({
    name: (
      <FormattedMessage id="workOrders.machines" defaultMessage="machines" />
    ),
    title: (
      <FormattedMessage
        id="workOrders.machineMaintenance"
        defaultMessage="Fleet & Machinery"
      />
    ),
    description: (
      <FormattedMessage
        id="workOrders.machineMaintenance.hint"
        defaultMessage="Indicate which machines went under repair or maintenance today."
      />
    ),
    ccTypeText: (
      <FormattedMessage
        id="machines.entityName.lower"
        defaultMessage="machine"
      />
    ),
    icon: (
      <Icon component={() => <img src="/images/icons/fleet-machinery.svg" />} />
    ),
    render: (byPercentage) => (
      <WorkOrderMachineMaintenance byPercentage={byPercentage} />
    ),
    builder,
  });
}
