import { Col, Row } from "antd";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import {
  formatNumber,
  formatProgress,
  formatUnitValue,
  useFormatMoney,
} from "../../../lib/formats";
import { WorkUnit } from "../../../lib/graphql";
import {
  usePermissions,
  useTranslate,
  useWorkUnitFormatters,
} from "../../../lib/hooks";
import { WorkOrderContext } from "./WorkOrderContext";
import { Statistic } from "../../shared";

export function WorkOrderSummary() {
  const translate = useTranslate();
  const { workOrder } = useContext(WorkOrderContext);
  const progressUnit = workOrder.activity.progressUnit;
  const { formatWorkUnitAbbr } = useWorkUnitFormatters();
  const dayUnit = translate("manDays.abbr");
  const hourUnit = { abbr: formatWorkUnitAbbr(WorkUnit.Hour) };
  const formatMoney = useFormatMoney();
  const [showWage, showCost] = usePermissions((p) => [
    p.settings?.showWage,
    p.settings?.showCost,
  ]);

  if (!workOrder.summary) return null;

  return (
    <Row gutter={[64, 16]} style={{ marginTop: 8 }}>
      {workOrder.summary.totalProgress ? (
        <Col>
          <Statistic
            title={
              !workOrder.summary.totalGoal && <FormattedMessage id="progress" />
            }
            ellipsis={false}
          >
            {workOrder.summary.totalGoal
              ? formatProgress(
                  workOrder.summary.totalGoal,
                  progressUnit,
                  workOrder.summary.totalProgress,
                  { showInfo: true }
                )
              : formatUnitValue(workOrder.summary.totalProgress, progressUnit)}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.summary.laborCost && showWage && showCost ? (
        <Col>
          <Statistic title={<FormattedMessage id="activities.laborCost" />}>
            {formatMoney(workOrder.summary.laborCost)}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.summary.inputCost && showCost ? (
        <Col>
          <Statistic title={<FormattedMessage id="activities.inventoryCost" />}>
            {formatMoney(workOrder.summary.inputCost)}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.summary.attendedCount ? (
        <Col>
          <Statistic title={<FormattedMessage id="employees.attended" />}>
            {formatNumber(workOrder.summary.attendedCount)}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.summary.absentCount ? (
        <Col>
          <Statistic title={<FormattedMessage id="employees.absent" />}>
            {formatNumber(workOrder.summary.absentCount)}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.summary.laborManDays ? (
        <Col>
          <Statistic title={<FormattedMessage id="directLabor" />}>
            {formatUnitValue(workOrder.summary.laborManDays, { abbr: dayUnit })}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.summary.indirectManDays ? (
        <Col>
          <Statistic title={<FormattedMessage id="indirectLabor" />}>
            {formatUnitValue(workOrder.summary.indirectManDays, {
              abbr: dayUnit,
            })}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.summary.laborEfficiency ? (
        <Col>
          <Statistic
            title={<FormattedMessage id="activities.laborEfficiency" />}
          >
            {formatUnitValue(
              workOrder.summary.laborEfficiency,
              progressUnit,
              dayUnit
            )}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.machines.length > 0 ? (
        <Col>
          <Statistic title={<FormattedMessage id="machines" />}>
            {formatNumber(workOrder.machines.length)}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.summary.machineEfficiency ? (
        <Col>
          <Statistic
            title={<FormattedMessage id="activities.machineEfficiency" />}
          >
            {formatUnitValue(
              workOrder.summary.machineEfficiency,
              hourUnit,
              progressUnit.abbr
            )}
          </Statistic>
        </Col>
      ) : undefined}

      {workOrder.summary.machineCount > 0 && (
        <Col>
          <Statistic title={<FormattedMessage id="activities.machineHours" />}>
            {formatNumber(workOrder.summary.machineHours)}
          </Statistic>
        </Col>
      )}

      {workOrder.metrics.map((m) => (
        <Col key={m.id}>
          <Statistic title={m.metric.name}>
            {formatUnitValue(m.value, m.metric.unit)}
          </Statistic>
        </Col>
      ))}
    </Row>
  );
}
