import { Row, Col, Input, Button, Descriptions, Divider } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import {
  TaxPlanComponentFragment,
  TaxPlanWithSampleFragment,
} from "../../../lib/graphql";
import {
  Destroy,
  SaveIDFunction,
  useCurrentUser,
  useTaxComponentOptions,
  useTaxPlanKindFormat,
  useTaxPlanKindOptions,
} from "../../../lib/hooks";
import routes, { routerPush } from "../../../lib/routes";
import { Form, Rules, SelectField } from "../../form";
import { BottomPanel, ItemSidebarContext, MenuContent } from "../../shared";
import { formatEntityNameLabel, formatPlaceholder } from "../../../lib/formats";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { TaxPlanSidebar } from "./TaxPlanSidebar";
import { useContext, useState } from "react";
import { TaxPlanComponents } from "./TaxPlanComponents";

export interface TaxPlanFormValues
  extends Omit<
    TaxPlanWithSampleFragment,
    "components" | "createdAt" | "updatedAt" | "mayDiscard" | "mayDestroy"
  > {
  components: Array<Destroy<TaxPlanComponentFragment>>;
}

interface TaxPlanFormProps {
  disabled?: boolean;
  plan: TaxPlanFormValues;
  onSave?(values: TaxPlanFormValues): SaveIDFunction;
}

export function TaxPlanForm({ plan, disabled, onSave }: TaxPlanFormProps) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { currentTenant } = useCurrentUser();
  const { setCurrentAction } = useContext(ItemSidebarContext);
  const [stayOnPage, setStayOnPage] = useState(false);
  const formatTaxPlanKind = useTaxPlanKindFormat();

  const sections = {
    basic: {
      title: <FormattedMessage id="taxPlans.entityName" />,
      render: () => (
        <Row gutter={32}>
          <Col span={12}>
            {disabled ? (
              <Descriptions
                layout="vertical"
                column={1}
                className="details-descriptions"
                style={{ marginTop: -20 }}
              >
                <Descriptions.Item
                  label={<FormattedMessage id="taxPlans.entityName" />}
                >
                  {plan.name}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <FormattedMessage
                      id="taxPlans.kind"
                      defaultMessage="Apply tax plan to"
                    />
                  }
                >
                  {formatTaxPlanKind(plan.kind)}
                </Descriptions.Item>
              </Descriptions>
            ) : (
              <>
                <Form.Item
                  name="name"
                  label={formatEntityNameLabel(
                    <FormattedMessage id="taxPlans.entityName" />
                  )}
                  rules={[Rules.required]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="kind"
                  label={
                    <FormattedMessage
                      id="taxPlans.kind"
                      defaultMessage="Apply tax plan to"
                    />
                  }
                  rules={[Rules.required]}
                >
                  <SelectField optionsHook={useTaxPlanKindOptions} />
                </Form.Item>
              </>
            )}
          </Col>

          <Divider dashed />

          <Col span={24}>
            <TaxPlanComponents
              plan={plan}
              disabled={disabled}
              menuItems={(taxComponent) => [
                {
                  key: "edit",
                  icon: <EditOutlined />,
                  label: <FormattedMessage id="edit" />,
                  onClick: () => {
                    setCurrentAction("editTaxPlanComponent", taxComponent);
                  },
                },
                {
                  key: "edit-component",
                  icon: <EditOutlined />,
                  label: (
                    <FormattedMessage
                      id="taxComponents.edit"
                      defaultMessage="Edit Tax Component"
                    />
                  ),
                  onClick: () => {
                    setCurrentAction(
                      "editTaxComponent",
                      taxComponent.taxComponent
                    );
                  },
                },
              ]}
              tableSelectProps={{
                mode: "multiple",
                optionsHook: useTaxComponentOptions,
                placeholder: formatPlaceholder({
                  id: "taxComponents.entityName",
                }),
                entityById(_, { taxComponent }) {
                  if (taxComponent) {
                    return {
                      id: "",
                      taxComponent,
                      index: plan.components.length,
                    };
                  }
                },
                prefix: () => (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setCurrentAction("newTaxComponent")}
                  >
                    <span>
                      <FormattedMessage id="new" />
                    </span>
                  </Button>
                ),
              }}
            />
          </Col>
        </Row>
      ),
    },
  } as Record<string, any>;

  if (currentTenant.features.accounting) {
    sections.accounting = {
      title: <FormattedMessage id="company.settings.accounting" />,
      render: () => (
        <TaxPlanComponents
          plan={plan}
          disabled={disabled}
          accounting
          menuItems={(component) => [
            {
              key: "edit",
              icon: <EditOutlined />,
              label: <FormattedMessage id="edit" />,
              onClick: () => {
                setCurrentAction("editTaxAccounts", component.taxComponent);
              },
            },
          ]}
        />
      ),
    };
  }

  return (
    <Form
      preventLeaving
      layout="vertical"
      initialValues={plan}
      form={form}
      onSubmit={(values, { setSubmitting, showSuccess, showErrors }) => {
        if (!onSave) return;

        const promise = onSave(values);
        if (!promise) return;

        promise.then((entry) => {
          if (!entry) return;

          if (entry.result) {
            showSuccess(
              intl.formatMessage(
                { id: plan.id ? "edit.success" : "new.success" },
                {
                  entityName: intl.formatMessage({
                    id: "taxPlans.entityName",
                  }),
                  id: entry.result.id,
                }
              )
            );

            if (stayOnPage) {
              if (values.id) {
                form.setFieldsValue(entry.result);
              } else {
                routerPush(entry.result.id, routes.finance.taxPlans.edit);
              }
            } else {
              routerPush(entry.result.id, routes.finance.taxPlans.details);
            }
          } else if (entry.errors) {
            showErrors(entry.errors);
          }

          setStayOnPage(false);
          setSubmitting(false);
        });
      }}
    >
      <MenuContent items={sections} />

      <TaxPlanSidebar
        taxPlan={form.getFieldsValue()}
        onTaxComponentSave={(component) => {
          const components = form.getFieldValue(
            "components"
          ) as TaxPlanComponentFragment[];
          const index = components.findIndex(
            (c) => c.taxComponent.id == component.id
          );

          if (index >= 0) {
            form.setFieldValue(
              ["components", index, "taxComponent"],
              component
            );
          } else {
            form.setFieldValue("components", [
              ...components,
              {
                taxComponent: component,
                index: components.length,
              },
            ]);
          }
        }}
        onTaxPlanComponentSave={(component) => {
          const components = form.getFieldValue(
            "components"
          ) as TaxPlanComponentFragment[];

          const index = components.findIndex(
            (c) => c.taxComponent.id == component.taxComponent.id
          );

          form.setFieldValue(["components", index], component);
        }}
      />

      <br />

      <BottomPanel
        sticky
        buttons={
          disabled
            ? [
                BottomPanel.ReturnButton({
                  route: routes.finance.taxPlans.index,
                }),
                BottomPanel.EditButton({
                  route: routes.finance.taxPlans.edit(plan.id),
                }),
              ]
            : [
                BottomPanel.CancelButton({
                  route: routes.finance.taxPlans.index,
                }),
                BottomPanel.SubmitButton(),
                BottomPanel.CompleteButton({
                  onClick: () => setStayOnPage(true),
                  children: (
                    <FormattedMessage
                      id="taxPlans.saveAndCheck"
                      defaultMessage="Save & Check"
                    />
                  ),
                }),
              ]
        }
      />
    </Form>
  );
}
