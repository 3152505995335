import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderTools } from "../../WorkOrderTools";
import { WorkOrderUsedTools } from "../../WorkOrderUsedTools";

export function Tools(visible: boolean, readonly: boolean) {
  return {
    key: "tools",
    visible,
    hidden: !visible,
    icon: (
      <Icon component={() => <img src="/images/icons/tools-equipment.svg" />} />
    ),
    name: <FormattedMessage id="workOrders.tools" defaultMessage="tools" />,
    views: [
      {
        title: (
          <FormattedMessage id="workOrders.tools" defaultMessage="tools" />
        ),
        description: (
          <FormattedMessage id="workOrders.tools.hint" defaultMessage="tools" />
        ),
        render: () => <WorkOrderTools readonly={readonly} />,
      },
    ],
  };
}

export function UsedTools(visible: boolean, readonly: boolean) {
  return {
    key: "tools",
    visible,
    icon: (
      <Icon component={() => <img src="/images/icons/tools-equipment.svg" />} />
    ),
    name: <FormattedMessage id="workOrders.tools" defaultMessage="tools" />,
    views: [
      {
        title: (
          <FormattedMessage id="workOrders.tools" defaultMessage="tools" />
        ),
        description: (
          <FormattedMessage id="workOrders.tools.hint" defaultMessage="tools" />
        ),
        render: () => <WorkOrderUsedTools readonly={readonly} />,
      },
    ],
  };
}
