import { useIngredientOptions } from "../../../lib/hooks";
import { SelectField, SelectOption, SelectFieldProps } from "../../form";
import { tagRender } from "../../../lib/formats";

interface ActiveIngredientSelectProps extends SelectFieldProps {
  defaultValues?: SelectOption[] | null;
}

export function ActiveIngredientSelect({
  defaultValues,
  ...restProps
}: ActiveIngredientSelectProps) {
  return (
    <SelectField
      mode="multiple"
      optionsHook={useIngredientOptions}
      defaultValues={defaultValues}
      tagRender={(props) => tagRender({ color: "green", ...props })}
      {...restProps}
    />
  );
}
