import { FormattedMessage, useIntl } from "react-intl";
import { TaxComponentFragment } from "../../../lib/graphql";
import { useTaxComponentUpdate } from "../../../lib/hooks";
import { Form } from "../../form";
import { EditForm } from "../../shared";
import { AccountSelect } from "../../finance";

export function TaxAccountsForm({
  taxComponent,
  onClose,
}: {
  taxComponent: TaxComponentFragment;
  onClose: (result?: TaxComponentFragment) => void;
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const entityName = intl.formatMessage({ id: "taxComponents.entityName" });

  return (
    <EditForm
      formProps={{ form }}
      item={taxComponent}
      entityName={entityName}
      mutation={useTaxComponentUpdate}
      onMutate={(result) => result.taxComponentUpdate}
      onCancel={onClose}
      onSuccess={(result) => onClose(result)}
      formValues={({ taxCreditAccount, taxDebitAccount }) => ({
        taxCreditAccountId: taxCreditAccount?.id,
        taxDebitAccountId: taxDebitAccount?.id,
      })}
      fields={[
        {
          label: (
            <FormattedMessage
              id="accounts.debit"
              defaultMessage="Debit Account"
            />
          ),
          name: "taxDebitAccountId",
          type: "custom",
          render: () => <AccountSelect mode="ledger" />,
        },
        {
          label: (
            <FormattedMessage
              id="accounts.credit"
              defaultMessage="Credit Account"
            />
          ),
          name: "taxCreditAccountId",
          type: "custom",
          render: () => <AccountSelect mode="ledger" />,
        },
      ]}
    />
  );
}
