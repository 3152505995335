import { FormattedMessage } from "react-intl";
import {
  ItemSidebar,
  ItemSidebarContext,
  SidebarHeader,
  TableInput,
} from "../../shared";
import {
  useEmployeesAndGroupsOptions,
  useEmployeeGroupOptions,
  useCurrentUser,
  useWorkUnitFormatters,
  useActivity,
  matchPayment,
  usePermissions,
  useMediaXs,
} from "../../../lib/hooks";
import {
  BLANK_SYMBOL,
  focusInput,
  formatEmployeeName,
  formatMoneyAmount,
  formatNumber,
  formatTime,
  formatWage,
  shouldUpdate,
} from "../../../lib/formats";
import { Form, InputNumber, SelectField, TimePicker } from "../../form";
import { useContext, useState, useEffect } from "react";
import { WorkOrderContext } from "./WorkOrderContext";
import {
  AttendanceFormItem,
  EmployeeAttendance,
  employeeByIdBuilder,
  EmployeeLink,
} from "./WorkOrderAttendance";
import {
  PositionType,
  WorkOrderEmployeeFragment,
  WorkUnit,
} from "../../../lib/graphql";
import { ItemFieldFormConfig, ItemForm } from "../../shared/ListView/ItemForm";
import { ColumnsType } from "antd/lib/table";
import { Badge, Col, Row, Space, Tag } from "antd";
import {
  CloseCircleOutlined,
  FilterOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useEffectOnce } from "react-use";
import { filterFalse } from "../../../lib/utils";

export function WorkerCount() {
  const { currentTenant } = useCurrentUser();
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { formatWorkUnit } = useWorkUnitFormatters();
  const activity = useActivity(workOrder.activity.id);
  const [isPiecework, setIsPiecework] = useState(false);

  useEffect(() => {
    if (!activity) return;

    // match activity payment
    const payment = matchPayment(activity, workOrder, {
      positionType: PositionType.Labor,
    });

    if (payment) {
      const piecework = payment.wageUnit == WorkUnit.Piecework;
      builder.form.setFieldValue("isPiecework", piecework);
      setIsPiecework(piecework);

      if (!workOrder.workerCount) {
        builder.form.setFieldValue("workerWage", payment.wage);
      }
    }
  }, [activity, workOrder, builder]);

  // set focus on first show
  useEffectOnce(() => {
    if (workOrder.workerCount) return;

    focusInput("input#workerCount");
  });

  return (
    <Row gutter={16}>
      <Col>
        <Form.Item
          name="workerCount"
          label={
            <FormattedMessage
              id="workOrders.workerCount"
              defaultMessage="workerCount"
            />
          }
        >
          {builder.isReadonly ? (
            formatNumber(workOrder.workerCount || 0)
          ) : (
            <InputNumber
              min={0}
              step={1}
              precision={0}
              onChange={() =>
                !isPiecework && builder.employees.calculateWorkerCost()
              }
            />
          )}
        </Form.Item>
      </Col>

      <Col>
        <Form.Item name="workerWage" label={<FormattedMessage id="wage" />}>
          {builder.isReadonly ? (
            formatWage(
              {
                wage: builder.form.getFieldValue("workerWage"),
                wageUnit: WorkUnit.Day,
              },
              currentTenant.currencyCode
            )
          ) : (
            <InputNumber
              addonBefore={currentTenant.currency.symbol}
              addonAfter={
                isPiecework && activity
                  ? activity?.progressUnit.abbr
                  : formatWorkUnit(WorkUnit.Day)
              }
              onChange={() => builder.employees.calculateWorkerCost()}
            />
          )}
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          noStyle
          shouldUpdate={shouldUpdate("workerCount", "workerWage")}
        >
          {() => (
            <Form.Item
              name="workerCost"
              label={<FormattedMessage id="activities.laborCost" />}
            >
              {builder.isReadonly ? (
                workOrder.workerCost ? (
                  formatMoneyAmount(
                    workOrder.workerCost,
                    currentTenant.currencyCode
                  )
                ) : (
                  BLANK_SYMBOL
                )
              ) : (
                <InputNumber
                  disabled={isPiecework}
                  addonBefore={currentTenant.currency.symbol}
                  onChange={() =>
                    !isPiecework && builder.employees.calculateWage()
                  }
                />
              )}
            </Form.Item>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
}

export function WorkOrderEmployees({ readonly }: { readonly: boolean }) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentTenant } = useCurrentUser();
  const { setCurrentAction } = useContext(ItemSidebarContext);
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState(0);
  const employeeById = employeeByIdBuilder(workOrder);
  const showWage = usePermissions((p) => p.settings?.showWage);
  const isXs = useMediaXs();

  useEffect(() => builder.employees.initEmployeeAttendance());

  const editFields: ItemFieldFormConfig<WorkOrderEmployeeFragment>[] =
    filterFalse([
      currentTenant.attendanceEnabled && {
        type: "custom",
        render: () => <AttendanceFormItem key="attendance" />,
      },
      currentTenant.timeTrackingEnabled && {
        type: "custom",
        hidden: !workOrder.activity.trackTime,
        render: () => (
          <Form.Item
            noStyle
            key="hours"
            shouldUpdate={shouldUpdate("attended")}
          >
            {({ getFieldValue }) => {
              const attended = getFieldValue(["attended"]);
              if (attended === false) return null;

              return (
                <Form.Item
                  label={
                    <FormattedMessage
                      id="workOrders.startTime"
                      defaultMessage="Start Time"
                    />
                  }
                  name="startTime"
                  key="startTime"
                >
                  <TimePicker />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
      {
        type: "custom",
        render: () => (
          <Form.Item
            key="employeeGroupId"
            noStyle
            shouldUpdate={shouldUpdate("attended")}
          >
            {({ getFieldValue, setFields }) => {
              const attended = getFieldValue(["attended"]);
              if (attended === false) return null;

              return (
                <Form.Item
                  name={["employeeGroup", "id"]}
                  label={<FormattedMessage id="employeeGroups.entityName" />}
                >
                  <SelectField
                    optionsHook={useEmployeeGroupOptions}
                    optionsHookParams={{
                      variables: {
                        filter: { localityId: workOrder.locality.id },
                      },
                    }}
                    placeholder={
                      <FormattedMessage
                        id="select.employeeGroup"
                        defaultMessage="employeeGroup"
                      />
                    }
                    onChange={(id, options) => {
                      const group = options.find((o: any) => o.key == id);
                      setFields([
                        {
                          name: ["employeeGroup", "name"],
                          value: group?.label,
                        },
                      ]);
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        ),
      },
    ]);

  const columns: ColumnsType<WorkOrderEmployeeFragment> = filterFalse([
    {
      title: <FormattedMessage id="employees.entityName" />,
      dataIndex: "employee",
      sorter: builder.employees.nameSorter,
      fixed: isXs ? undefined : "left",
      render: (_, e, idx) => <EmployeeLink employee={e} idx={idx + 1} />,
    },
    showWage && {
      title: <FormattedMessage id="wage" defaultMessage="wage" />,
      render: (_, e) =>
        e.wagePayment &&
        formatWage(
          e.wagePayment,
          currentTenant.currencyCode,
          workOrder.activity.progressUnit
        ),
    },
    currentTenant.attendanceEnabled && {
      title: (
        <FormattedMessage
          id="workOrders.attendance"
          defaultMessage="attendance"
        />
      ),
      width: "8rem",
      render: (_, e) => <EmployeeAttendance employee={e} />,
    },
    workOrder.activity.trackTime && {
      title: (
        <FormattedMessage
          id="workOrders.startTime"
          defaultMessage="Start Time"
        />
      ),
      dataIndex: "startTime",
      width: "10rem",
      render: (_, e, index) => (
        <Form.Item
          noStyle
          shouldUpdate={shouldUpdate(
            ["employees", index, "attended"],
            "documentDate"
          )}
        >
          {({ getFieldValue }) => {
            const employee = builder.employees.getBy(index);
            if (builder.employees.isAbsent(employee)) return;

            return formatTime(e.startTime || getFieldValue("documentDate"));
          }}
        </Form.Item>
      ),
    },
  ]);

  const [positionFilter, setPositionFilter] = useState<string>();
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [showWorkerCount, setShowWorkerCount] = useState(
    !currentTenant.features.employees || builder.workerCountMode
  );

  return (
    <>
      {showWorkerCount && (
        <Space align="start" style={{ marginTop: 16 }}>
          <WorkerCount />

          <a
            className="text-link"
            style={{ marginTop: "5px", display: "block" }}
            onClick={() => {
              setShowWorkerCount(false);
              builder.employees.resetWorkerCount();
            }}
          >
            <CloseCircleOutlined />
          </a>
        </Space>
      )}

      {currentTenant.features.employees && (
        <>
          <TableInput
            name="employees"
            tableProps={{ fixed: true, bordered: true }}
            dataSource={workOrder.employees}
            rowKey={(e) => e.employee.id}
            disabled={readonly}
            tableSelectProps={{
              mode: "multiple",
              showGroups: true,
              groupsSorter: () => 0,
              placeholder: (
                <FormattedMessage
                  id="select.employeesGroups"
                  defaultMessage="employeesGroups"
                />
              ),
              optionsHook: useEmployeesAndGroupsOptions,
              optionsHookParams: {
                localityId: workOrder.locality.id,
                activityId: workOrder.activity.id,
                cropIds: workOrder.cropCycle?.crops.map((c) => c.id),
                documentDate: workOrder.documentDate,
                positionId: positionFilter,
              },
              entityById: employeeById,
              // TODO: extract this behaviour to separate component/module
              onSelectedChanged: (selected) =>
                setSelectedPositions(
                  selected
                    .map((se) =>
                      (Array.isArray(se) ? se : [se]).map(
                        (e) => e.employee.position.id
                      )
                    )
                    .flat()
                ),
              afterAdd: () => setSelectedPositions([]),
              dropdownRender:
                showWorkerCount || builder.disableWorkerCount
                  ? undefined
                  : (menu) => (
                      <SelectField.DropdownFooter
                        menu={
                          builder.planning ? (
                            <SelectField.DropdownHeader
                              menu={menu}
                              left={
                                <Space>
                                  {builder.planning?.positions.map((p) => (
                                    <Badge
                                      key={p.id}
                                      count={Math.max(
                                        0,
                                        p.quantity -
                                          builder.employees
                                            .get()
                                            .filter(
                                              (e) =>
                                                e.employee.position.id ==
                                                p.position.id
                                            ).length -
                                          selectedPositions.filter(
                                            (id) => id == p.position.id
                                          ).length
                                      )}
                                      color="purple"
                                      size="small"
                                      offset={[-5, 0]}
                                    >
                                      <Tag
                                        key={p.id}
                                        color="purple"
                                        style={{ cursor: "pointer" }}
                                        icon={
                                          positionFilter == p.position.id && (
                                            <FilterOutlined />
                                          )
                                        }
                                        onClick={() =>
                                          setPositionFilter((filter) =>
                                            p.position.id == filter
                                              ? undefined
                                              : p.position.id
                                          )
                                        }
                                      >
                                        {p.position.name}
                                      </Tag>
                                    </Badge>
                                  ))}
                                </Space>
                              }
                            />
                          ) : (
                            menu
                          )
                        }
                        onClick={() => setShowWorkerCount(true)}
                        mode="header"
                      >
                        <UsergroupAddOutlined />{" "}
                        <FormattedMessage
                          id="workOrders.enterWorkerCount"
                          defaultMessage="enterWorkerCount"
                        />
                      </SelectField.DropdownFooter>
                    ),
            }}
            addSorter={(a, b) => builder.employees.sorter(a, b)}
            columns={columns}
            allowBulkRemove
            bulkUpdateFields={editFields}
            onBulkUpdate={() => builder.employees.recalculateEndTimes()}
            menuItems={(_, index) => [
              {
                key: "edit-employee",
                onClick: () => {
                  setCurrentEmployeeIndex(index);
                  setCurrentAction("editEmployee");
                },
                label: <FormattedMessage id="edit" />,
              },
            ]}
          />

          <ItemSidebar
            item={currentEmployeeIndex}
            sidebarActions={{
              editEmployee: ({ item, closeSidebar }) => {
                const employee = builder.employees.get(false)[item];
                return (
                  <>
                    <SidebarHeader
                      back
                      title={
                        <FormattedMessage
                          id="edit.header"
                          values={{
                            entityName: formatEmployeeName(employee.employee),
                          }}
                        />
                      }
                      onClose={closeSidebar}
                    />
                    <ItemForm
                      initialValues={employee}
                      fields={editFields}
                      onCancel={closeSidebar}
                      onSave={(values) => {
                        builder.employees.update(item, values);
                        builder.employees.recalculateEndTime(item);
                        closeSidebar();
                        return Promise.resolve(null);
                      }}
                    />
                  </>
                );
              },
            }}
          />
        </>
      )}
    </>
  );
}
