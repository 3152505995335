import { defineMessages, useIntl } from "react-intl";
import { useContinueOnboardingStep } from "../../../lib/hooks/onboarding";
import ProductTour, { IStep } from "../../shared/ProductTour";
import { useCurrentUser } from "../../../lib/hooks";

const onboardingMessages = defineMessages({
  activityCategoriesTitle: {
    id: "onboarding.activities.productTour.activityCategories.title",
    defaultMessage: "Review activity categories",
  },
  activityCategoriesContent: {
    id: "onboarding.activities.productTour.activityCategories.content",
    defaultMessage:
      "Before creating activities, review activity categories and create new ones if needed.",
  },
  newTitle: {
    id: "onboarding.activities.productTour.new.title",
    defaultMessage: "Create a new activity",
  },
  newContent: {
    id: "onboarding.activities.productTour.new.content",
    defaultMessage:
      "Click here to create a new activity by filing out the form.",
  },
  importTitle: {
    id: "onboarding.activities.productTour.import.title",
    defaultMessage: "Import activities",
  },
  importContent: {
    id: "onboarding.activities.productTour.import.content",
    defaultMessage:
      "Follow the steps to download an excel template and import activities.",
  },
  catalogTitle: {
    id: "onboarding.activities.productTour.catalog.title",
    defaultMessage: "Activity Catalog",
  },
  catalogContent: {
    id: "onboarding.activities.productTour.catalog.content",
    defaultMessage: "Select activities from catalog",
  },
});

const KEY = "onboarding:activities";

export function ActivitiesProductTour() {
  const intl = useIntl();
  const { loaded, step: continueOnboardingStep } = useContinueOnboardingStep();
  const { user } = useCurrentUser();

  if (!loaded) return null;

  const steps = [
    {
      selector: "[data-action='activity-categories']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.activityCategoriesTitle),
      content: intl.formatMessage(onboardingMessages.activityCategoriesContent),
    },
    {
      selector: "[data-new='true']",
      placement: "bottom-left",
      title: intl.formatMessage(onboardingMessages.newTitle),
      content: intl.formatMessage(onboardingMessages.newContent),
    },
    {
      selector: "[data-action='activity-catalog']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.catalogTitle),
      content: intl.formatMessage(onboardingMessages.catalogContent),
    },
  ] as IStep[];

  if (user?.permissions?.settings?.import) {
    steps.push({
      selector: "[data-action='import-btn']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.importTitle),
      content: intl.formatMessage(onboardingMessages.importContent),
    });
  }

  if (continueOnboardingStep) {
    steps.unshift(continueOnboardingStep);
  }

  return <ProductTour hotspot={false} localKey={KEY} steps={steps} />;
}
