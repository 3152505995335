import {
  InventoryStatus,
  WorkOrderVariantFragment,
} from "../../../../lib/graphql";
import { Destroy } from "../../../../lib/hooks";
import { WorkOrderBaseBuilder } from "./base";

export interface WorkOrderVariant extends Destroy<WorkOrderVariantFragment> {
  assignedAmount?: number;
  sourceWarehouseId?: string;
  returnWarehouseId?: string;
  applicationUnitId?: string;
}

export class WorkOrderToolBuilder extends WorkOrderBaseBuilder<WorkOrderVariant> {
  protected get field() {
    return "tools";
  }

  sorter(a: WorkOrderVariantFragment, b: WorkOrderVariantFragment) {
    return a.variant.name.localeCompare(b.variant.name);
  }

  init() {
    const tools = this.get();

    tools.forEach((i) => {
      if (i.returnedAmount == undefined) {
        i.returnedAmount = i.totalAmount;
      }
    });
  }

  onInit() {
    super.onInit();

    this.entity[this.field].forEach((v: WorkOrderVariant) => {
      v.sourceWarehouseId = v.sourceWarehouse?.id;
      if (v.status == InventoryStatus.Assigned) {
        v.assignedAmount = v.totalAmount;
      }
    });
  }

  get isRequesting() {
    return this.get().some(
      (v) =>
        v.status == InventoryStatus.NotRequested ||
        v.status == InventoryStatus.Requesting
    );
  }

  get defaultAmount(): number {
    return (
      this.builder.employees.getWorkers().length +
      (this.builder.employees.workerCount || 0)
    );
  }
}
