import { FormItemProps } from "antd";
import { FormattedMessage } from "react-intl";
import { useLocalityOptions } from "../../../lib/hooks";
import { Form, SelectField, SelectFieldProps } from "../../form";

export function LocalityFormSelect({
  formItemProps,
  ...props
}: SelectFieldProps & { formItemProps?: FormItemProps }) {
  return (
    <Form.Item
      name="localityId"
      label={<FormattedMessage id="localities.pageTitle" />}
      {...formItemProps}
    >
      <SelectField
        mode="multiple"
        optionsHook={useLocalityOptions}
        placeholder={<FormattedMessage id="localities.all" />}
        {...props}
      />
    </Form.Item>
  );
}
