import { FormattedMessage, useIntl } from "react-intl";
import { JournalEntryFragment, JournalEntryStatus } from "../../lib/graphql";
import { useJournalEntryUpdate } from "../../lib/hooks";
import {
  ChangeLogSidebar,
  ConfirmSidebar,
  ItemSidebar,
  RevertSidebar,
} from "../shared";
import { CloneForm } from "./journalEntries/CloneForm";
import routes from "../../lib/routes";

export function JournalEntrySidebar({
  entry,
}: {
  entry: JournalEntryFragment;
}) {
  const intl = useIntl();
  const [update] = useJournalEntryUpdate();
  const entityName = intl.formatMessage({ id: "journalEntries.entityName" });

  return (
    <ItemSidebar
      item={entry}
      sidebarActions={{
        cancel: ({ closeSidebar }) => (
          <ConfirmSidebar
            title={
              <FormattedMessage
                id="cancel.doc.header"
                defaultMessage="cancel {entityName}"
                values={{ entityName }}
              />
            }
            onCancel={closeSidebar}
            okButtonProps={{ danger: true }}
            okText={
              <FormattedMessage id="cancel.doc" defaultMessage="Cancel" />
            }
            cancelText={
              <FormattedMessage id="cancel.doc.return" defaultMessage="Back" />
            }
            onOk={({ showSuccess }) =>
              update({
                variables: {
                  id: entry.id,
                  changes: { status: JournalEntryStatus.Canceled },
                },
              }).then((result) => {
                const updateResult =
                  result.data && result.data.journalEntryUpdate;

                if (updateResult && updateResult.result) {
                  closeSidebar();

                  showSuccess(
                    intl.formatMessage(
                      { id: "cancel.doc.success" },
                      {
                        entityName,
                        id: entry.id,
                      }
                    )
                  );
                }
              })
            }
          >
            <FormattedMessage
              id="cancel.doc.confirm"
              defaultMessage="confirm"
            />
          </ConfirmSidebar>
        ),
        revert: ({ closeSidebar }) => (
          <RevertSidebar
            closeSidebar={closeSidebar}
            entityName={entityName}
            entity={entry}
            revert={() =>
              update({
                variables: {
                  id: entry.id,
                  changes: { status: JournalEntryStatus.Reverted },
                },
              }).then((result) => result.data?.journalEntryUpdate)
            }
          />
        ),
        changeLog: ({ closeSidebar }) => (
          <ChangeLogSidebar
            id={entry.id}
            type="Finance::JournalEntry"
            onClose={closeSidebar}
          />
        ),
        clone: ({ item, closeSidebar }) => (
          <CloneForm
            item={item}
            entityName={entityName}
            entityUrl={routes.finance.journalEntries.details}
            onClose={closeSidebar}
          />
        ),
      }}
    />
  );
}
