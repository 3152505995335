import {
  useCostCenters,
  useMachineOptions,
  useInfrastructureOptions,
  useCurrentUser,
  useCropFieldOptions,
  useWarehouseOptions,
} from "../../lib/hooks";
import {
  SortDirection,
  ProfitableType,
  CostCenterTreeFragment,
  CropFieldFragment,
  MachineFragment,
  WarehouseFragment,
  InfrastructureFragment,
  HasProfitable,
  CostCenterShortFragment,
  Maybe,
} from "../../lib/graphql";
import {
  DeepNamePath,
  TreeSelectField,
  TreeSelectFieldProps,
  useFormContext,
} from "../form";

import { FormattedMessage } from "react-intl";
import {
  formatCropFieldVarieties,
  formatCropStage,
  formatMachineOption,
} from "../../lib/formats";
import { useCallback, useEffect, useState } from "react";
import { Space } from "antd";

interface CostCenterSelectProps
  extends Partial<TreeSelectFieldProps<CostCenterTreeFragment>> {
  useDefault?: boolean;
  profitableTypeFilter?: ProfitableType[];
}

export function CostCenterSelect({
  useDefault,
  profitableTypeFilter,
  ...restProps
}: CostCenterSelectProps) {
  return (
    <TreeSelectField
      queryHook={useCostCenters}
      queryHookParams={(parentCostCenterId) => ({
        variables: {
          filter: {
            parentCostCenterId,
            profitableType: profitableTypeFilter,
          },
          sort: { name: SortDirection.Asc },
          pageSize: 500,
        },
      })}
      buildTreeNode={(costCenter) => ({
        title: costCenter.name,
        pId: costCenter.parentCostCenter && costCenter.parentCostCenter.id,
        isLeaf:
          !costCenter.subcostCenters || costCenter.subcostCenters.length === 0,
        costCenter,
      })}
      placeholder={
        useDefault && (
          <FormattedMessage
            id="costCenters.useDefault"
            defaultMessage="useDefault"
          />
        )
      }
      {...restProps}
    />
  );
}

export function CostCenterProfitableSelect({
  name,
  profitableTypeFilter,
  cropFieldGroup,
  multiple,
  onChangeValue,
  ...props
}: CostCenterSelectProps & {
  name: DeepNamePath;
  required?: boolean;
  profitableTypeFilter?: ProfitableType[];
  cropFieldGroup?: string | null;
  onChangeValue?: (values: HasProfitable[]) => void;
}) {
  const { currentLocalityIds: localityId, currentLocalityId } =
    useCurrentUser();
  const { form } = useFormContext();
  const [currentValue, setCurrentValue] = useState<string | string[]>();
  const { load: loadCropFields, search: searchCropFields } =
    useCropFieldOptions({
      variables: {
        filter: {
          localityId,
          group: cropFieldGroup ? [cropFieldGroup] : undefined,
        },
        pageSize: 500,
      },
    });
  const { load: loadMachines, search: searchMachines } = useMachineOptions({
    variables: {
      fetchCostCenter: true,
      localityId: currentLocalityId,
      filter: { localityId },
      pageSize: 500,
    },
  });
  const { load: loadInfrastructures, search: searchInfrastructures } =
    useInfrastructureOptions({
      variables: { filter: { localityId }, pageSize: 500 },
    });
  const { load: loadWarehouses, search: searchWarehouses } =
    useWarehouseOptions({
      variables: { filter: { localityId }, pageSize: 500 },
    });

  const buildNode = useCallback(
    (
      item:
        | CostCenterTreeFragment
        | CropFieldFragment
        | (MachineFragment & { costCenter: Maybe<CostCenterShortFragment> })
        | WarehouseFragment
        | InfrastructureFragment
    ) => {
      const key = `${item.__typename}-${item.id}`;
      const leaf = {
        id: key,
        key,
        value: key,
        title: item.name,
        isLeaf: true,
        profitableId: item.id,
      };

      switch (item.__typename) {
        case "CropField":
          return {
            ...leaf,
            profitableType: ProfitableType.CropField,
            name: `${item.costCenter?.name} - ${item.name}`,
            title: (
              <Space size={4}>
                {item.name} {formatCropStage(item.cropStage)}{" "}
                {formatCropFieldVarieties(item)}
              </Space>
            ),
            pId: item.costCenter?.id,
            costCenter: item.costCenter,
          };
        case "Infrastructure":
          return {
            ...leaf,
            profitableType: ProfitableType.Infrastructure,
            name: `${item.costCenter?.name} - ${item.name}`,
            pId: item.costCenter?.id,
            costCenter: item.costCenter,
          };
        case "Warehouse":
          return {
            ...leaf,
            profitableType: ProfitableType.Warehouse,
            name: `${item.costCenter?.name} - ${item.name}`,
            pId: item.costCenter?.id,
            costCenter: item.costCenter,
          };
        case "Machine":
          return {
            ...leaf,
            profitableType: ProfitableType.Machine,
            name: `${item.costCenter?.name} - ${item.name}`,
            title: (
              <Space size={4}>
                {item.kind.name}/{formatMachineOption(item)}
              </Space>
            ),
            pId: item.costCenter?.id,
            costCenter: item.costCenter,
          };
        case "CostCenter":
          return {
            title: item.name,
            name: item.name,
            pId: item.parentCostCenter && item.parentCostCenter.id,
            isLeaf: false,
            costCenter: item,
          };
        default:
          return leaf;
      }
    },
    []
  );

  useEffect(() => {
    const value = form.getFieldValue(name);

    if (!value) {
      setCurrentValue(undefined);
    } else {
      const val = [value]
        .flat()
        .filter((v) => v.costCenter)
        .map((v) => `${v.costCenter.name} - ${v.profitableName}`);

      setCurrentValue(multiple ? val : val[0]);
    }
  }, [form, name, multiple]);

  return (
    <CostCenterSelect
      {...props}
      style={{ width: "100%" }}
      treeNodeFilterProp="name"
      treeNodeLabelProp="name"
      treeLine
      multiple={multiple}
      treeCheckable={multiple}
      value={currentValue}
      profitableTypeFilter={profitableTypeFilter}
      buildTreeNode={buildNode}
      loadNode={(node) => {
        const costCenterId = node.costCenter?.id;

        switch (node.costCenter?.profitableType) {
          case ProfitableType.CropField:
            return loadCropFields({
              variables: {
                filter: {
                  localityId,
                  group: cropFieldGroup ? [cropFieldGroup] : undefined,
                  costCenterId,
                },
              },
            }).then((r) => r.data?.cropFields?.items || []);
          case ProfitableType.Machine:
            return loadMachines({
              variables: {
                filter: { localityId, costCenterId },
              },
            }).then((r) => r.data?.machines?.items || []);
          case ProfitableType.Warehouse:
            return loadWarehouses({
              variables: {
                filter: { localityId },
              },
            }).then((r) => r.data?.warehouses?.items || []);
          case ProfitableType.Infrastructure:
            return loadInfrastructures({
              variables: {
                filter: { localityId, costCenterId },
              },
            }).then((r) => r.data?.infrastructures?.items || []);
          default:
            return Promise.resolve([]);
        }
      }}
      searchNode={(searchValue) =>
        Promise.all([
          searchCropFields(searchValue).then(
            (r) => r.data?.cropFields?.items || []
          ),
          searchInfrastructures(searchValue).then(
            (r) => r.data?.infrastructures?.items || []
          ),
          searchMachines(searchValue).then(
            (r) => r.data?.machines?.items || []
          ),
          searchWarehouses(searchValue).then(
            (r) => r.data?.warehouses?.items || []
          ),
        ]).then((r) => r.flat())
      }
      onChange={(val: string[], options: any[]) => {
        setCurrentValue(val);

        const value = options
          .filter((o) => (val || []).includes(o.value))
          .map(({ costCenter, profitableType, profitableId, name }) => ({
            costCenterId: costCenter.id,
            costCenter,
            profitableType,
            profitableId,
            profitableName: name,
          }));

        if (onChangeValue) onChangeValue(value);

        if (multiple) {
          form.setFields([{ name, value }]);
        } else {
          form.setFields([
            {
              name: name.concat("costCenterId"),
              value: value[0]?.costCenterId,
            },
            {
              name: name.concat("costCenter"),
              value: value[0]?.costCenter,
            },
            {
              name: name.concat("profitableType"),
              value: value[0]?.profitableType,
            },
            {
              name: name.concat("profitableId"),
              value: value[0]?.profitableId,
            },
            {
              name: name.concat("profitableName"),
              value: value[0]?.profitableName,
            },
          ]);
        }
      }}
    />
  );
}
