import { TreeSelect } from "antd";
import { useAccountKinds } from "../../lib/hooks";
import { TreeSelectProps } from "antd/lib/tree-select";
import { DataNode } from "../form/types";
import { AccountKindFragment } from "../../lib/graphql";
import { unaccent } from "../../lib/formats";

function treeNode(kind: AccountKindFragment): DataNode {
  return {
    key: kind.id,
    title: kind.name,
    value: kind.id,
    filterBy: unaccent(kind.name),
    isLeaf: !kind.accountKinds || kind.accountKinds.length === 0,
    children: kind.accountKinds?.map((k: any) => treeNode(k)),
    kind,
  };
}

export function AccountKindSelect({
  onKindChange,
  ...restProps
}: Omit<TreeSelectProps<string>, "treeData"> & {
  onKindChange?: (kind: AccountKindFragment) => void;
}) {
  const { accountKinds } = useAccountKinds();

  const treeData = accountKinds.reduce((opts: DataNode[], kind) => {
    opts.push(treeNode(kind));
    return opts;
  }, []);

  return (
    <TreeSelect
      treeData={treeData}
      allowClear
      showSearch
      treeNodeFilterProp="filterBy"
      onSelect={(_: any, option: any) =>
        onKindChange && onKindChange(option.kind)
      }
      {...restProps}
    />
  );
}
