import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { TransferStatus } from "../../lib/graphql";
import { formatVariantQuantity, formatVariantLink } from "../../lib/formats";
import { TransferForm, TransferFormProps } from "./TransferForm";
import routes from "../../lib/routes";
import { QuantityFormItem } from "./QuantityFormItem";
import { Typography } from "antd";

const messages = defineMessages({
  qty: { id: "stockTransfers.qty", defaultMessage: "qty" },
  itemCategory: { id: "items.itemCategory", defaultMessage: "itemCategory" },
});

export function MovementForm({
  values,
  onSave,
}: Pick<TransferFormProps, "values" | "onSave">) {
  const intl = useIntl();

  const disabled = values.status !== TransferStatus.Open;

  return (
    <TransferForm
      entityName={intl.formatMessage({ id: "stockTransfers.entityName" })}
      values={values}
      transferUrl={routes.inventory.transfers.index}
      onSave={onSave}
      showTargetWarehouse
      variantTableProps={(_, formValue) => ({
        optionsHookVariables: { date: formValue?.documentDate },
        withStockOnly: true,
        columns: [
          {
            title: (
              <FormattedMessage
                id="variants.entityName"
                defaultMessage="variant"
              />
            ),
            dataIndex: "name",
            render: (_, v) => formatVariantLink(v),
          },
          {
            title: (
              <FormattedMessage id="stock.onHand" defaultMessage="onHand" />
            ),
            render: (_, v) => (
              <Typography.Text
                type={v.stock.onHand <= 0 ? "danger" : undefined}
              >
                {formatVariantQuantity(v, v.stock.onHand, true)}
              </Typography.Text>
            ),
          },
          disabled
            ? {
                title: intl.formatMessage(messages.qty),
                render: (_, v) => formatVariantQuantity(v, v.quantity || 0),
              }
            : {
                title: intl.formatMessage(messages.qty),
                width: 300,
                render: (_, v, index) => (
                  <QuantityFormItem
                    name={["variants", index, "quantity"]}
                    variant={v}
                    rules={TransferForm.stockPresence(index)}
                  />
                ),
              },
        ],
      })}
    />
  );
}
