import { Key, useContext, useState } from "react";

import { WorkOrderContext } from "./WorkOrderContext";
import { Form, InputNumber, RuleBuilder, Rules } from "../../form";
import { HintTooltip, Space, TableInput } from "../../shared";
import { useCurrentUser, useMachineOptions } from "../../../lib/hooks";
import {
  CropFieldMachineFragment,
  MachineCategory,
  WorkOrderMachineFragment,
} from "../../../lib/graphql";
import {
  formatCropField,
  formatCropFieldArea,
  formatMachine,
  formatMachineCapacity,
  formatPlaceholder,
  formatUnitValue,
  shouldUpdate,
  translate,
} from "../../../lib/formats";
import { FormattedMessage, useIntl } from "react-intl";
import { Tag } from "antd";
import { filterArray, filterColumns } from "../../../lib/utils";
import { WorkOrderMachine } from "./builder/machines";
import { useWaterUsage } from "./WorkOrderSpraying";
import { cropFieldToEntity } from "./WorkOrderCropFields";
import { MachineWorkHours } from "./WorkOrderMachines";

export function WorkOrderIrrigation() {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();
  const { workOrder, builder } = useContext(WorkOrderContext);
  const localityId = [workOrder.locality.id];
  const [expandedRows, setExpandedRows] = useState<readonly Key[]>(
    workOrder.machines.map((m) => m.machine.id)
  );
  const { recalculate } = useWaterUsage();

  const h = intl.formatMessage({ id: "workUnits.abbr.hour" });

  const fieldHoursLabel = (
    <Space>
      {builder.isDataIntake ? (
        <FormattedMessage
          id="workOrders.irrigation.irrigatedHours"
          defaultMessage="Hours Irrigated"
        />
      ) : (
        <FormattedMessage
          id="workOrders.irrigation.hoursToIrrigate"
          defaultMessage="Hours to Irrigate"
        />
      )}
      <HintTooltip
        title={
          builder.isDataIntake ? (
            <FormattedMessage
              id="workOrders.irrigation.irrigatedHours.hint"
              defaultMessage="Enter the actual number of hours each field was irrigated"
            />
          ) : (
            <FormattedMessage
              id="workOrders.irrigation.hoursToIrrigate.hint"
              defaultMessage="Enter the number of hours the irrigation equipment should operate"
            />
          )
        }
      />
    </Space>
  );

  return (
    <TableInput
      name="machines"
      rules={[Rules.required]}
      dataSource={workOrder.machines}
      rowKey={(m) => m.machine.id}
      disabled={builder.isReadonly}
      addIndexColumn
      tableSelectProps={{
        mode: "multiple",
        showGroups: true,
        optionsHook: useMachineOptions,
        optionsHookParams: {
          variables: {
            filter: { localityId, category: [MachineCategory.Irrigation] },
          },
        },
        placeholder: formatPlaceholder({
          id: "machines.categories.irrigation",
        }),
        entityById: (_, { machine }) => {
          if (machine) {
            return {
              id: "",
              machine,
              operator: null,
              implement: null,
              workHours: 0,
            };
          }
        },
        afterAdd: (selected: WorkOrderMachineFragment[]) => {
          setExpandedRows(selected.map((m) => m.machine.id));

          selected.forEach((m) =>
            m.machine.cropFieldMachines.forEach(({ cropField }) => {
              const cc = cropFieldToEntity(cropField, builder);
              builder.costCenters.add(cc);
            })
          );

          builder.machines.initMachineMetrics();

          recalculate();
        },
      }}
      addSorter={(a, b) => builder.machines.sorter(a, b)}
      columns={filterColumns<WorkOrderMachine>([
        {
          title: translate("machines.categories.irrigation"),
          dataIndex: ["machine", "id"],
          render: (_, m) => (
            <Space>
              {formatMachine(m.machine)}
              <Tag color="green">{formatMachineCapacity(m.machine)}</Tag>
            </Space>
          ),
        },
        {
          title: (
            <Space>
              {builder.isDataIntake ? (
                <FormattedMessage
                  id="workOrders.irrigation.operatedHours"
                  defaultMessage="Hours Operated"
                />
              ) : (
                <FormattedMessage
                  id="workOrders.irrigation.scheduledHours"
                  defaultMessage="Scheduled Hours"
                />
              )}
              <HintTooltip
                title={
                  builder.isDataIntake ? (
                    <FormattedMessage
                      id="workOrders.irrigation.operatedHours.hint"
                      defaultMessage="Enter the number of hours the irrigation equipment was operated"
                    />
                  ) : (
                    <FormattedMessage
                      id="workOrders.irrigation.scheduledHours.hint"
                      defaultMessage="Enter the number of hours the irrigation equipment should operate"
                    />
                  )
                }
              />
            </Space>
          ),
          dataIndex: "workHours",
          width: "12rem",
          align: "center",
          render: (_, m, index) => {
            return builder.isReadonly ? (
              formatUnitValue(m.workHours, { abbr: h })
            ) : (
              <MachineWorkHours
                name={["machines", index]}
                hourAbbr={h}
                onChange={recalculate}
              />
            );
          },
        },
        {
          title: <FormattedMessage id="workOrders.spraying.waterUsed" />,
          width: "8rem",
          align: "center",
          render: (_, m, index) => (
            <Form.Item
              noStyle
              shouldUpdate={shouldUpdate(["waterUsage", "waterAmount"])}
            >
              {() =>
                formatUnitValue(
                  builder.machines.getCapacityUsed(index),
                  m.machine.capacityUnit
                )
              }
            </Form.Item>
          ),
        },
      ])}
      tableProps={{
        bordered: true,
        expandable: {
          rowExpandable: (m) => Form.undestroyed(m),
          expandedRowKeys: expandedRows,
          onExpandedRowsChange: setExpandedRows,
          expandedRowRender: (m, mIndex) => {
            return (
              <TableInput
                name={["machines", mIndex, "machine", "cropFieldMachines"]}
                dataSource={m.machine.cropFieldMachines}
                tableProps={{
                  size: "small",
                  rowClassName: (cfm) =>
                    builder.costCenters.getByCropField(cfm.cropField)
                      ? ""
                      : "hide",
                }}
                addIndexColumn
                allowBulkRemove
                disabled={builder.isReadonly}
                rowKey={(cfm) => cfm.id}
                onRemove={(cfm) => {
                  builder.machines.onRemoveCropField(cfm.cropField);
                  recalculate();
                }}
                bulkUpdateFields={
                  [
                    {
                      label: fieldHoursLabel,
                      type: "number",
                      name: "hours",
                      numberProps: { addonAfter: h },
                    },
                  ] as any
                }
                onBulkUpdate={(values: any) => {
                  builder.machines.updateCropFieldMachineHours(m, values.hours);
                  recalculate();
                }}
                columns={filterColumns<CropFieldMachineFragment>([
                  {
                    title: <FormattedMessage id="cropFields.entityName" />,
                    render: (_, cfm) => (
                      <>
                        {formatCropField(cfm.cropField)}
                        <Tag color="green">
                          {formatMachineCapacity({
                            ...m.machine,
                            capacityValue:
                              cfm.capacityValue || m.machine.capacityValue,
                          })}
                        </Tag>
                      </>
                    ),
                  },
                  {
                    title: <FormattedMessage id="cropFields.totalArea" />,
                    width: "12rem",
                    align: "center",
                    render: (_, cfm) =>
                      formatCropFieldArea(
                        cfm.cropField,
                        workOrder.activity.useTotalArea,
                        currentTenant.features.customCropVarieties
                      ),
                  },
                  {
                    title: fieldHoursLabel,
                    width: "9rem",
                    align: "center",
                    render: (_, cfm) => {
                      const field =
                        builder.machines.getCropFieldMachineHoursField(
                          m,
                          cfm.cropField
                        );

                      if (!field) return;

                      if (builder.isReadonly) {
                        return formatUnitValue(
                          builder.form.getFieldValue(field),
                          { abbr: h }
                        );
                      }

                      return (
                        <Form.Item
                          compact
                          name={field}
                          rules={[
                            Rules.required,
                            Rules.onlyNumbers,
                            RuleBuilder.gtEq(0),
                          ]}
                        >
                          <InputNumber
                            step={1}
                            min={0}
                            max={24}
                            addonAfter={h}
                            onChange={recalculate}
                          />
                        </Form.Item>
                      );
                    },
                  },
                  {
                    title: (
                      <FormattedMessage id="workOrders.spraying.waterUsed" />
                    ),
                    width: "8rem",
                    align: "center",
                    render: (_, cfm) => {
                      return (
                        <Form.Item
                          noStyle
                          shouldUpdate={shouldUpdate(
                            ...filterArray(
                              ["machines", mIndex, "workHours"],
                              builder.machines.getCropFieldMachineHoursField(
                                m,
                                cfm.cropField
                              )
                            )
                          )}
                        >
                          {() =>
                            formatUnitValue(
                              builder.machines.getCapacityUsed(mIndex, cfm),
                              m.machine.capacityUnit
                            )
                          }
                        </Form.Item>
                      );
                    },
                  },
                ])}
              />
            );
          },
        },
      }}
    />
  );
}
