import { Checkbox, Col, InputNumber, Row, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { useCurrentUser, usePaymentMethodOptions } from "../../lib/hooks";
import { AccountSelect, TaxPlanSelect } from "../finance";
import { Form, SelectField } from "../form";
import { TaxPlanKind } from "../../lib/graphql";
import { shouldUpdate } from "../../lib/formats";

export function CounterpartyVendor() {
  const { currentTenant } = useCurrentUser();
  const { loadingPaymentMethods, paymentMethodOptions } =
    usePaymentMethodOptions();

  return (
    <Row>
      <Col xs={24} md={12}>
        <Row gutter={32}>
          <Col span={24}>
            <Typography.Text type="secondary">
              <FormattedMessage
                id="counterparties.vendorConfig.hint"
                defaultMessage="vendorConfig.hint"
              />
            </Typography.Text>
            <Form.Item
              name="vendorPaymentMethodId"
              label={
                <FormattedMessage
                  id="paymentMethods"
                  defaultMessage="Payment Terms"
                />
              }
              style={{ marginTop: "16px" }}
            >
              <SelectField
                loading={loadingPaymentMethods}
                options={paymentMethodOptions}
                showGroups
              />
            </Form.Item>
            {currentTenant.features.accounting && (
              <Form.Item
                name="payableAccountId"
                label={
                  <FormattedMessage
                    id="counterparties.accountPayable"
                    defaultMessage="accountPayable"
                  />
                }
              >
                <AccountSelect mode="ledger" />
              </Form.Item>
            )}
          </Col>

          {currentTenant.features.taxPlan ? (
            <Col span={24}>
              <Form.Item
                name="taxPlanId"
                label={<FormattedMessage id="taxPlans.entityName" />}
              >
                <TaxPlanSelect kind={TaxPlanKind.Vendor} />
              </Form.Item>
            </Col>
          ) : (
            <Col span={24}>
              <Form.Item noStyle shouldUpdate={shouldUpdate("taxRate")}>
                {({ getFieldValue, setFields }) => {
                  const visible = getFieldValue("taxRate") != null;

                  return (
                    <>
                      <Checkbox
                        defaultChecked={visible}
                        style={{ marginBottom: "10px" }}
                        onChange={(e) =>
                          setFields([
                            {
                              name: "taxRate",
                              value: e.target.checked ? 15 : null,
                            },
                          ])
                        }
                      >
                        <FormattedMessage
                          id="counterparties.taxSubject"
                          defaultMessage="Is vendor subject to VAT?"
                        />
                      </Checkbox>

                      {visible && (
                        <Row gutter={16}>
                          <Col span={8}>
                            <Form.Item
                              label={<FormattedMessage id="vat" />}
                              name="taxRate"
                            >
                              <InputNumber addonAfter="%" min={0} max={99} />
                            </Form.Item>
                          </Col>
                          {currentTenant.features.accounting && (
                            <Col span={16}>
                              <Form.Item
                                name="vatAccountId"
                                label={
                                  <FormattedMessage
                                    id="vatAccount"
                                    defaultMessage="VAT Account"
                                  />
                                }
                              >
                                <AccountSelect mode="ledger" />
                              </Form.Item>
                            </Col>
                          )}
                          <Col span={24}>
                            {currentTenant.features.accounting && (
                              <Form.Item
                                noStyle
                                shouldUpdate={shouldUpdate("withholdingRate")}
                              >
                                {({ getFieldValue, setFields }) => {
                                  const visible =
                                    getFieldValue("withholdingRate") != null;

                                  return (
                                    <>
                                      <Checkbox
                                        defaultChecked={visible}
                                        style={{ marginBottom: "10px" }}
                                        onChange={(e) =>
                                          setFields([
                                            {
                                              name: "withholdingRate",
                                              value: e.target.checked
                                                ? 2
                                                : null,
                                            },
                                          ])
                                        }
                                      >
                                        <FormattedMessage
                                          id="counterparties.withholdingSubject"
                                          defaultMessage="Is vendor subject to Withholding?"
                                        />
                                      </Checkbox>

                                      {visible && (
                                        <Row gutter={16}>
                                          <Col span={8}>
                                            <Form.Item
                                              label={
                                                <FormattedMessage id="payments.withholding" />
                                              }
                                              name="withholdingRate"
                                            >
                                              <InputNumber
                                                addonAfter="%"
                                                min={0}
                                                max={99}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={16}>
                                            <Form.Item
                                              name="withholdingAccountId"
                                              label={
                                                <FormattedMessage
                                                  id="withholdingAccount"
                                                  defaultMessage="Withholding Account"
                                                />
                                              }
                                            >
                                              <AccountSelect mode="ledger" />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      )}
                                    </>
                                  );
                                }}
                              </Form.Item>
                            )}
                          </Col>
                        </Row>
                      )}
                    </>
                  );
                }}
              </Form.Item>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}
