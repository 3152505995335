import { sum, sumBy, uniq } from "lodash";
import { WorkOrderBuilder, WorkOrderFormValues } from ".";
import {
  ItemFragment,
  UnitShortFragment,
  UnitType,
  WorkOrderWaterUsageFragment,
} from "../../../../lib/graphql";
import {
  distributeProportionally,
  getUnitsConversionFactor,
  roundUnit,
} from "../../../../lib/formats";
import { WorkOrderVariant } from "./tools";

const PRECISION = 10000;

export interface WorkOrderWaterUsage extends WorkOrderWaterUsageFragment {
  waterSourceIds?: string[];
  clientOnly?: boolean;
}

export class WorkOrderWaterUsageBuilder {
  protected builder: WorkOrderBuilder;
  protected workOrder: WorkOrderFormValues;

  constructor(builder: WorkOrderBuilder) {
    this.builder = builder;
    this.workOrder = builder.workOrder;

    if (this.workOrder.waterUsage) {
      this.workOrder.waterUsage.waterSourceIds =
        this.workOrder.waterUsage.waterSources.map((s) => s.id);
    }
  }

  init(unit: UnitShortFragment) {
    if (!this.builder.isWaterUsage) return;

    if (!this.workOrder.waterUsage) {
      this.workOrder.waterUsage = {
        tankCapacity: this.builder.isIrrigation ? 1 : 0,
        tanksRequired: 0,
        outputRate: 0,
        outputUnit: unit,
        waterAmount: 0,
        waterUnit: unit,
        applicationUnit: this.workOrder.locality.areaUnit,
        waterSources: [],
        waterSourceIds: [],
        clientOnly: this.builder.isIrrigation,
      };

      this.builder.form.setFieldValue("waterUsage", this.workOrder.waterUsage);
    } else if (
      this.builder.isInProgress &&
      !this.workOrder.waterUsage.tanksUsed
    ) {
      this.builder.form.setFieldValue(
        ["waterUsage", "tanksUsed"],
        this.workOrder.waterUsage.tanksRequired
      );

      this.calculateConsumption();
    }
  }

  get value(): WorkOrderWaterUsageFragment {
    return this.builder.form.getFieldValue("waterUsage");
  }

  get coverageArea() {
    return this.builder.allowProgress
      ? this.builder.costCenters.getTotalGoal()
      : this.builder.costCenters.getTotalArea();
  }

  get sprayRequired() {
    if (this.builder.isIrrigation) {
      return sum(
        this.builder.machines
          .get()
          .map((_, index) => this.builder.machines.getCapacityUsed(index))
      );
    }

    const areaFactor = getUnitsConversionFactor(
      this.workOrder.locality.areaUnit,
      this.value.applicationUnit
    );

    const outputFactor = getUnitsConversionFactor(
      this.value.outputUnit,
      this.value.waterUnit
    );

    return (
      this.value.outputRate * outputFactor * this.coverageArea * areaFactor
    );
  }

  get sprayUsed() {
    return this.sprayRequired * this.usedFactor;
  }

  getWaterRequired(initially?: boolean) {
    const inputs = this.builder.inputs
      .get()
      .filter((i) => i.unit.unitType === UnitType.Volume);

    const waterUnit = this.value.waterUnit;
    const usedFactor = initially ? 1 : this.usedFactor;

    const inputVolume =
      sumBy(
        inputs,
        (i) => i.totalAmount * getUnitsConversionFactor(i.unit, waterUnit)
      ) * usedFactor;

    // for irrigation return spray required bc it has different calculation
    const totalSpray =
      initially || this.builder.isIrrigation
        ? this.sprayRequired
        : this.sprayUsed;

    return Math.max(0, totalSpray - inputVolume);
  }

  recalculate() {
    if (!this.builder.isWaterUsage) return;

    if (this.builder.isDataIntake) {
      this.calculateTanksUsed();
      this.calculateConsumption();
    } else {
      this.calculateTanksRequired();
      this.recalculateInputs();
    }

    this.calculateWaterAmount();
  }

  calculateTanksRequired() {
    this.builder.form.setFieldValue(
      ["waterUsage", "tanksRequired"],
      this.sprayRequired / this.value.tankCapacity
    );
  }

  calculateTanksUsed() {
    if (!this.builder.isIrrigation) return;

    this.builder.form.setFieldValue(
      ["waterUsage", "tanksUsed"],
      this.sprayRequired / this.value.tankCapacity
    );
  }

  calculateWaterAmount() {
    this.builder.form.setFieldValue(
      ["waterUsage", "waterAmount"],
      this.getWaterRequired(this.builder.isOpen)
    );
  }

  get usedFactor() {
    return (this.value.tanksUsed || 0) / this.value.tanksRequired;
  }

  calculateConsumption() {
    const usedFactor = this.usedFactor;
    const inputs = this.builder.inputs.get(false);
    const costCenters = this.builder.costCenters.get();

    inputs.forEach((i, index) => {
      const consumed = roundUnit(i.totalAmount * usedFactor, PRECISION);

      const distribution = distributeProportionally(
        consumed,
        costCenters.map((cc) =>
          this.builder.allowProgress ? cc.totalProgress : 1
        ),
        PRECISION
      );

      costCenters.forEach((cc, ccIndex) => {
        const ccvIndex = cc.variants.findIndex(
          (ccv) => ccv.variantId === i.variant.id
        );

        if (ccvIndex >= 0) {
          this.builder.form.setFields([
            {
              name: [
                "costCenters",
                ccIndex,
                "variants",
                ccvIndex,
                "consumedAmount",
              ],
              value: distribution[ccIndex],
            },
            {
              name: ["inputs", index, "returnedAmount"],
              value: i.totalAmount - consumed,
            },
          ]);
        }
      });

      this.calculateWaterAmount();
    });
  }

  recalculateInputs() {
    this.builder.inputs.get().forEach((_, i) => this.onInputChanged(i));
  }

  onInputChanged(inputIndex: number) {
    this.builder.inputs.calculateAmounts(inputIndex);
    this.calculateWaterAmount();
  }

  onRemoveInput(input: WorkOrderVariant) {
    this.builder.inputs.onRemove(input);
    this.calculateWaterAmount();
  }

  onAddInput(inputs: WorkOrderVariant[]) {
    this.builder.inputs.initInputs();

    inputs.forEach((i) => {
      const index = this.builder.inputs.indexOf(i);
      if (index >= 0) this.builder.inputs.calculateAmounts(index);
    });

    this.calculateWaterAmount();

    const equipmentIds = inputs
      .map((i) => i.variant.item as ItemFragment)
      .flatMap((item) => (item.equipment || []).map((e) => e.id));

    if (equipmentIds.length) {
      const existingIds = this.builder.form.getFieldValue("equipmentIds") || [];
      this.builder.form.setFieldValue(
        "equipmentIds",
        uniq(equipmentIds.concat(existingIds))
      );
    }
  }
}
