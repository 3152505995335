import { FormattedMessage } from "react-intl";
import { TableInputProps } from "../../../shared";

import {
  formatDetailsLink,
  formatCostCenterName,
} from "../../../../lib/formats";
import routes from "../../../../lib/routes";
import { useContext } from "react";
import { CostCenterProfitableSelect } from "../../../finance";
import { useFormContext, Form } from "../../../form";
import { WorkOrderContext } from "../WorkOrderContext";
import { WorkOrderCostCenter } from "../builder/costCenters";
import { CostCenterTableInput } from "./CostCenterTableInput";
import { DistributeProgress } from "./DistributeProgress";
import { DistributeHours } from "./DistributeHours";

export interface WorkOrderCostCentersProps
  extends Omit<TableInputProps<WorkOrderCostCenter>, "name" | "dataSource"> {
  byPercentage?: boolean;
}

export function WorkOrderCostCenters(props: WorkOrderCostCentersProps) {
  const { builder } = useContext(WorkOrderContext);

  if (!builder.isDataIntake || builder.isProcessing) {
    return <CostCenterTableInput {...props} />;
  } else {
    return builder.allowProgress ? (
      <DistributeProgress {...props} />
    ) : (
      <DistributeHours {...props} />
    );
  }
}

export function WorkOrderGenericCostCenters({
  byPercentage,
}: {
  byPercentage?: boolean;
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { form } = useFormContext();

  return (
    <WorkOrderCostCenters
      byPercentage={byPercentage}
      tableSelectProps={{
        render: () => {
          return (
            <CostCenterProfitableSelect
              name={["newCostCenters"]}
              required={false}
              cropFieldGroup={workOrder.cropFieldGroup}
              multiple
              placeholder={
                <FormattedMessage
                  id="select.costCenter"
                  defaultMessage="select.costCenter"
                />
              }
            />
          );
        },
        onAdd: () => {
          const costCenters: any[] = form.getFieldValue("newCostCenters") || [];

          costCenters.forEach((cc: any) =>
            builder.costCenters.add({
              id: "",
              costCenter: cc.costCenter,
              profitableType: cc.profitableType,
              profitableId: cc.profitableId,
              profitableName: cc.profitableName,
              dayGoal: 0,
              totalProgress: 0,
              plannedProgress: 0,
              employees: [],
              machines: [],
              variants: [],
              metrics: builder.costCenters.metrics.build(),
            })
          );

          form.setFields([{ name: "newCostCenters", value: null }]);
        },
        afterAdd: () =>
          !builder.allowProgress &&
          builder.costCenters.calculateCostDistribution(true),
      }}
      columns={[
        {
          title: <FormattedMessage id="costCenters.entityName" />,
          dataIndex: "costCenter",
          render: (_, c, index) => (
            <Form.Item name={["costCenters", index, "id"]} compact>
              {formatDetailsLink({
                id: c.costCenter.id,
                route: routes.finance.costCenters.details,
                title: formatCostCenterName(c.costCenter),
                discarded: !!c.costCenter.discardedAt,
              })}
            </Form.Item>
          ),
        },
        {
          title: (
            <FormattedMessage
              id="costCenters.profitable"
              defaultMessage="subcostCenter"
            />
          ),
          dataIndex: "profitableId",
          render: (_, c) => c.profitableName,
        },
      ]}
    />
  );
}
