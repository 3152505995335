import { useTagOptions } from "../../lib/hooks/basic/tags";
import { SelectField, SelectFieldProps } from "../form";

export default function TagSelect(props: SelectFieldProps) {
  const { search, options } = useTagOptions();

  return (
    <SelectField
      allowClear
      mode="tags"
      onSearch={search}
      options={options.map((o) => ({
        label: o.label,
        key: o.label,
      }))}
      {...props}
    />
  );
}
