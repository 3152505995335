import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderConsumedInputs } from "../../WorkOrderConsumedInputs";
import { WorkOrderInputs } from "../../WorkOrderInputs";
import { WorkOrderBuilder } from "..";
import { WorkOrderSpraying } from "../../WorkOrderSpraying";

export function Inputs(
  builder: WorkOrderBuilder,
  visible: boolean,
  readonly: boolean,
  hidden: boolean
) {
  if (builder.isSpraying) {
    return {
      key: "inputs",
      visible,
      icon: <Icon component={() => <img src="/images/icons/spraying.svg" />} />,
      name: (
        <FormattedMessage id="workOrders.spraying" defaultMessage="Spraying" />
      ),
      views: [
        {
          title: <FormattedMessage id="workOrders.spraying" />,
          description: (
            <FormattedMessage
              id="workOrders.spraying.hint"
              defaultMessage="hint"
            />
          ),
          render: () => <WorkOrderSpraying />,
        },
      ],
    };
  }

  return {
    key: "inputs",
    visible,
    icon: <Icon component={() => <img src="/images/icons/inputs.svg" />} />,
    name: <FormattedMessage id="workOrders.inputs" defaultMessage="inputs" />,
    viewProperty: "requestByTotal",
    views: [
      {
        title: <FormattedMessage id="workOrders.inputs" />,
        description: (
          <FormattedMessage
            id="workOrders.inputsByTotal.hint"
            defaultMessage="hint"
          />
        ),
        menuItem: (
          <FormattedMessage
            id="workOrders.requestTotalAmount"
            defaultMessage="requestTotalAmount"
          />
        ),
        render: () => <WorkOrderInputs readonly={readonly} />,
      },
    ],
    hidden,
  };
}

export function ConsumedInputs(
  builder: WorkOrderBuilder,
  visible: boolean,
  readonly: boolean,
  usageByManual: boolean,
  allowProgress: boolean
) {
  if (builder.isSpraying) {
    return {
      key: "input-usage",
      visible,
      icon: <Icon component={() => <img src="/images/icons/spraying.svg" />} />,
      name: (
        <FormattedMessage id="workOrders.spraying" defaultMessage="Spraying" />
      ),
      views: [
        {
          title: <FormattedMessage id="workOrders.spraying" />,
          description: (
            <FormattedMessage
              id="workOrders.spraying.hint"
              defaultMessage="hint"
            />
          ),
          render: () => <WorkOrderSpraying />,
        },
      ],
    };
  }

  return {
    key: "input-usage",
    visible,
    icon: <Icon component={() => <img src="/images/icons/inputs.svg" />} />,
    name: <FormattedMessage id="workOrders.inputs" defaultMessage="inputs" />,
    viewProperty: "usageByManual",
    currentViewIndex: usageByManual ? 1 : 0,
    views: [
      {
        title: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsByProgress"
            defaultMessage="distributeByProgress"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeAutomatically"
            defaultMessage="distributeAutomatically"
          />
        ),
        menuItem: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsByProgress.menuItem"
            defaultMessage="distributeByProgress"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeAutomatically.menuItem"
            defaultMessage="distributeAutomatically"
          />
        ),
        description: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsByProgress.hint"
            defaultMessage="hint"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeAutomatically.hint"
            defaultMessage="hint"
          />
        ),
        render: () => (
          <WorkOrderConsumedInputs
            readonly={readonly}
            byProgress={true}
            noDosages={!allowProgress}
          />
        ),
      },
      {
        title: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsManual"
            defaultMessage="distributeManually"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeByCostCenter"
            defaultMessage="distributeByCostCenter"
          />
        ),
        menuItem: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsManual.menuItem"
            defaultMessage="distributeManually"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeByCostCenter.menuItem"
            defaultMessage="distributeByCostCenter"
          />
        ),
        description: allowProgress ? (
          <FormattedMessage
            id="workOrders.inputsManual.hint"
            defaultMessage="hint"
          />
        ) : (
          <FormattedMessage
            id="workOrders.distributeByCostCenter.hint"
            defaultMessage="hint"
          />
        ),
        render: () => (
          <WorkOrderConsumedInputs
            readonly={readonly}
            byProgress={false}
            noDosages={!allowProgress}
          />
        ),
      },
    ],
  };
}
