import { StatusPanel } from "../../shared";
import { Typography, Descriptions, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import {
  FinanceDocumentType,
  InventoryRequestDetailsFragment,
} from "../../../lib/graphql";
import {
  formatInventoryStatus,
  BLANK_SYMBOL,
  formatDocumentLink,
  formatDateTime,
  formatDate,
} from "../../../lib/formats";
import { formatUsername } from "../../../lib/formats/users";

export function InventoryRequestHeader({
  request,
}: {
  request: InventoryRequestDetailsFragment;
}) {
  return (
    <StatusPanel
      node={formatInventoryStatus(request.workOrder.inventoryStatus)}
    >
      <Typography.Text type="secondary">
        <Descriptions>
          <Descriptions.Item
            label={
              <FormattedMessage
                id="workOrders.documentDate"
                defaultMessage="documentDate"
              />
            }
          >
            {formatDate(request.workOrder.documentDate, { timeTooltip: true })}
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="localities.entityName" />}
          >
            {request.workOrder.locality.name}
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id="sourceDocument" />}>
            {formatDocumentLink(
              request.workOrder.id,
              FinanceDocumentType.AgroWorkOrder
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="activities.entityName" />}
          >
            {request.workOrder.activity.category.name} /{" "}
            {request.workOrder.activity.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={<FormattedMessage id="cropCycles.entityName" />}
          >
            {request.workOrder.cropCycle?.name || BLANK_SYMBOL}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <FormattedMessage
                id="inventoryRequests.requestor"
                defaultMessage="requestor"
              />
            }
          >
            <Tooltip title={formatDateTime(request.updatedAt)}>
              <span>{formatUsername(request.createdBy)}</span>
            </Tooltip>
          </Descriptions.Item>
        </Descriptions>
      </Typography.Text>
    </StatusPanel>
  );
}
