import React, { useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, Row, Table, Typography } from "antd";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  BLANK_SYMBOL,
  formatAccountName,
  formatDateTime,
  formatMoneyAmount,
  formatPercent,
  formatStatus,
  shouldUpdate,
} from "../../lib/formats";
import {
  FinanceInvoiceDetailsFragment,
  FinanceInvoicePaymentDetailsFragment,
  FinancePaymentStatus,
  InvoiceStatus,
  PaymentFields,
} from "../../lib/graphql";
import {
  useCurrentUser,
  useFinancePaymentCreate,
  useFinancePaymentUpdate,
  useItemSidebarContext,
} from "../../lib/hooks";
import routes from "../../lib/routes";
import { Form, InputNumber, RuleBuilder, Rules } from "../form";
import {
  BottomPanel,
  ConfirmSidebar,
  ExchangeRateInput,
  ItemDetails,
  ItemSidebar,
  NewForm,
} from "../shared";
import { AccountSelect } from "./AccountSelect";
import { ColumnsType } from "antd/lib/table";
import { filterFalse } from "../../lib/utils";

interface InvoicePaymentsProps {
  invoice: FinanceInvoiceDetailsFragment;
}

const messages = defineMessages({
  payment: { id: "payments.entityName", defaultMessage: "payment" },
  paymentDate: { id: "payments.depositDate", defaultMessage: "Deposit Date" },
  account: {
    id: "payments.receiverAccount",
    defaultMessage: "What account will receive the funds?",
  },
  amount: {
    id: "payments.amountDeposited",
    defaultMessage: "Amount Deposited",
  },
  refNumber: { id: "refNumber", defaultMessage: "refNumber" },
  withholding: { id: "payments.withholding", defaultMessage: "Withholding" },
  withholdingHelp: {
    id: "payments.withholdingHelp",
    defaultMessage: "Amount: {amount}. Net payment: {net}",
  },
});

export function InvoicePayments({ invoice }: InvoicePaymentsProps) {
  const intl = useIntl();
  const readonly =
    invoice.invoiceStatus === InvoiceStatus.Completed ||
    invoice.invoiceStatus === InvoiceStatus.Canceled ||
    invoice.invoiceStatus === InvoiceStatus.Reverted;

  const [currentPayment, setCurrentPayment] =
    useState<FinanceInvoicePaymentDetailsFragment>();
  const { setCurrentAction } = useItemSidebarContext();
  const { currentTenant } = useCurrentUser();
  const entityName = intl.formatMessage(messages.payment);
  const [update] = useFinancePaymentUpdate();

  const isSale = invoice.sourceDocumentCode == "SO";

  const columns: ColumnsType<FinanceInvoicePaymentDetailsFragment> =
    filterFalse([
      {
        title: <FormattedMessage id="id" />,
        dataIndex: "id",
      },
      {
        title: intl.formatMessage(messages.refNumber),
        dataIndex: "internalId",
      },
      {
        title: intl.formatMessage(messages.paymentDate),
        dataIndex: "paymentDate",
        render: (_, p) => formatDateTime(p.paymentDate),
      },
      currentTenant.features.accounting &&
        !currentTenant.financeEntriesDisabled && {
          title: (
            <FormattedMessage id="invoices.account" defaultMessage="account" />
          ),
          dataIndex: "account",
          render: (_, p) => p.account && formatAccountName(p.account),
        },
      {
        title: <FormattedMessage id="invoices.amountPaid" />,
        dataIndex: "invoiceAmount",
        align: "right",
        render: (_, p) =>
          formatMoneyAmount(p.invoiceAmount, invoice.currencyCode),
      },
      {
        title: <FormattedMessage id="status" />,
        dataIndex: "status",
        align: "center",
        render: (_, p) => formatStatus(p.status, "tag"),
      },
    ]);

  return (
    <>
      <Table
        dataSource={invoice.payments}
        pagination={false}
        rowKey="id"
        onRow={(payment) => ({
          onClick: () => {
            setCurrentPayment(payment);
            setCurrentAction("viewPayment");
          },
        })}
        columns={columns}
      />

      <ItemSidebar
        item={currentPayment}
        sidebarActions={{
          viewPayment: ({ item, setAction, closeSidebar }) =>
            item && (
              <ItemDetails
                item={item}
                entityName={entityName}
                onClose={closeSidebar}
                menuItems={[
                  item.status === FinancePaymentStatus.Completed && {
                    key: "revert",
                    danger: true,
                    icon: <CloseCircleOutlined />,

                    onClick: () => setAction("revertPayment"),

                    label: <FormattedMessage id="revert" />,
                  },
                ]}
                fields={[
                  {
                    label: intl.formatMessage(messages.paymentDate),
                    render: (p) => formatDateTime(p.paymentDate),
                  },
                  {
                    label: intl.formatMessage(messages.refNumber),
                    render: (p) => p.internalId || BLANK_SYMBOL,
                  },
                  currentTenant.features.accounting &&
                  !currentTenant.financeEntriesDisabled
                    ? {
                        label: intl.formatMessage(messages.account),
                        render: (p) =>
                          p.account && formatAccountName(p.account),
                      }
                    : null,
                  {
                    label: intl.formatMessage(messages.amount),
                    render: (p) =>
                      formatMoneyAmount(p.invoiceAmount, invoice.currencyCode),
                  },
                  {
                    label: intl.formatMessage(messages.withholding),
                    render: (p) => formatPercent(p.withholdingRate),
                  },
                  {
                    label: <FormattedMessage id="status" />,
                    render: (p) => formatStatus(p.status, "tag"),
                  },
                ]}
              />
            ),
          newPayment: ({ closeSidebar }) => (
            <NewForm
              entityName={entityName}
              mutation={useFinancePaymentCreate}
              mutationOptions={{ refetchQueries: ["FinanceInvoice"] }}
              onMutate={(result) => result.financePaymentCreate}
              onClose={closeSidebar}
              fields={[
                {
                  label: intl.formatMessage(messages.account),
                  name: "accountId",
                  type: "custom",
                  rules: [Rules.required],
                  hidden:
                    !currentTenant.features.accounting ||
                    !!currentTenant.financeEntriesDisabled,
                  render: () => <AccountSelect mode="ledger" />,
                },
                {
                  label: intl.formatMessage(messages.paymentDate),
                  name: "paymentDate",
                  type: "datetime",
                  rules: [Rules.required],
                },
                {
                  type: "custom",
                  rules: [Rules.required],
                  hidden: currentTenant.currencyCode == invoice.currencyCode,
                  render: () => (
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        prev.paymentDate !== next.paymentDate
                      }
                      key="exchangeRate"
                    >
                      {({ getFieldValue }) => (
                        <ExchangeRateInput
                          name="exchangeRate"
                          to={currentTenant.currency}
                          from={invoice.currency}
                          date={getFieldValue("paymentDate")}
                        />
                      )}
                    </Form.Item>
                  ),
                },
                {
                  label: intl.formatMessage(messages.refNumber),
                  name: "internalId",
                  type: "string",
                },
                {
                  type: "custom",
                  render: () => (
                    <Row
                      justify="space-between"
                      key="payment-amounts"
                      style={{ marginBottom: "16px" }}
                    >
                      <Col>
                        <div>
                          <FormattedMessage id="invoices.originalAmount" />
                        </div>
                        {formatMoneyAmount(invoice.total, invoice.currencyCode)}
                      </Col>
                      <Col>
                        <div>
                          {isSale ? (
                            <FormattedMessage id="invoices.amountReceived" />
                          ) : (
                            <FormattedMessage id="invoices.amountPaid" />
                          )}
                        </div>
                        {formatMoneyAmount(
                          invoice.amountPaid,
                          invoice.currencyCode
                        )}
                      </Col>
                      <Col>
                        <div>
                          <FormattedMessage id="invoices.amountToPay" />
                        </div>
                        <Typography.Text type="danger">
                          {formatMoneyAmount(
                            invoice.amountToPay,
                            invoice.currencyCode
                          )}
                        </Typography.Text>
                      </Col>
                    </Row>
                  ),
                },
                {
                  label: intl.formatMessage(messages.amount),
                  name: "amounts",
                  type: "custom",
                  rules: [
                    Rules.required,
                    Rules.gtZero,
                    RuleBuilder.ltEq(invoice.amountToPay),
                  ],
                  render: () => (
                    <InputNumber addonBefore={invoice.currency.symbol} />
                  ),
                },
                {
                  type: "custom",
                  hidden: invoice.counterparty.withholdingRate == null,
                  render: () => (
                    <Form.Item
                      key="withholdings"
                      noStyle
                      shouldUpdate={shouldUpdate("withholdingRate", "amounts")}
                    >
                      {({ getFieldValue }) => {
                        const amount = getFieldValue("amounts") || 0;
                        const withholding =
                          getFieldValue("withholdingRate") || 0;
                        const retention =
                          (invoice.withholdAmount *
                            (amount / invoice.total) *
                            withholding) /
                          100;

                        return (
                          <Form.Item
                            label={intl.formatMessage(messages.withholding)}
                            name="withholdingRate"
                            rules={[Rules.percentage]}
                            help={
                              <span>
                                {intl.formatMessage(messages.withholdingHelp, {
                                  amount: formatMoneyAmount(
                                    retention,
                                    invoice.currencyCode
                                  ),
                                  net: formatMoneyAmount(
                                    amount - retention,
                                    invoice.currencyCode
                                  ),
                                })}
                              </span>
                            }
                          >
                            <InputNumber addonAfter="%" min={0} max={99} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  ),
                },
              ]}
              formValues={() =>
                ({
                  invoiceIds: [invoice.id],
                  amounts: [invoice.amountToPay],
                  exchangeRate:
                    currentTenant.currencyCode == invoice.currencyCode
                      ? 1
                      : undefined,
                  withholdingRate:
                    (invoice.counterparty.withholdingRate || 0) * 100,
                } as PaymentFields)
              }
              processValues={(value) => ({
                ...value,
                withholdingRate: value.withholdingRate / 100,
              })}
            />
          ),
          revertPayment: ({ item, setAction, closeSidebar }) =>
            item && (
              <ConfirmSidebar
                title={
                  <FormattedMessage
                    id="revert.header"
                    defaultMessage="revert {entityName}"
                    values={{ entityName }}
                  />
                }
                onCancel={() => setAction("viewPayment")}
                okButtonProps={{ danger: true }}
                okText={<FormattedMessage id="revert" />}
                onOk={({ showSuccess }) =>
                  update({
                    variables: {
                      id: item.id,
                      changes: { status: FinancePaymentStatus.Reverted },
                    },
                  }).then((result) => {
                    const updateResult =
                      result.data && result.data.financePaymentUpdate;

                    if (updateResult && updateResult.result) {
                      closeSidebar();

                      showSuccess(
                        intl.formatMessage(
                          { id: "revert.success" },
                          {
                            entityName,
                            id: item.id,
                          }
                        )
                      );
                    }
                  })
                }
              >
                <FormattedMessage
                  id="revert.confirm"
                  defaultMessage="confirm"
                />
              </ConfirmSidebar>
            ),
        }}
      />

      <BottomPanel
        sticky
        buttons={[
          BottomPanel.ReturnButton({ route: routes.finance.invoices.index }),
          !readonly && (
            <BottomPanel.ActionButton
              disable={false}
              onClick={() => setCurrentAction("newPayment")}
              content={
                isSale ? (
                  <FormattedMessage
                    id="payments.receive"
                    defaultMessage="Receive Payment"
                  />
                ) : (
                  <FormattedMessage id="payments.new" defaultMessage="new" />
                )
              }
            />
          ),
          invoice.invoiceStatus === InvoiceStatus.Completed && (
            <BottomPanel.DownloadPDFButton
              onClick={() => setCurrentAction("pdf")}
            />
          ),
        ]}
      />
    </>
  );
}
