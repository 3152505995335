import { useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { formatPdfPath } from "../../../lib/formats";
import { WorkOrderStatus } from "../../../lib/graphql";
import { useWorkOrderUpdate } from "../../../lib/hooks";
import { routerPush } from "../../../lib/routes";
import {
  ChangeLogSidebar,
  ConfirmSidebar,
  ItemSidebar,
  RevertSidebar,
  SidebarHeader,
  Space,
} from "../../shared";
import { ItemForm } from "../../shared/ListView/ItemForm";
import { NewActivitySidebar } from "../activities";
import { NewCropCycleForm } from "../cropCycles";
import { WorkOrderCloneForm } from "./WorkOrderCloneForm";
import { WorkOrderContext } from "./WorkOrderContext";
import { WorkOrderFormValues } from "./builder";
import { Col, Row, Typography } from "antd";
import { useLocalStorage } from "react-use";
import { Form, InputNumber } from "../../form";

const messages = defineMessages({
  canceled: {
    id: "cancel.doc.success",
    defaultMessage: "{entityName} #{id} was canceled.",
  },
  reverted: {
    id: "revert.success",
    defaultMessage: "{entityName} #{id} was reverted.",
  },
  reopened: {
    id: "reopen.success",
    defaultMessage: "{entityName} #{id} was re-opened.",
  },
  reopen: { id: "workOrders.reopen", defaultMessage: "Re-open Work Order" },
  workOrderEntity: { id: "workOrders.entityName" },
});

export function WorkOrderSidebar() {
  const { workOrder, rebuild } = useContext(WorkOrderContext);
  const intl = useIntl();
  const [update] = useWorkOrderUpdate();
  const entityName = intl.formatMessage(messages.workOrderEntity);

  return (
    <ItemSidebar
      item={workOrder}
      sidebarActions={{
        reopen: ({ closeSidebar }) => (
          <ConfirmSidebar
            title={intl.formatMessage(messages.reopen)}
            onCancel={closeSidebar}
            okText={intl.formatMessage(messages.reopen)}
            cancelText={<FormattedMessage id="cancel" />}
            onOk={({ showSuccess }) =>
              update({
                variables: {
                  id: workOrder.id,
                  changes: { status: WorkOrderStatus.Open },
                },
              }).then((result) => {
                const updateResult = result.data && result.data.workOrderUpdate;

                if (updateResult && updateResult.result) {
                  closeSidebar();
                  showSuccess(
                    intl.formatMessage(messages.reopened, {
                      entityName,
                      id: workOrder.id,
                    })
                  );
                  rebuild(updateResult.result, true);
                }
              })
            }
          >
            <FormattedMessage
              id="workOrders.reopen.confirm"
              defaultMessage="Are you sure want to re-open work order?"
            />
          </ConfirmSidebar>
        ),
        cancel: ({ closeSidebar }) => (
          <ConfirmSidebar
            title={
              <FormattedMessage
                id="cancel.doc.header"
                defaultMessage="cancel {entityName}"
                values={{ entityName }}
              />
            }
            onCancel={closeSidebar}
            okButtonProps={{ danger: true }}
            okText={
              <FormattedMessage id="cancel.doc" defaultMessage="Cancel" />
            }
            cancelText={
              <FormattedMessage id="cancel.doc.return" defaultMessage="Back" />
            }
            onOk={({ showSuccess }) =>
              update({
                variables: {
                  id: workOrder.id,
                  changes: { status: WorkOrderStatus.Canceled },
                },
              }).then((result) => {
                const updateResult = result.data && result.data.workOrderUpdate;

                if (updateResult && updateResult.result) {
                  closeSidebar();
                  showSuccess(
                    intl.formatMessage(messages.canceled, {
                      entityName,
                      id: workOrder.id,
                    })
                  );
                }
              })
            }
          >
            <FormattedMessage
              id="cancel.doc.confirm"
              defaultMessage="confirm"
            />
          </ConfirmSidebar>
        ),
        revert: ({ closeSidebar, setAction }) => (
          <RevertSidebar
            closeSidebar={closeSidebar}
            entityName={entityName}
            entity={workOrder}
            revert={() =>
              update({
                variables: {
                  id: workOrder.id,
                  changes: { status: WorkOrderStatus.Reverted },
                },
              }).then((result) => {
                setAction("");
                return result.data?.workOrderUpdate;
              })
            }
          />
        ),
        clone: ({ closeSidebar }) => (
          <WorkOrderCloneForm onClose={closeSidebar} />
        ),
        pdf: ({ closeSidebar }) => (
          <WorkOrderTemplatePDFSidebar
            closeSidebar={closeSidebar}
            workOrder={workOrder}
          />
        ),
        changeLog: ({ closeSidebar }) => (
          <ChangeLogSidebar
            id={workOrder.id}
            type="Agro::WorkOrder"
            onClose={closeSidebar}
          />
        ),
        newActivity: ({ closeSidebar }) => (
          <NewActivitySidebar onClose={closeSidebar} />
        ),
        newCropCycle: ({ closeSidebar }) => (
          <NewCropCycleForm onClose={closeSidebar} />
        ),
      }}
    />
  );
}

export function WorkOrderTemplatePDFSidebar({
  closeSidebar,
  pdfParams,
  workOrder,
}: {
  closeSidebar: () => void;
  pdfParams?: any;
  workOrder?: WorkOrderFormValues;
}) {
  const [localStorageValue, setLocalStorage] = useLocalStorage<{
    empty_crop_fields?: number;
    empty_employees?: number;
    empty_machines?: number;
    empty_inputs?: number;
  }>("wo-template:pdf-params", {});

  const value = workOrder
    ? {
        empty_crop_fields: 0,
        empty_employees: 0,
        empty_machines: 0,
        empty_inputs: 0,
      }
    : localStorageValue;

  return (
    <>
      <SidebarHeader
        title={
          <FormattedMessage
            id="workOrders.pdfTemplate.header"
            defaultMessage="Create and download a field report template"
          />
        }
        onClose={closeSidebar}
      />

      <Space vertical size="middle">
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          <FormattedMessage
            id="workOrders.pdf.rowsHint"
            defaultMessage="Set rows to be exported per section"
          />
        </Typography.Title>

        {workOrder && (
          <Row>
            <Col offset={6} span={18}>
              <Space justify="space-around">
                <FormattedMessage
                  id="workOrders.pdf.includedRows"
                  defaultMessage="Included rows"
                />

                <FormattedMessage
                  id="workOrders.pdf.additionalRows"
                  defaultMessage="Additional rows"
                />
              </Space>
            </Col>
          </Row>
        )}

        <ItemForm
          item={workOrder}
          saveText={<FormattedMessage id="downloadPdf" />}
          onCancel={closeSidebar}
          initialValues={value || {}}
          formProps={{
            layout: "horizontal",
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
          }}
          fields={[
            {
              label: <FormattedMessage id="cropFields" />,
              type: workOrder ? "custom" : "number",
              name: "empty_crop_fields",
              render: () => (
                <Space>
                  <InputNumber value={workOrder?.costCenters.length} disabled />
                  <Form.Item name="empty_crop_fields" noStyle>
                    <InputNumber step={1} min={0} max={100} />
                  </Form.Item>
                </Space>
              ),
              formItemProps: {
                style: {
                  alignItems: "end",
                },
              },
            },
            {
              label: <FormattedMessage id="employees" />,
              name: "empty_employees",
              type: workOrder ? "custom" : "number",
              render: () => (
                <Space>
                  <InputNumber value={workOrder?.employees.length} disabled />
                  <Form.Item name="empty_employees" noStyle>
                    <InputNumber step={1} min={0} max={100} />
                  </Form.Item>
                </Space>
              ),
            },
            {
              label: <FormattedMessage id="machines" />,
              name: "empty_machines",
              type: workOrder ? "custom" : "number",
              render: () => (
                <Space>
                  <InputNumber value={workOrder?.machines.length} disabled />
                  <Form.Item name="empty_machines" noStyle>
                    <InputNumber step={1} min={0} max={100} />
                  </Form.Item>
                </Space>
              ),
            },
            {
              label: <FormattedMessage id="workOrders.inputs" />,
              name: "empty_inputs",
              type: workOrder ? "custom" : "number",
              render: () => (
                <Space>
                  <InputNumber value={workOrder?.inputs.length} disabled />
                  <Form.Item name="empty_inputs" noStyle>
                    <InputNumber step={1} min={0} max={100} />
                  </Form.Item>
                </Space>
              ),
            },
          ]}
          onSave={(params) => {
            const pdfUrl = formatPdfPath(
              "work_order",
              workOrder?.id || "empty",
              {
                ...params,
                ...pdfParams,
              }
            );

            setLocalStorage(params);

            return Promise.resolve().then(() => {
              routerPush(pdfUrl);
              closeSidebar();
              return null;
            });
          }}
        />
      </Space>
    </>
  );
}
