import { FormattedMessage } from "react-intl";
import {
  ActivityMetricFragment,
  ActivityMetricScope,
} from "../../../lib/graphql";
import { TableInput, useItemSidebarContext } from "../../shared";
import {
  useActivityMetricScopeOptions,
  useMetricOptions,
} from "../../../lib/hooks";
import { formatPlaceholder, shouldUpdate } from "../../../lib/formats";
import { Form, SelectField, useFormContext } from "../../form";

export function ActivityMetrics({ readonly }: { readonly?: boolean }) {
  const { form } = useFormContext();
  const { setCurrentAction } = useItemSidebarContext();
  const { options } = useActivityMetricScopeOptions();

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate("activityMetrics")}>
      {({ getFieldValue }) => {
        const activityMetrics: ActivityMetricFragment[] =
          getFieldValue("activityMetrics") || [];

        return (
          <TableInput
            name="activityMetrics"
            dataSource={activityMetrics}
            disabled={readonly}
            rowKey={(m) => m.metric.id}
            addIndexColumn
            hideEmpty
            tableSelectProps={{
              mode: "multiple",
              optionsHook: useMetricOptions,
              placeholder: formatPlaceholder({ id: "metrics.entityName" }),
              entityById(_, { metric }) {
                return {
                  id: "",
                  index: activityMetrics.length,
                  metric,
                  scope: ActivityMetricScope.CostCenter,
                };
              },
              newItemProps: {
                entityName: <FormattedMessage id="metrics.entityName" />,
                onClick: () => setCurrentAction("newMetric"),
              },
            }}
            draggable={{
              onDragEnd: ({ active, over }) => {
                const metrics: ActivityMetricFragment[] =
                  form.getFieldValue("activityMetrics");
                const activeIndex = metrics.findIndex(
                  (c) => c.metric.id == active.id
                );
                const toIndex = metrics.findIndex(
                  (c) => c.metric.id == over?.id
                );
                metrics.splice(toIndex, 0, metrics.splice(activeIndex, 1)[0]);

                form.setFieldValue("activityMetrics", [...metrics]);
              },
            }}
            columns={[
              {
                title: <FormattedMessage id="metrics.entityName" />,
                render: (_, m) => m.metric.name,
              },
              {
                title: <FormattedMessage id="units.entityName" />,
                render: (_, m) => m.metric.unit.name,
              },
              {
                title: <FormattedMessage id="scope" defaultMessage="Scope" />,
                render: (_, _m, index) => (
                  <Form.Item name={["activityMetrics", index, "scope"]} compact>
                    <SelectField options={options} allowClear={false} />
                  </Form.Item>
                ),
              },
            ]}
          />
        );
      }}
    </Form.Item>
  );
}
