import { Tooltip } from "antd";
import { formatDateTime } from "../../../lib/formats";
import { formatUsername } from "../../../lib/formats/users";
import { UserShortFragment } from "../../../lib/graphql";

export default function ModifiedAtTooltip({
  modifiedAt,
  author,
}: {
  modifiedAt: Date | string;
  author?: UserShortFragment | null;
}) {
  return (
    <Tooltip title={formatDateTime(modifiedAt)}>
      <span>{formatUsername(author)}</span>
    </Tooltip>
  );
}
