import { Col, Row, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { useCurrentUser, usePaymentMethodOptions } from "../../lib/hooks";
import { AccountSelect } from "../finance";
import { Form, SelectField } from "../form";

export function CounterpartyCustomer() {
  const { currentTenant } = useCurrentUser();
  const { loadingPaymentMethods, paymentMethodOptions } =
    usePaymentMethodOptions();

  return (
    <Row>
      <Col xs={24} md={12}>
        <Row gutter={32}>
          <Col span={24}>
            <Typography.Text type="secondary">
              <FormattedMessage
                id="counterparties.customerConfig.hint"
                defaultMessage="customerConfig.hint"
              />
            </Typography.Text>

            <Form.Item
              name="customerPaymentMethodId"
              label={
                <FormattedMessage
                  id="paymentMethods"
                  defaultMessage="Payment Terms"
                />
              }
              style={{ marginTop: "16px" }}
            >
              <SelectField
                loading={loadingPaymentMethods}
                options={paymentMethodOptions}
                showGroups
              />
            </Form.Item>

            {currentTenant.features.accounting && (
              <Form.Item
                name="receivableAccountId"
                label={
                  <FormattedMessage
                    id="counterparties.accountReceivable"
                    defaultMessage="accountReceivable"
                  />
                }
              >
                <AccountSelect mode="ledger" />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
