import { sumBy } from "lodash";
import {
  distributeEqually,
  distributeProportionally,
  roundUnit,
} from "../../../../../lib/formats";
import {
  ActivityMetricScope,
  WorkOrderCostCenterMetricFragment,
} from "../../../../../lib/graphql";
import { WorkOrderCostCenter } from "../costCenters";
import { WorkOrderMetricBuilder } from "./base";
import { debounce, filterFalse } from "../../../../../lib/utils";

export class OutputMetricBuilder extends WorkOrderMetricBuilder {
  public debouncedDistributeIndividualMetrics = debounce(
    this.distributeIndividualMetrics
  );

  protected finder(metricId: string, individualId?: string) {
    return (m: WorkOrderCostCenterMetricFragment) =>
      m.metricId == metricId && m.variantId == individualId;
  }

  protected isScoped(scope: ActivityMetricScope) {
    return scope == ActivityMetricScope.Output;
  }

  initInidividualMetricFields() {
    const outputs = this.builder.outputs.getProcessingOutputs();
    this.activityMetrics.forEach(({ scope, metric }) => {
      if (!this.isScoped(scope)) return;

      outputs.forEach((o, index) => {
        this.builder.form.setFieldValue(
          ["processingOutputs", index, metric.id],
          this.getTotalMetric(metric.id, undefined, o.variant.id)
        );
      });
    });
  }

  build(costCenter: WorkOrderCostCenter) {
    const outputs = this.builder.outputs.getProcessingOutputs();

    return filterFalse(
      this.activityMetrics.flatMap(({ scope, metric }) => {
        if (!this.isScoped(scope)) return;

        const total = this.builder.costCenters.metrics.getTotalMetric(
          metric.id,
          costCenter
        );

        const distribution = distributeProportionally(
          total,
          outputs.map((o) => o.totalAmount || 0)
        );

        return outputs.map(({ variant }, index) => {
          if (costCenter.metrics.find(this.finder(metric.id, variant.id)))
            return;

          return {
            id: "",
            metricId: metric.id,
            variantId: variant.id,
            value: distribution[index],
          };
        });
      })
    );
  }

  getTotalMetric(
    metricId: string,
    costCenter?: WorkOrderCostCenter,
    individualId?: string
  ) {
    const costCenters = costCenter
      ? [costCenter]
      : this.builder.costCenters.get();

    return roundUnit(
      sumBy(costCenters, (cc) =>
        sumBy(
          cc.metrics.filter(
            (m) =>
              m.metricId == metricId &&
              m.variantId &&
              (!individualId || m.variantId == individualId)
          ),
          "value"
        )
      )
    );
  }

  distributeMetric(metricId: string, value?: number | null, ccIndex?: number) {
    if (value == null || ccIndex == null) return;

    const outputs = this.builder.outputs.getProcessingOutputs();

    const distribution = distributeProportionally(
      value,
      outputs.map((e) => e.totalAmount || 0)
    );

    outputs.forEach((o, index) => {
      this.builder.form.setFieldValue(
        this.metricValueFieldName(ccIndex, metricId, o.variant.id),
        distribution[index]
      );
    });
  }

  distributeIndividualMetrics(
    metricId: string,
    outputId: string,
    value?: number | null
  ) {
    if (value == null) return;

    const distribution = distributeEqually(
      value,
      this.builder.costCenters.get().length
    );

    distribution.forEach((value, ccIndex) => {
      this.builder.form.setFieldValue(
        this.metricValueFieldName(ccIndex, metricId, outputId),
        value
      );
      this.builder.costCenters.metrics.recalculate(metricId, ccIndex);
    });
  }
}
