import { JournalEntry, JournalEntryStatus } from "../../lib/graphql";
import { ActionButton } from "../shared";
import { useItemSidebarContext, usePermissions } from "../../lib/hooks";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { formatPdfPath } from "../../lib/formats";

interface JournalEntryABProps {
  entry: Pick<JournalEntry, "id" | "status" | "internalId">;
  entityName: string;
}

export function JournalEntryActionButton({
  entry,
  entityName,
}: JournalEntryABProps) {
  const { setCurrentAction } = useItemSidebarContext();
  const canRevert = usePermissions((p) => p.financeOrder?.delete);

  const actions: ItemType[] = [
    ActionButton.DownloadPDF({
      downloadLink: formatPdfPath("journal_entry", entry.id),
    }),
    ActionButton.CopyLinkMenuItem(),
    ActionButton.ChangeLog(() => setCurrentAction("changeLog")),
    ActionButton.Clone({
      onClick: () => setCurrentAction("clone"),
      entityName,
    }),
  ];
  if (entry.status === JournalEntryStatus.Open) {
    actions.push(
      { type: "divider" },
      ActionButton.Cancel(() => setCurrentAction("cancel"))
    );
  } else if (entry.status === JournalEntryStatus.Completed && canRevert) {
    actions.push(
      { type: "divider" },
      ActionButton.Revert(() => setCurrentAction("revert"))
    );
  }

  return <ActionButton menuItems={actions} />;
}
