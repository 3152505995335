import { Col, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { roundUnit } from "../../lib/formats";
import { ActiveIngredientSelect } from "../agro/activeIngredients";
import { InputNumber, Form, DeepNamePath, Rules } from "../form";

interface ItemIngredientInput {
  name: DeepNamePath;
}

export function ItemIngredientsInput({ name }: ItemIngredientInput) {
  return (
    <Form.List
      name={name}
      addText={
        <FormattedMessage
          id="items.addIngredient"
          defaultMessage="Add Ingredient"
        />
      }
      renderItem={({ key, name: index }, removeIcon) => (
        <Row key={key} align="middle" gutter={16}>
          <Col span={12}>
            <Form.Item
              compact
              name={[index, "ingredientId"]}
              rules={[Rules.required]}
            >
              <ActiveIngredientSelect mode={undefined} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              compact
              name={[index, "rate"]}
              rules={[Rules.percentage]}
            >
              <InputNumber
                className="ingredient-rate"
                min={0}
                max={1}
                step={0.01}
                formatter={(value: any) =>
                  value ? roundUnit(value * 100.0).toString() : ""
                }
                parser={(value: any) => (value ? value / 100.0 : 0)}
                addonAfter="%"
                style={{ margin: "4px 0" }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>{removeIcon}</Col>
        </Row>
      )}
    />
  );
}
