import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderAttendance } from "../../WorkOrderAttendance";
import { WorkOrderEmployees } from "../../WorkOrderEmployees";

export function Employees(
  visible: boolean,
  readonly: boolean,
  disallowRemove: boolean,
  isInfrastructural: boolean,
  isMachineryMaintenance: boolean,
  isTransitAndTransport: boolean,
  isProcessing: boolean
) {
  return {
    key: "manual-labor",
    visible,
    icon: (
      <Icon
        component={() => (
          <img
            src={
              isInfrastructural ||
              isMachineryMaintenance ||
              isTransitAndTransport
                ? "/images/icons/mechanic.svg"
                : isProcessing
                ? "/images/icons/gloves.svg"
                : "/images/icons/labor.svg"
            }
            width={42}
          />
        )}
      />
    ),
    name: (
      <FormattedMessage
        id="workOrders.manualLabor"
        defaultMessage="manualLabor"
      />
    ),
    views: [
      {
        title: (
          <FormattedMessage
            id="workOrders.manualLabor"
            defaultMessage="manualLabor"
          />
        ),
        description: (
          <FormattedMessage
            id="workOrders.manualLabor.hint"
            defaultMessage="hint"
          />
        ),
        render: () => <WorkOrderEmployees readonly={readonly} />,
      },
    ],
    disallowRemove,
  };
}

export function Attendance(
  visible: boolean,
  readonly: boolean,
  showProgress: boolean
) {
  return {
    key: "attendance",
    visible,
    icon: <Icon component={() => <img src="/images/icons/attendance.svg" />} />,
    name: (
      <FormattedMessage
        id="workOrders.manualLabor"
        defaultMessage="manualLabor"
      />
    ),
    views: [
      {
        title: (
          <FormattedMessage
            id="workOrders.manualLabor"
            defaultMessage="manualLabor"
          />
        ),
        description: showProgress ? (
          <FormattedMessage
            id="workOrders.attendance.hint.progress"
            defaultMessage="attendance"
          />
        ) : (
          <FormattedMessage
            id="workOrders.attendance.hint"
            defaultMessage="attendance"
          />
        ),
        render: () => <WorkOrderAttendance readonly={readonly} />,
      },
    ],
  };
}
