import { FormattedMessage } from "react-intl";
import { evictCache, useTransferClone } from "../../../lib/hooks";
import { routerPush } from "../../../lib/routes";

import { SidebarHeader } from "../../shared";
import { ItemForm } from "../../shared/ListView/ItemForm";
import { TransferFragment } from "../../../lib/graphql";

export function CloneForm({
  item,
  entityName,
  entityUrl,
  onClose,
}: {
  item: TransferFragment;
  entityName: string;
  entityUrl: (id: string) => string;
  onClose: () => void;
}) {
  const [clone] = useTransferClone();

  const title = (
    <FormattedMessage
      id="duplicateEntity"
      defaultMessage="Duplicate {entityName}"
      values={{ entityName }}
    />
  );

  return (
    <>
      <SidebarHeader title={title} onClose={onClose} />

      <ItemForm
        item={item}
        saveText={title}
        onCancel={onClose}
        initialValues={{
          documentDate: item.documentDate,
          internalId: item.internalId,
        }}
        fields={[
          {
            label: <FormattedMessage id="documentDate" />,
            type: "date",
            name: "documentDate",
          },
          {
            label: <FormattedMessage id="internalId" />,
            type: "string",
            name: "internalId",
          },
        ]}
        onSave={(changes) => {
          return clone({
            update: evictCache("stockTransfers"),
            variables: { id: item.id, changes },
          })
            .then((result) => result.data?.stockTransferClone)
            .then((data) => {
              if (data?.result) {
                onClose();
                routerPush(entityUrl(data.result.id));
              }

              return data;
            });
        }}
      />
    </>
  );
}
