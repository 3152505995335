import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import {
  formatVariantQuantity,
  formatMoneyAmount,
  translate,
  formatInfoTooltip,
  formatFinalStock,
  shouldUpdateBy,
  formatVariantLink,
  formatAdjustment,
} from "../../lib/formats";
import {
  TransferForm,
  TransferFormProps,
  VariantTransferValue,
} from "./TransferForm";
import routes from "../../lib/routes";
import { QuantityFormItem } from "./QuantityFormItem";
import { Typography } from "antd";
import {
  AdjustmentTypeFragment,
  AdjustmentKind,
  TransferStatus,
  VirtualWarehouse,
} from "../../lib/graphql";
import { Form, InputNumber, Rules } from "../form";
import { useCurrentUser } from "../../lib/hooks";
import { ColumnsType } from "antd/lib/table";
import { StockVariantTableInputProps } from "./StockVariantTableInput";

export function isTokenRedeem(type?: AdjustmentTypeFragment) {
  return type?.entryCode == "TR";
}

export function isOpeningBalance(type?: AdjustmentTypeFragment) {
  return type?.entryCode == "OS";
}

export function isAdjustCost(type?: AdjustmentTypeFragment) {
  return type?.entryCode == "AC";
}

export function isLost(type?: AdjustmentTypeFragment) {
  return type?.entryCode == "LS" || type?.entryCode == "ST";
}

export function isReconciliation(type?: AdjustmentTypeFragment) {
  return type?.entryCode == "RC";
}

export function translateAdjustmentType(
  type?: AdjustmentTypeFragment,
  status?: TransferStatus
) {
  const kind = isTokenRedeem(type)
    ? "redeem"
    : isReconciliation(type)
    ? "reconciliation"
    : type?.kind || AdjustmentKind.Other;
  const statusSuffix = status === TransferStatus.Completed ? ".completed" : "";
  return translate(`adjustmentKinds.${kind}.qty${statusSuffix}`);
}

const messages = defineMessages({
  openingStockBalance: {
    id: "openingStockBalance",
    defaultMessage: "Opening Stock Balance",
  },
});

export function AdjustmentForm({
  values,
  onSave,
}: Pick<TransferFormProps, "values" | "onSave">) {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();

  const importHeaders = {
    quantity: intl.formatMessage({ id: "stockReconciliations.newQty" }),
    openingStock: intl.formatMessage(messages.openingStockBalance),
    newCost: intl.formatMessage({ id: "unitCost" }),
  };

  return (
    <TransferForm
      showAdjustmentType
      entityName={intl.formatMessage({ id: "stockAdjustments.entityName" })}
      values={values}
      transferUrl={routes.inventory.adjustments.index}
      onSave={onSave}
      variantTableProps={(type, formValue) => {
        const adjustmentKind = type?.kind;

        const columns: ColumnsType<VariantTransferValue> = [
          {
            title: (
              <FormattedMessage
                id="variants.entityName"
                defaultMessage="variant"
              />
            ),
            dataIndex: "name",
            render: (_, v) => formatVariantLink(v),
          },
          {
            title: isTokenRedeem(type) ? (
              <FormattedMessage
                id="tokens.inCirculation"
                defaultMessage="inCirculation"
              />
            ) : (
              <FormattedMessage id="stock.onHand" defaultMessage="onHand" />
            ),
            render: (_, v) => (
              <Typography.Text
                type={
                  adjustmentKind === AdjustmentKind.Decremental &&
                  v.stock.onHand <= 0
                    ? "danger"
                    : undefined
                }
              >
                {formatVariantQuantity(v, v.stock.onHand, true)}
              </Typography.Text>
            ),
          },
          {
            title: isOpeningBalance(type)
              ? intl.formatMessage(messages.openingStockBalance)
              : translateAdjustmentType(type),
            width: 400,
            render: (_, v, index) => (
              <QuantityFormItem
                name={["variants", index, "quantity"]}
                variant={v}
                rules={
                  adjustmentKind === AdjustmentKind.Decremental
                    ? TransferForm.stockPresence(index)
                    : [Rules.gtEqZero]
                }
              />
            ),
          },
        ];

        const importProps: Record<
          AdjustmentTypeFragment["entryCode"],
          StockVariantTableInputProps<VariantTransferValue>["importProps"]
        > = {
          OS: {
            headers: {
              quantity: {
                editable: true,
                label: importHeaders.openingStock,
              },
              newCost: {
                editable: true,
                label: importHeaders.newCost,
                transformer: (v) => v || 0,
              },
            },
            tooltip: (
              <FormattedMessage
                id="stockAdjustments.open.import.hint"
                defaultMessage="Set initial stock balances and cost"
              />
            ),
            filter: (v) => v[importHeaders.openingStock] > 0,
          },
          RC: {
            headers: {
              stock_on_hand: {
                label: intl.formatMessage({ id: "stock.onHand" }),
              },
              quantity: {
                editable: true,
                label: importHeaders.quantity,
              },
            },
            tooltip: (
              <FormattedMessage
                id="stockAdjustments.stock.import.hint"
                defaultMessage="Set new stock balances"
              />
            ),
          },
        };

        const renderCostFormItem = (_: any, _v: any, index: number) => (
          <Form.Item
            name={["variants", index, "newCost"]}
            compact
            rules={[Rules.gtEqZero]}
          >
            <InputNumber addonBefore={currentTenant.currency.symbol} />
          </Form.Item>
        );

        if (isOpeningBalance(type)) {
          columns.splice(
            3,
            0,
            {
              title: (
                <FormattedMessage id="variants.cost" defaultMessage="cost" />
              ),
              width: 200,
              render: renderCostFormItem,
            },
            {
              title: <FormattedMessage id="total" defaultMessage="total" />,
              render: (_, _v, index) => (
                <Form.Item
                  shouldUpdate={shouldUpdateBy(
                    (v) => v.variants[index]?.quantity,
                    (v) => v.variants[index]?.newCost
                  )}
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const quantity =
                      getFieldValue(["variants", index, "quantity"]) || 0;
                    const cost =
                      getFieldValue(["variants", index, "newCost"]) || 0;

                    return formatMoneyAmount(
                      quantity * cost,
                      currentTenant.currencyCode
                    );
                  }}
                </Form.Item>
              ),
            }
          );
        } else if (isAdjustCost(type)) {
          columns.splice(
            2,
            1,
            {
              title: (
                <>
                  <FormattedMessage id="unitCost" />{" "}
                  {formatInfoTooltip(
                    <FormattedMessage
                      id="unitCost.documentDate"
                      defaultMessage="Unit Cost at Document Date & Time"
                    />
                  )}
                </>
              ),
              align: "right",
              render: (_, v) =>
                v.cost &&
                formatMoneyAmount(v.cost, currentTenant.currencyCode, {
                  maximumFractionDigits: 4,
                }),
            },
            {
              title: (
                <FormattedMessage
                  id="stockAdjustments.newCost"
                  defaultMessage="New Cost"
                />
              ),
              width: 200,
              render: renderCostFormItem,
            }
          );
        } else if (!isReconciliation(type)) {
          columns.push({
            title: <FormattedMessage id="variants.finalStock" />,
            render: (_, v, index) => (
              <Form.Item
                compact
                shouldUpdate={(prev, next) =>
                  prev.variants[index]?.quantity !=
                  next.variants[index]?.quantity
                }
              >
                {({ getFieldValue }) => {
                  const quantity =
                    getFieldValue(["variants", index, "quantity"]) || 0;

                  return (
                    adjustmentKind &&
                    formatVariantQuantity(
                      v,
                      formatFinalStock(
                        v.stock.onHand,
                        quantity * v.variationValue,
                        adjustmentKind
                      ),
                      true
                    )
                  );
                }}
              </Form.Item>
            ),
          });
        } else {
          columns.push({
            title: (
              <FormattedMessage id="adjustment" defaultMessage="Adjustment" />
            ),
            render: (_, v, index) => (
              <Form.Item
                compact
                shouldUpdate={(prev, next) =>
                  prev.variants[index]?.quantity !=
                  next.variants[index]?.quantity
                }
              >
                {({ getFieldValue }) => {
                  const quantity = getFieldValue([
                    "variants",
                    index,
                    "quantity",
                  ]);

                  return formatAdjustment(v, quantity);
                }}
              </Form.Item>
            ),
          });
        }

        return {
          columns,
          virtualWarehouse: isTokenRedeem(type)
            ? VirtualWarehouse.Employees
            : undefined,
          dropdownRightTitle: isTokenRedeem(type) ? (
            <FormattedMessage
              id="tokens.inCirculation"
              defaultMessage="inCirculation"
            />
          ) : (
            <FormattedMessage
              id="variants.runningStock"
              defaultMessage="runningStock"
            />
          ),
          withStockOnly: isLost(type),
          optionsHookVariables: {
            date: formValue?.documentDate,
            fetchCost: isAdjustCost(type),
            filter: {
              noStockBalance: isOpeningBalance(type)
                ? formValue?.sourceWarehouseId
                : undefined,
            },
          },
          importProps: type?.entryCode
            ? importProps[type?.entryCode]
            : undefined,
        };
      }}
    />
  );
}
