import {
  ActivityChanges,
  ActivityDetailsFragment,
  ActivityFields,
  ActivityMetricFragment,
} from "../../../lib/graphql";
import { Destroy, SaveIDFunction } from "../../../lib/hooks";
import routes, { routerPush } from "../../../lib/routes";
import { Form } from "../../form";
import { ActivityContent } from "./ActivityContent";
import { BottomPanel } from "../../shared";

export type ActivityFormValues = ActivityChanges &
  ActivityFields & {
    activityMetrics?: Destroy<ActivityMetricFragment>[];
  };

export interface ActivityFormProps {
  initialValues: ActivityFormValues;
  activity?: ActivityDetailsFragment;
  onSave(values: ActivityFormValues): SaveIDFunction;
}

export function ActivityForm({
  initialValues,
  onSave,
  activity,
}: ActivityFormProps) {
  const mode = initialValues.name ? "edit" : "new";

  return (
    <Form
      preventLeaving
      layout="vertical"
      style={{ marginTop: "16px" }}
      initialValues={initialValues}
      onSubmit={(activity, { setSubmitting, showErrors }) => {
        const promise = onSave(activity);
        if (!promise) return;

        promise.then((a) => {
          if (!a) return;

          if (a.result) {
            routerPush(a.result.id, routes.agro.activities.details);
          } else if (a.errors) {
            showErrors(a.errors);
            setSubmitting(false);
          }
        });
      }}
    >
      <ActivityContent mode={mode} activity={activity} />

      <BottomPanel
        buttons={[
          BottomPanel.CancelButton({
            route: routes.agro.activities.index,
          }),
          BottomPanel.SubmitButton(),
        ]}
      />
    </Form>
  );
}
