export interface SectionProps {
  key: string;
  icon: JSX.Element;
  name: JSX.Element;
  extra?: () => JSX.Element;
  views: Array<{
    title: JSX.Element;
    description?: JSX.Element;
    menuItem?: JSX.Element;
    render(): JSX.Element;
  }>;
  viewProperty?: string;
  currentViewIndex?: number;
  visible: boolean;
  hidden?: boolean;
  collapsed?: boolean;
  disallowRemove?: boolean;
}

export class SectionBuilder {
  public current: SectionProps[] = [];

  public onUpdate = () => {};

  onShowSection(section: SectionProps) {
    if (section.visible) return;

    section.visible = true;
    section.hidden = section.collapsed = false;

    this.onUpdate();
  }

  onHideSection(section: SectionProps) {
    if (section.hidden) return;

    section.visible = section.collapsed = false;
    section.hidden = true;

    this.onUpdate();
  }

  onCollapseSection(section: SectionProps) {
    if (section.collapsed) return;

    section.collapsed = true;

    this.onUpdate();
  }

  onChangeSectionView(section: SectionProps, viewIndex: number) {
    if (section.currentViewIndex === viewIndex) return;

    section.currentViewIndex = viewIndex;

    this.onUpdate();
  }
}
