import {
  TaxComponentFragment,
  TaxPlanComponentFragment,
  TaxPlanFragment,
} from "../../../lib/graphql";
import { ItemSidebar, useItemSidebarContext } from "../../shared";
import { TaxAccountsForm } from "./TaxAccountsForm";
import { TaxComponentForm, TaxPlanComponentForm } from "./TaxComponentForm";

export function TaxPlanSidebar({
  taxPlan,
  onTaxComponentSave,
  onTaxPlanComponentSave,
}: {
  taxPlan: Pick<TaxPlanFragment, "components">;
  taxComponent?: TaxComponentFragment;
  taxPlanComponent?: TaxPlanComponentFragment;
  onTaxComponentSave: (tax: TaxComponentFragment) => void;
  onTaxPlanComponentSave: (component: TaxPlanComponentFragment) => void;
}) {
  const { currentState } = useItemSidebarContext();

  return (
    <ItemSidebar
      item={{} as TaxComponentFragment}
      sidebarActions={{
        newTaxComponent: ({ closeSidebar }) => (
          <TaxComponentForm
            availableComponents={taxPlan.components.map((c) => c.taxComponent)}
            onClose={(result) => {
              if (result) onTaxComponentSave(result);
              closeSidebar();
            }}
          />
        ),
        editTaxComponent: ({ closeSidebar }) => (
          <TaxComponentForm
            availableComponents={taxPlan.components.map((c) => c.taxComponent)}
            taxComponent={currentState}
            onClose={(result) => {
              if (result) onTaxComponentSave(result);
              closeSidebar();
            }}
          />
        ),
        editTaxPlanComponent: ({ closeSidebar }) => (
          <TaxPlanComponentForm
            component={currentState}
            onClose={(result) => {
              if (result)
                onTaxPlanComponentSave({ ...currentState, ...result });
              closeSidebar();
            }}
          />
        ),
        editTaxAccounts: ({ closeSidebar }) => (
          <TaxAccountsForm
            taxComponent={currentState}
            onClose={(result) => {
              if (result) onTaxComponentSave(result);
              closeSidebar();
            }}
          />
        ),
      }}
    />
  );
}
