import { Row, Col, Input } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import { shouldUpdate, formatPlaceholder } from "../../lib/formats";
import { useCurrentUser, useLocalityOptions } from "../../lib/hooks";
import { CostCenterSelect } from "../finance";
import { Form, NamePath, Rules, SelectField } from "../form";

export function LocalityCostCenterListFormItem({
  name,
  required,
}: {
  name: NamePath;
  required?: boolean;
}) {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();

  const span = currentTenant.features.costCenters ? 11 : 22;

  return (
    <Form.Item
      key="locality-cost-centers"
      noStyle
      shouldUpdate={shouldUpdate(name)}
    >
      {({ getFieldValue }) => (
        <div style={{ marginBottom: 16 }}>
          <Row align="middle" gutter={[2, 0]} style={{ marginBottom: 2 }}>
            <Col span={span}>
              <Form.Item
                label={<FormattedMessage id="localities" />}
                required={required}
                compact
              />
            </Col>
            {currentTenant.features.costCenters && (
              <Col span={13}>
                <Form.Item
                  label={<FormattedMessage id="costCenters" />}
                  compact
                />
              </Col>
            )}

            {!required &&
            !getFieldValue(name).filter(Form.undestroyed).length ? (
              <>
                <Col span={span}>
                  <Input
                    readOnly
                    value={intl.formatMessage({ id: "localities.all" })}
                  />
                </Col>
                {currentTenant.features.costCenters && (
                  <Col span={13}>
                    <Form.Item name="costCenterId" compact>
                      <CostCenterSelect useDefault />
                    </Form.Item>
                  </Col>
                )}
              </>
            ) : null}
          </Row>

          <Form.List
            name={name}
            hideRemoveLabel
            addText={
              <FormattedMessage
                id="cropStages.addLocality"
                defaultMessage="Add Specific Farm"
              />
            }
            renderItem={({ key, name: index }, removeIcon) => (
              <Row key={key} align="top" gutter={2} style={{ marginBottom: 2 }}>
                <Col span={span}>
                  <Form.Item
                    compact
                    name={[index, "localityId"]}
                    rules={[Rules.required]}
                  >
                    <SelectField
                      optionsHook={useLocalityOptions}
                      placeholder={formatPlaceholder({
                        id: "localities.entityName",
                      })}
                    />
                  </Form.Item>
                </Col>

                {currentTenant.features.costCenters && (
                  <Col span={span}>
                    <Form.Item compact name={[index, "costCenterId"]}>
                      <CostCenterSelect useDefault />
                    </Form.Item>
                  </Col>
                )}

                <Col span={2}>{removeIcon}</Col>
              </Row>
            )}
          />
        </div>
      )}
    </Form.Item>
  );
}
