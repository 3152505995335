import {
  InventoryOrderFragment,
  InventoryOrderStatus,
} from "../../lib/graphql";
import { ActionButton } from "../shared";
import { useItemSidebarContext, usePermissions } from "../../lib/hooks";
import { formatPdfPath } from "../../lib/formats";
import { ItemType } from "antd/lib/menu/hooks/useItems";

export function InventoryOrderActionButton({
  order,
  entityName,
}: {
  order: InventoryOrderFragment;
  entityName: string;
}) {
  const { setCurrentAction } = useItemSidebarContext();
  const canRevert = usePermissions((p) => p.financeOrder?.delete);

  if (
    order.status === InventoryOrderStatus.Completing ||
    order.status === InventoryOrderStatus.Reverting
  )
    return null;

  const actions: ItemType[] = [
    ActionButton.CopyLinkMenuItem(),
    ActionButton.ChangeLog(() => setCurrentAction("changeLog")),
    ActionButton.DownloadPDF({
      downloadLink: formatPdfPath("inventory_order", order.id),
    }),
    ActionButton.Clone({
      onClick: () => setCurrentAction("clone"),
      entityName,
    }),
  ];

  if (order.status === InventoryOrderStatus.Open) {
    actions.push(
      { type: "divider" },
      ActionButton.Cancel(() => setCurrentAction("cancel"))
    );
  } else if (order.status === InventoryOrderStatus.Completed && canRevert) {
    actions.push(
      { type: "divider" },
      ActionButton.Revert(() => setCurrentAction("revert"))
    );
  }

  return <ActionButton menuItems={actions} />;
}
