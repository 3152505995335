import { FormattedMessage } from "react-intl";

import { formatDetailsLink, formatUnitValue } from "../../../lib/formats";
import routes from "../../../lib/routes";
import { useContext } from "react";
import { ProfitableType, InfrastructureFragment } from "../../../lib/graphql";
import { useInfrastructureOptions } from "../../../lib/hooks";
import { WorkOrderContext } from "./WorkOrderContext";
import { WorkOrderCostCenters } from "./costCenters";

export function WorkOrderInfrastructures({
  byPercentage,
}: {
  byPercentage?: boolean;
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const filter = { localityId: [workOrder.locality.id] };

  return (
    <WorkOrderCostCenters
      byPercentage={byPercentage}
      tableSelectProps={{
        mode: "multiple",
        optionsHook: useInfrastructureOptions,
        optionsHookParams: { variables: { filter, pageSize: 75 } },
        showGroups: true,
        filterOption: true,
        placeholder: (
          <FormattedMessage
            id="select.infrastructure"
            defaultMessage="infrastructure"
          />
        ),
        entityById: (
          _,
          {
            infrastructure,
          }: {
            infrastructure: InfrastructureFragment;
          }
        ) => {
          const defaultCostCenter = {
            id: "",
            name: "",
          };
          const defaultProps = {
            id: "",
            dayGoal: 0,
            totalProgress: 0,
            plannedProgress: 0,
            employees: [],
            machines: [],
            variants: [],
            metrics: builder.costCenters.metrics.build(),
          };

          return {
            ...defaultProps,
            profitableId: infrastructure.id,
            profitableType: ProfitableType.Infrastructure,
            profitableName: infrastructure.name,
            dayGoal: infrastructure.total || 0,
            costCenter: infrastructure.costCenter || defaultCostCenter,
            infrastructure,
          };
        },
      }}
      columns={[
        {
          title: <FormattedMessage id="name" />,
          dataIndex: "name",
          render: (_, c) =>
            formatDetailsLink({
              id: c.profitableId || "",
              route: routes.infrastructures.details,
              title: c.profitableName,
            }),
        },
        {
          title: (
            <FormattedMessage
              id="costCenters.entityName"
              defaultMessage="costCenter"
            />
          ),
          dataIndex: "costCenter",
          render: (_, c) => c.costCenter.name,
        },
        {
          title: <FormattedMessage id="total" defaultMessage="total" />,
          dataIndex: ["infrastructure", "total"],
          render: (_, c) =>
            formatUnitValue(c.infrastructure?.total, c.infrastructure?.unit),
        },
      ]}
    />
  );
}
