import { sumBy } from "lodash";
import {
  distributeProportionally,
  roundUnit,
} from "../../../../../lib/formats";
import {
  ActivityMetricScope,
  WorkOrderCostCenterMetricFragment,
} from "../../../../../lib/graphql";
import { WorkOrderCostCenter } from "../costCenters";
import { WorkOrderMetricBuilder } from "./base";
import { debounce, filterFalse } from "../../../../../lib/utils";

export class MachineMetricBuilder extends WorkOrderMetricBuilder {
  public debouncedDistributeIndividualMetrics = debounce(
    this.distributeIndividualMetrics
  );

  protected finder(metricId: string, individualId?: string) {
    return (m: WorkOrderCostCenterMetricFragment) =>
      m.metricId == metricId && m.machineId == individualId;
  }

  protected isScoped(scope: ActivityMetricScope) {
    if (scope == ActivityMetricScope.Machine) return true;

    return scope == ActivityMetricScope.CostCenter && this.builder.isMachinery;
  }

  initInidividualMetricFields() {
    const machines = this.builder.machines.get(false);
    this.activityMetrics.forEach(({ scope, metric }) => {
      if (!this.isScoped(scope)) return;

      machines.forEach((m, index) => {
        this.builder.form.setFieldValue(
          ["machines", index, metric.id],
          this.getTotalMetric(metric.id, undefined, m.machine.id)
        );
      });
    });
  }

  build(costCenter: WorkOrderCostCenter) {
    const machines = this.builder.machines.get();

    return filterFalse(
      this.activityMetrics.flatMap(({ scope, metric }) => {
        if (!this.isScoped(scope)) return;

        const total = this.builder.costCenters.metrics.getTotalMetric(
          metric.id,
          costCenter
        );

        const distribution = distributeProportionally(
          total,
          machines.map((m) => m.totalProgress || 0)
        );

        return machines.map(({ machine }, index) => {
          if (costCenter.metrics.find(this.finder(metric.id, machine.id)))
            return;

          return {
            id: "",
            metricId: metric.id,
            machineId: machine.id,
            value: distribution[index],
          };
        });
      })
    );
  }

  getTotalMetric(
    metricId: string,
    costCenter?: WorkOrderCostCenter,
    individualId?: string
  ) {
    const costCenters = costCenter
      ? [costCenter]
      : this.builder.costCenters.get();

    return roundUnit(
      sumBy(costCenters, (cc) =>
        sumBy(
          cc.metrics.filter(
            (m) =>
              m.metricId == metricId &&
              m.machineId &&
              (!individualId || m.machineId == individualId)
          ),
          "value"
        )
      )
    );
  }

  distributeMetric(metricId: string, value?: number | null, ccIndex?: number) {
    if (value == null || !ccIndex) return;

    const machines = this.builder.machines.get();

    const distribution = distributeProportionally(
      value,
      machines.map((m) => m.totalProgress || 0)
    );

    machines.forEach((m, index) => {
      this.builder.form.setFieldValue(
        this.metricValueFieldName(ccIndex, metricId, m.machine.id),
        distribution[index]
      );
    });
  }

  distributeIndividualMetrics(
    metricId: string,
    machineId: string,
    value?: number | null
  ) {
    if (value == null) return;

    const distribution =
      this.builder.costCenters.metrics.distributeCostCenters(value);

    distribution.forEach((value, ccIndex) => {
      this.builder.form.setFieldValue(
        this.metricValueFieldName(ccIndex, metricId, machineId),
        value
      );
      this.builder.costCenters.metrics.recalculate(metricId, ccIndex);
    });
  }
}
