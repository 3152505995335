import { FormattedMessage } from "react-intl";
import { useCropFieldOptions } from "../../../lib/hooks";
import { Form, SelectField, SelectFieldProps } from "../../form";
import { FieldData } from "rc-field-form/es/interface";
import { useUpdateEffect } from "react-use";
import { merge } from "lodash";
import { CropFieldsFilterInput } from "../../../lib/graphql";
import Text from "antd/lib/typography/Text";

interface CropFieldSelectProps extends SelectFieldProps {
  includeDisabled?: boolean;
  filter?: CropFieldsFilterInput;
}

export function CropFieldFormSelect(props: CropFieldSelectProps) {
  return (
    <Form.Item
      label={<FormattedMessage id="cropFields.entityName" />}
      shouldUpdate={(prev, next) => prev.localityId !== next.localityId}
    >
      {({ getFieldValue, setFields }) => {
        return (
          <CropFieldFormItem
            setFields={setFields}
            localityIds={getFieldValue("localityId")}
            {...props}
          />
        );
      }}
    </Form.Item>
  );
}

function CropFieldFormItem({
  localityIds,
  filter,
  includeDisabled,
  setFields,
  ...props
}: CropFieldSelectProps & {
  setFields: (fields: FieldData[]) => void;
  localityIds?: string[];
}) {
  // clear crop fields when locality changes
  useUpdateEffect(() => {
    setFields([{ name: "cropFieldId", value: undefined }]);
  }, [localityIds?.length, setFields]);

  return (
    <Form.Item noStyle name="cropFieldId">
      <SelectField
        showGroups
        optionsHook={useCropFieldOptions}
        optionsHookParams={{
          variables: {
            filter: merge(
              {},
              {
                localityId:
                  localityIds && localityIds.length > 0
                    ? localityIds
                    : undefined,
                showAll: includeDisabled,
              },
              filter
            ),
          },
        }}
        placeholder={
          <FormattedMessage
            id="select.cropField"
            defaultMessage="select.cropField"
          />
        }
        formatOption={(label, option) =>
          option.cropField.discardedAt ? (
            <Text type="secondary">{label}</Text>
          ) : (
            label
          )
        }
        {...props}
      />
    </Form.Item>
  );
}
