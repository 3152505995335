import { useAccountOptions } from "../../lib/hooks";
import { SelectField, SelectFieldProps } from "../form";

interface AccountSelectProps extends Omit<SelectFieldProps, "mode"> {
  mode?: "ledger" | "group" | "all";
  kindKey?: string[];
  multiple?: boolean;
}

export function AccountSelect({
  mode = "all",
  kindKey,
  multiple,
  ...props
}: AccountSelectProps) {
  return (
    <SelectField
      mode={multiple ? "multiple" : undefined}
      optionsHook={useAccountOptions}
      optionsHookParams={{
        variables: { pageSize: 500, filter: { kindKey } },
      }}
      optionsHookFilter={(a) =>
        mode === "all" ? a : mode === "group" ? a.isGroup : !a.isGroup
      }
      showGroups={true}
      {...props}
    />
  );
}
