import React from "react";
import { locales, useCurrentLocale } from "../../../lib/hooks";
import { AvailableLocales } from "../../../lib/locale";

export default function LocaleEmoji({ locale }: { locale: AvailableLocales }) {
  return <>{locales[locale].emoji}</>;
}

const Current = () => {
  const { currentLocale } = useCurrentLocale();
  return <LocaleEmoji locale={currentLocale} />;
};

LocaleEmoji.Current = Current;
