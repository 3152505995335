import { SidebarHeader, ItemSidebar } from "../../shared";
import { ItemForm } from "../../shared/ListView/ItemForm";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  new: { id: "new.header", defaultMessage: "new" },
  group: { id: "cropFields.group", defaultMessage: "zone" },
});

interface CropFieldGroupSidebarProps {
  onSave: (name: string) => void;
}

export function CropFieldGroupSidebar({ onSave }: CropFieldGroupSidebarProps) {
  const intl = useIntl();

  return (
    <ItemSidebar
      item={null}
      sidebarActions={{
        newZone: ({ closeSidebar }) => (
          <>
            <SidebarHeader
              back
              title={intl.formatMessage(messages.new, {
                entityName: intl.formatMessage(messages.group),
              })}
              onClose={closeSidebar}
            />

            <ItemForm
              entityName={intl.formatMessage(messages.group)}
              initialValues={{ name: "" }}
              onCancel={closeSidebar}
              onSave={({ name }) => {
                return Promise.resolve(null).then(() => {
                  onSave(name);
                  closeSidebar();
                  return null;
                });
              }}
              fields={[
                {
                  name: "name",
                  type: "string",
                },
              ]}
            />
          </>
        ),
      }}
    />
  );
}
