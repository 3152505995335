import { WorkOrderBuilder, WorkOrderFormValues } from ".";
import { WithIDType } from "../../../../lib/hooks";
import { Builder } from "../../../shared/Wizard/builder";

export class WorkOrderBaseBuilder<T extends WithIDType> extends Builder<
  T,
  WorkOrderFormValues
> {
  protected builder: WorkOrderBuilder;
  protected workOrder: WorkOrderFormValues;

  constructor(builder: WorkOrderBuilder) {
    super(builder.form, builder.workOrder);

    this.builder = builder;
    this.workOrder = this.entity;
  }

  getCostCenters(filterDestroyed = true) {
    return this.builder.costCenters.get(filterDestroyed);
  }
}
