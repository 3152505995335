import {
  evictCache,
  useInventoryRequestUpdate,
  usePermissions,
  watchJobStatus,
} from "../../../lib/hooks";
import { Form, SubmitButton } from "../../form";
import routes, { routerPush } from "../../../lib/routes";
import { BottomPanel } from "../../shared";
import {
  InventoryRequestDetailsFragment,
  InventoryStatus,
  WorkOrderStatus,
} from "../../../lib/graphql";
import { FormattedMessage } from "react-intl";
import { Divider, Typography } from "antd";

import { CheckOutlined } from "@ant-design/icons";
import { InventoryRequestVariants } from "./InventoryRequestVariants";
import { InventoryReturnVariants } from "./InventoryReturnVariants";

export function InventoryRequestForm({
  request,
}: {
  request: InventoryRequestDetailsFragment;
}) {
  const [update, updateResult] = useInventoryRequestUpdate();
  const [form] = Form.useForm();
  const permissions = usePermissions((p) => p.inventoryRequest);

  const readonly =
    !permissions?.write ||
    (request.workOrder.inventoryStatus !== InventoryStatus.Requesting &&
      request.workOrder.inventoryStatus !== InventoryStatus.Returning) ||
    request.workOrder.status === WorkOrderStatus.Canceled ||
    request.workOrder.status === WorkOrderStatus.Reverted;

  const buttons = [
    BottomPanel.ReturnButton({
      route: routes.agro.inventoryRequests.index,
    }),
  ];

  if (!readonly) {
    buttons.push(
      BottomPanel.CancelButton({
        route: routes.agro.inventoryRequests.index,
      }),
      BottomPanel.SubmitButton({
        onClick: () => {
          form.setFields([{ name: "completeRequest", value: false }]);
        },
      }),
      <SubmitButton
        type="primary"
        icon={<CheckOutlined />}
        className="btn-complete"
        onClick={() => {
          form.setFields([{ name: "completeRequest", value: true }]);
        }}
      >
        <span>
          <FormattedMessage
            id="inventoryRequests.completeRequest"
            defaultMessage="completeRequest"
          />
        </span>
      </SubmitButton>
    );
  }

  return (
    <Form
      form={form}
      preventLeaving
      layout="vertical"
      style={{ marginTop: "16px" }}
      initialValues={request}
      onSubmit={({ variants }, { setSubmitting, showError, showErrors }) => {
        const promise = update({
          update: evictCache("inventoryRequests", "workOrders", "workOrder"),
          variables: {
            id: request.id,
            changes: {
              completeRequest: form.getFieldValue("completeRequest"),
              variantsAttributes: variants.map((v: any) => ({
                id: v.id,
                assignedAmount: v.assignedAmount,
                sourceWarehouseId: v.sourceWarehouseId,
                returnWarehouseId: v.returnWarehouseId,
                confirmedReturnedAmount: v.confirmedReturnedAmount,
              })),
            },
          },
        });
        if (!promise) return;

        promise.then((r) => {
          const result = r.data?.inventoryRequestUpdate;
          if (!result) return;

          if (result.jobId) {
            watchJobStatus(updateResult.client, result.jobId, (jobStatus) => {
              if (jobStatus?.status == "complete") {
                if (jobStatus.error) {
                  showError(jobStatus.error);
                  setSubmitting(false);
                } else {
                  routerPush(routes.agro.inventoryRequests.index);
                }

                return true;
              }
              return false;
            });
          } else if (result.result) {
            routerPush(routes.agro.inventoryRequests.index);
          } else if (result.errors) {
            showErrors(result.errors);
            setSubmitting(false);
          }
        });
      }}
    >
      {request.workOrder.status === WorkOrderStatus.Completed && (
        <>
          <Typography.Title level={4}>
            <FormattedMessage
              id="inventoryRequests.returns"
              defaultMessage="returns"
            />
          </Typography.Title>
          <InventoryReturnVariants
            request={request}
            readonly={
              readonly ||
              request.workOrder.inventoryStatus !== InventoryStatus.Returning
            }
          />
          <br />
        </>
      )}

      <Divider />

      <Typography.Title level={4}>
        <FormattedMessage
          id="inventoryRequests.details"
          defaultMessage="details"
        />
      </Typography.Title>

      <InventoryRequestVariants
        request={request}
        readonly={
          readonly ||
          request.workOrder.inventoryStatus !== InventoryStatus.Requesting
        }
      />

      <BottomPanel sticky buttons={buttons} />
    </Form>
  );
}
