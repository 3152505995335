import { FinanceOrder, FinanceOrderStatus } from "../../lib/graphql";
import { ActionButton } from "../shared";
import { useItemSidebarContext, usePermissions } from "../../lib/hooks";
import { formatPdfPath } from "../../lib/formats";
import { ItemType } from "antd/lib/menu/hooks/useItems";

interface FinanceOrderABProps {
  order: Pick<FinanceOrder, "id" | "status" | "internalId">;
  entityName: string;
}

export function FinanceOrderActionButton({
  order,
  entityName,
}: FinanceOrderABProps) {
  const { setCurrentAction } = useItemSidebarContext();
  const canRevert = usePermissions((p) => p.financeOrder?.delete);

  const actions: ItemType[] = [
    ActionButton.CopyLinkMenuItem(),
    ActionButton.ChangeLog(() => setCurrentAction("changeLog")),
    ActionButton.Clone({
      onClick: () => setCurrentAction("clone"),
      entityName,
    }),
  ];
  if (order.status === FinanceOrderStatus.Open) {
    actions.push(
      { type: "divider" },
      ActionButton.Cancel(() => setCurrentAction("cancel"))
    );
  } else if (order.status === FinanceOrderStatus.Completed) {
    actions.push(
      ActionButton.DownloadPDF({
        downloadLink: formatPdfPath("finance_order", order.id),
      })
    );
    if (canRevert) {
      actions.push(
        { type: "divider" },
        ActionButton.Revert(() => setCurrentAction("revert"))
      );
    }
  }

  return <ActionButton menuItems={actions} />;
}
