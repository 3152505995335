import { FormattedMessage } from "react-intl";
import { useCropFieldGroupOptions } from "../../../lib/hooks";
import { Form, SelectField, SelectFieldProps } from "../../form";
import { FieldData } from "rc-field-form/es/interface";
import { useUpdateEffect } from "react-use";

export function CropFieldGroupFormSelect(props: SelectFieldProps) {
  return (
    <Form.Item
      label={<FormattedMessage id="cropFields.group" />}
      shouldUpdate={(prev, next) => prev.localityId !== next.localityId}
    >
      {({ getFieldValue, setFields }) => {
        return (
          <CropFieldGroupFormItem
            setFields={setFields}
            localityIds={getFieldValue("localityId")}
            {...props}
          />
        );
      }}
    </Form.Item>
  );
}

function CropFieldGroupFormItem({
  localityIds,
  setFields,
  ...props
}: SelectFieldProps & {
  setFields: (fields: FieldData[]) => void;
  localityIds?: string[];
}) {
  // clear crop field groups when locality changes
  useUpdateEffect(() => {
    setFields([{ name: "cropFieldGroup", value: undefined }]);
  }, [localityIds?.length, setFields]);

  return (
    <Form.Item noStyle name="cropFieldGroup">
      <SelectField
        mode="multiple"
        optionsHook={useCropFieldGroupOptions}
        optionsHookParams={{
          variables: {
            localityId:
              localityIds && localityIds.length > 0 ? localityIds : undefined,
          },
        }}
        placeholder={
          <FormattedMessage
            id="cropFields.group.all"
            defaultMessage="All zones"
          />
        }
        {...props}
      />
    </Form.Item>
  );
}
