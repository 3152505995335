import { CalculatorOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import { sumBy } from "lodash";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  formatUnitValue,
  getUnitsConversionFactor,
  roundUnit,
} from "../../lib/formats";
import { UnitShortFragment } from "../../lib/graphql";
import { InputNumber } from "../form";
import { Popover } from "../shared";
import { UnitSelect } from "./UnitSelect";

const messages = defineMessages({
  dosageTitle: { id: "dosageCalculator", defaultMessage: "Dosage Calculator" },
});

export function DosageCalculator({
  defaultUnit,
  variantUnit,
  progressUnit,
  cropFields,
  onVariantUnitChanged,
  onOk,
}: {
  defaultUnit?: UnitShortFragment;
  variantUnit: UnitShortFragment;
  progressUnit: Pick<UnitShortFragment, "abbr">;
  cropFields: Array<{ goal: number; plantDensity: number }>;
  onVariantUnitChanged?(unit: UnitShortFragment): void;
  onOk(value: number): void;
}) {
  const intl = useIntl();
  const dosageTitle = intl.formatMessage(messages.dosageTitle);
  const [open, setOpen] = useState(false);
  const [unit, setUnit] = useState<UnitShortFragment>(
    defaultUnit || variantUnit
  );
  const [value, setValue] = useState(0);
  const plantAbbr = intl.formatMessage({ id: "plants.abbr" });

  const conversionFactor = getUnitsConversionFactor(unit, variantUnit);
  const isPlantProgress = progressUnit.abbr == plantAbbr;

  const dosage = isPlantProgress
    ? value * conversionFactor
    : (value * conversionFactor * sumBy(cropFields, (cf) => cf.plantDensity)) /
      cropFields.length;

  useEffect(() => {
    if (defaultUnit) setUnit(defaultUnit);
  }, [defaultUnit]);

  return (
    <Popover
      title={dosageTitle}
      trigger="click"
      open={open}
      onOpenChange={(visible) => setOpen(visible)}
      content={
        <Space direction="vertical">
          <Space>
            <InputNumber
              id="dosageCalculator"
              min={0}
              addonAfter={
                <UnitSelect
                  value={unit.id}
                  unitTypes={[variantUnit.unitType]}
                  labelRenderer={(label) => `${label} / ${plantAbbr}`}
                  onUnitChange={(unit) => {
                    if (!unit) return;

                    setUnit(unit);
                    if (onVariantUnitChanged) onVariantUnitChanged(unit);
                  }}
                />
              }
              onChange={(value) => {
                if (!value) return;

                setValue(value);
              }}
            />
            <Button
              type="primary"
              icon={<CheckOutlined />}
              disabled={!value}
              onClick={() => {
                onOk(roundUnit(dosage, 10000));
                setOpen(false);
              }}
            />
          </Space>
          <Typography.Text type="success" style={{ fontSize: "12px" }}>
            <FormattedMessage id="dosage" />:{" "}
            {formatUnitValue(dosage, variantUnit, progressUnit.abbr, {
              maximumFractionDigits: 4,
            })}
          </Typography.Text>
        </Space>
      }
    >
      <Button
        title={dosageTitle}
        type="link"
        size="small"
        style={{ padding: 0 }}
        onClick={() => setOpen(true)}
      >
        <CalculatorOutlined />
      </Button>
    </Popover>
  );
}
