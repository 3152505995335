import Icon from "@ant-design/icons";
import { Col, Collapse, Row, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { useMediaXs } from "../../../lib/hooks";
import { CommentList, CommentListProps } from "../CommentList";

export function CommentSection(props: CommentListProps) {
  const isXs = useMediaXs();
  return (
    <Collapse
      bordered={false}
      expandIconPosition="end"
      style={{ background: "none" }}
      defaultActiveKey={["comments"]}
    >
      <Collapse.Panel
        className="wizard-section"
        key="comments"
        header={
          <Row gutter={16} align="middle">
            <Col>
              <Icon component={() => <img src="/images/icons/comment.svg" />} />
            </Col>
            <Col flex={1}>
              <Typography.Title level={3} style={{ marginBottom: 0 }}>
                <FormattedMessage id="comments" defaultMessage="comments" />
              </Typography.Title>
              {!isXs && (
                <Typography.Text type="secondary">
                  <FormattedMessage
                    id="workOrders.comments.hint"
                    defaultMessage="comments"
                  />
                </Typography.Text>
              )}
            </Col>
          </Row>
        }
      >
        <CommentList {...props} />
      </Collapse.Panel>
    </Collapse>
  );
}
