import { FormattedMessage } from "react-intl";
import { useCurrentUser, useMachineOptions } from "../../lib/hooks";
import { SelectField, SelectFieldProps } from "../form";

export function MachineSelect(props: SelectFieldProps) {
  const { currentLocalityIds: localityId } = useCurrentUser();

  return (
    <SelectField
      optionsHook={useMachineOptions}
      optionsHookParams={{ variables: { filter: { localityId } } }}
      placeholder={
        <FormattedMessage id="select.machine" defaultMessage="select.machine" />
      }
      showGroups
      {...props}
    />
  );
}
