import { LoadingOutlined, WarningFilled } from "@ant-design/icons";
import { TaxValueFragment } from "../../../lib/graphql";
import { useDebouncedTaxPlan } from "../../../lib/hooks";
import { BLANK_SYMBOL, formatMoneyAmount } from "../../../lib/formats";
import { Tooltip } from "antd";
import { useUpdateEffect } from "react-use";
import { useMemo } from "react";

interface TaxPlanComponentValueProps {
  subtotal: number;
  quantity: number;
  taxPlanId: string;
  currencyCode: string;
  taxComponentId: string;
  onChange?: (value: TaxValueFragment[]) => void;
}

export function TaxPlanComponentValue({
  subtotal,
  quantity,
  taxPlanId,
  currencyCode,
  taxComponentId,
  onChange,
}: TaxPlanComponentValueProps) {
  const inputVariables = useMemo(
    () => ({ subtotal, quantity }),
    [subtotal, quantity]
  );
  const { taxPlan, loading } = useDebouncedTaxPlan(taxPlanId, inputVariables);

  useUpdateEffect(() => {
    taxPlan && onChange && onChange(taxPlan?.sample);
  }, [taxPlan, onChange]);
  if (loading || !taxPlan) return <LoadingOutlined spin />;

  const result = taxPlan.sample.find((s) => s.component.id === taxComponentId);

  if (!result) return <>{BLANK_SYMBOL}</>;
  if (result.value != null)
    return formatMoneyAmount(result.value, currencyCode);

  return (
    <Tooltip title={result?.error}>
      <WarningFilled />
    </Tooltip>
  );
}
