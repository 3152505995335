import { Col, Row, Typography } from "antd";
import { groupBy, sumBy } from "lodash";
import dayjs from "dayjs";
import { Sunburst } from "@ant-design/plots";
import { FormattedMessage, useIntl } from "react-intl";
import {
  formatDateFromNow,
  formatUnitValue,
  roundUnit,
} from "../../../lib/formats";
import { CropFieldDetailsFragment } from "../../../lib/graphql";

export function CropFieldStats({
  stats,
  areaAbbr,
  showChart,
}: {
  stats: Pick<
    CropFieldDetailsFragment,
    "plantDensity" | "effectiveArea" | "effectivePlants" | "cropFieldVarieties"
  >;
  areaAbbr: string;
  showChart?: boolean;
}) {
  const intl = useIntl();
  const plantsAbbr = intl.formatMessage({
    id: "plants.abbr",
    defaultMessage: "pl",
  });
  const varieties = stats.cropFieldVarieties.filter((v) => v.plantedAt);
  const avgPlantedAt =
    sumBy(varieties, (v) => dayjs(v.plantedAt).valueOf()) / varieties.length;

  return (
    <Row justify="center" gutter={[8, 16]}>
      <Col xs={12}>
        <Typography.Text type="secondary">
          <FormattedMessage id="cropFields.effectivePlants" />
        </Typography.Text>
        <Typography.Title level={3} style={{ margin: 0 }}>
          {formatUnitValue(stats.effectivePlants, { abbr: plantsAbbr })}
        </Typography.Title>
      </Col>
      <Col xs={12}>
        <Typography.Text type="secondary">
          <FormattedMessage
            id="cropFields.avgPlantDensity"
            defaultMessage="Avg Plant Density"
          />
        </Typography.Text>
        <Typography.Title level={3} style={{ margin: 0 }}>
          {formatUnitValue(stats.plantDensity, { abbr: plantsAbbr }, areaAbbr)}
        </Typography.Title>
      </Col>
      <Col xs={12}>
        <Typography.Text type="secondary">
          <FormattedMessage id="cropFields.effectiveArea" />
        </Typography.Text>
        <Typography.Title level={3} style={{ margin: 0 }}>
          {formatUnitValue(stats.effectiveArea, { abbr: areaAbbr })}
        </Typography.Title>
      </Col>
      <Col xs={12}>
        <Typography.Text type="secondary">
          <FormattedMessage
            id="cropFields.avgAge"
            defaultMessage="Average Age"
          />
        </Typography.Text>
        <Typography.Title level={3} style={{ margin: 0 }}>
          {formatDateFromNow(avgPlantedAt, true)}
        </Typography.Title>
      </Col>
      {showChart && (
        <Col xs={24}>
          <VarietiesChart
            areaAbbr={areaAbbr}
            cropFieldVarieties={stats.cropFieldVarieties}
          />
        </Col>
      )}
    </Row>
  );
}

function VarietiesChart({
  cropFieldVarieties,
  areaAbbr,
}: Pick<CropFieldDetailsFragment, "cropFieldVarieties"> & {
  areaAbbr: string;
}) {
  const intl = useIntl();
  const groupedData = groupBy(cropFieldVarieties, (v) => v.cropVariety.name);
  const total = sumBy(cropFieldVarieties, "effectiveArea");
  const data = Object.entries(groupedData).map(([name, cfv]) => {
    const totalArea = roundUnit(sumBy(cfv, "effectiveArea"));

    return {
      name,
      value: totalArea,
      percentage: totalArea / total,
      children: cfv.map((c) => ({
        varietyName: name,
        name: c.plantedAt ? dayjs(c.plantedAt).fromNow(true) : "",
        value: c.effectiveArea,
        percentage: c.effectiveArea / totalArea,
      })),
    };
  });

  return (
    <Sunburst
      data={{
        name: intl.formatMessage({ id: "cropVarieties" }),
        children: data,
      }}
      radius={1}
      innerRadius={0.2}
      rawFields={["name", "value", "varietyName", "percentage"]}
      tooltip={{
        title: (_, d) => {
          return [d.varietyName, d.name].filter((d) => d).join(": ");
        },
        showTitle: true,
        fields: ["name", "value", "varietyName", "percentage"],
        formatter: (d) => {
          return {
            name: intl.formatMessage({
              id: "cropFields.effectiveArea",
              defaultMessage: "effectiveArea",
            }),
            value: `${d.value} ${areaAbbr} (${roundUnit(d.percentage * 100)}%)`,
          };
        },
      }}
      colorField="name"
      label={{
        layout: { type: "limit-in-shape" },
      }}
      height={200}
    />
  );
}
