import { CheckOutlined } from "@ant-design/icons";
import { Button, Row, Col, Divider, Typography } from "antd";
import {
  evictCache,
  useCurrentLocale,
  useTransferUpdate,
} from "../../lib/hooks";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { TransferStatus, Transfer, CommentableType } from "../../lib/graphql";
import { ReactNode } from "react";
import { BottomPanel, CommentList } from "../shared";
import { formatPdfPath } from "../../lib/formats";
import { useForm } from "antd/lib/form/Form";

const messages = defineMessages({
  completing: {
    id: "completingDocument",
    defaultMessage: "completingDocument",
  },
});

export function TransferFooter({
  transfer,
  indexRoute,
  editRoute,
  completeTitle,
}: {
  transfer: Pick<Transfer, "id" | "status" | "notes">;
  indexRoute: string;
  editRoute: string;
  completeTitle: ReactNode;
}) {
  const { showSuccess } = useCurrentLocale();
  const intl = useIntl();
  const [update, { loading }] = useTransferUpdate();

  const [commentForm] = useForm();

  return (
    <>
      {transfer.notes && (
        <>
          <Divider dashed />
          <Typography.Title level={4}>
            <FormattedMessage id="notes" />
          </Typography.Title>
          <Typography.Paragraph>{transfer.notes}</Typography.Paragraph>
        </>
      )}

      <Divider dashed />

      <CommentList
        commentableType={CommentableType.InventoryTransfer}
        commentableId={transfer.id}
        form={commentForm}
      />

      <Divider dashed />

      <Row justify="end" gutter={8}>
        <Col>
          <BottomPanel.ReturnButton route={indexRoute} />
        </Col>

        {transfer.status === TransferStatus.Completed && (
          <Col>
            <BottomPanel.DownloadPDFButton
              route={formatPdfPath("inventory_transfer", transfer.id)}
            />
          </Col>
        )}

        {transfer.status === TransferStatus.Open && (
          <>
            <Col>
              <BottomPanel.EditButton route={editRoute} />
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                className="btn-complete"
                disabled={loading}
                loading={loading}
                onClick={() => {
                  update({
                    variables: {
                      id: transfer.id,
                      changes: { status: TransferStatus.Completed },
                    },
                    update: evictCache("stockVariants"),
                  }).then((result) => {
                    const updateResult = result?.data?.stockTransferUpdate;

                    if (updateResult?.result) {
                      commentForm.submit();

                      showSuccess(
                        intl.formatMessage(messages.completing, {
                          name: transfer.id,
                        })
                      );
                    }
                  });
                }}
              >
                <span>{completeTitle}</span>
              </Button>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}
