import { FormattedMessage } from "react-intl";

import { formatDetailsLink, formatUnitValue } from "../../../lib/formats";
import routes from "../../../lib/routes";
import { useContext } from "react";
import { ProfitableType } from "../../../lib/graphql";
import { useMachineOptions } from "../../../lib/hooks";
import { WorkOrderContext } from "./WorkOrderContext";
import { WorkOrderCostCenters } from "./costCenters";

export function WorkOrderMachineMaintenance({
  byPercentage,
}: {
  byPercentage?: boolean;
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);

  return (
    <WorkOrderCostCenters
      byPercentage={byPercentage}
      tableSelectProps={{
        mode: "multiple",
        optionsHook: useMachineOptions,
        optionsHookParams: {
          variables: {
            fetchCostCenter: true,
            localityId: workOrder.locality.id,
            filter: { localityId: [workOrder.locality.id] },
          },
        },
        showGroups: true,
        placeholder: (
          <FormattedMessage id="select.machine" defaultMessage="machine" />
        ),
        entityById: (_, { machine }) => {
          if (machine) {
            return {
              id: "",
              costCenter: machine.costCenter || {
                id: "",
                name: "",
              },
              profitableId: machine.id,
              profitableType: ProfitableType.Machine,
              profitableName: machine.name,
              machine,
              totalProgress: 0,
              plannedProgress: 0,
              dayGoal: 0,
              employees: [],
              machines: [],
              variants: [],
              metrics: builder.costCenters.metrics.build(),
            };
          }
        },
      }}
      columns={[
        {
          title: <FormattedMessage id="name" />,
          dataIndex: "name",
          render: (_, c) =>
            c.machine &&
            formatDetailsLink({
              id: c.machine.id || "",
              route: routes.machines.details,
              title: c.machine.name,
            }),
        },
        {
          title: <FormattedMessage id="machineKinds.entityName" />,
          render: (_, c) => c.machine?.kind.name,
        },
        {
          title: (
            <FormattedMessage
              id="costCenters.entityName"
              defaultMessage="costCenter"
            />
          ),
          dataIndex: "costCenter",
          render: (_, c) => c.costCenter.name,
        },
        {
          title: <FormattedMessage id="machines.odometer" />,
          render: (_, c) =>
            formatUnitValue(c.machine?.odometerValue, c.machine?.odometerUnit),
        },
        {
          title: <FormattedMessage id="machines.horometer" />,
          render: (_, c) =>
            formatUnitValue(
              c.machine?.horometerValue,
              c.machine?.horometerUnit
            ),
        },
      ]}
    />
  );
}
