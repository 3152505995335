import { SelectField, Form, NamePath, Rules, RuleBuilder } from "../form";
import { Input } from "antd";
import * as React from "react";
import { InputNumber } from "../form/InputNumber";
import { useUnitOptions } from "../../lib/hooks";
import { UnitType } from "../../lib/graphql";
import { defineMessages, useIntl } from "react-intl";
import { EditDisabledInput } from "../form/EditDisabledInput";

export type OdometerValueInputProps = {
  unitName: NamePath;
  amountName: NamePath;
  initialValue?: number | null;
};

const messages = defineMessages({
  selectUnit: { id: "units.selectPlaceholder", defaultMessage: "select" },
});

export function OdometerValueInput({
  unitName,
  amountName,
  initialValue,
}: OdometerValueInputProps) {
  const intl = useIntl();
  const { unitAllowedOptions, loadingUnitOptions } = useUnitOptions({
    types: [UnitType.Distance],
  });

  const odometerValueRules = [Rules.onlyIntegers];

  if (initialValue) {
    odometerValueRules.push(RuleBuilder.gtEq(initialValue));
  }

  const input = (disabled: boolean) => (
    <Input.Group compact>
      <Form.Item
        name={amountName}
        rules={odometerValueRules}
        style={{ marginBottom: 0, width: "60%" }}
      >
        <InputNumber disabled={disabled} min={0} step={1} />
      </Form.Item>

      <Form.Item name={unitName} style={{ marginBottom: 0, width: "40%" }}>
        <SelectField
          disabled={disabled}
          placeholder={intl.formatMessage(messages.selectUnit)}
          allowClear={false}
          showGroups
          options={unitAllowedOptions}
          loading={loadingUnitOptions}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Input.Group>
  );

  return initialValue ? <EditDisabledInput render={input} /> : input(false);
}
