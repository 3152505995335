import { shouldUpdate } from "../../lib/formats";
import { UnitShortFragment, UnitType } from "../../lib/graphql";
import { useUnitOptions } from "../../lib/hooks";
import { Form, DeepNamePath, SelectField, SelectFieldProps } from "../form";

interface UnitSelectProps extends SelectFieldProps {
  unitTypes?: Array<UnitType>;
  abbrLabel?: boolean;
  onUnitChange?: (unit?: UnitShortFragment) => void;
}

export function UnitSelect({
  unitTypes,
  abbrLabel = true,
  onUnitChange,
  ...props
}: UnitSelectProps) {
  const { unitOptions, loadingUnitOptions } = useUnitOptions({
    types: unitTypes,
    abbrLabel,
  });

  return (
    <SelectField
      allowClear={false}
      showSearch={false}
      options={unitOptions}
      loading={loadingUnitOptions}
      onSelect={(val: any) => {
        if (onUnitChange) {
          const opt = unitOptions?.find((u) => u.key == val);
          onUnitChange(opt?.unit);
        }
      }}
      {...props}
    />
  );
}

function UnitSelectFormItem({
  name,
  readonly,
  onUnitChange,
  ...props
}: {
  name: DeepNamePath;
  readonly?: boolean;
  onUnitChange?: (value: UnitShortFragment) => void;
} & UnitSelectProps) {
  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate(name)}>
      {({ getFieldValue, setFields }) =>
        readonly ? (
          props.labelRenderer &&
          props.labelRenderer(getFieldValue(name.concat("abbr")), {} as any)
        ) : (
          <Form.Item compact name={name.concat("id")}>
            <UnitSelect
              id={name.concat("id").join("_")}
              onUnitChange={(value) => {
                setFields([{ name, value }]);
                if (onUnitChange) onUnitChange(value);
              }}
              style={{ minWidth: "90px" }}
              {...props}
            />
          </Form.Item>
        )
      }
    </Form.Item>
  );
}

UnitSelect.FormItem = UnitSelectFormItem;
