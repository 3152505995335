import React from "react";
import {
  SelectField,
  Form,
  NamePath,
  Rules,
  SelectFieldProps,
} from "../../form";
import { useUnitOptions } from "../../../lib/hooks";
import { UnitType } from "../../../lib/graphql";
import { defineMessages, useIntl } from "react-intl";
import { Input, InputNumber, InputNumberProps } from "antd";

interface UnitAmountInputProps {
  unitName: NamePath;
  amountName: NamePath;
  unitType?: UnitType | null;
  width?: [string, string];
  isInteger?: boolean | null;
  disabled?: boolean;
  unitSelectProps?: SelectFieldProps;
  amountNumberProps?: InputNumberProps;
  required?: boolean;
}

const messages = defineMessages({
  selectUnit: { id: "units.selectPlaceholder", defaultMessage: "select" },
  selectAmount: { id: "units.amountPlaceholder", defaultMessage: "amount" },
});

export function UnitAmountInput({
  unitType,
  unitName,
  amountName,
  width,
  isInteger,
  disabled,
  unitSelectProps,
  amountNumberProps,
  required = true,
}: UnitAmountInputProps) {
  const intl = useIntl();
  const { unitOptions, loadingUnitOptions } = useUnitOptions({
    types: unitType ? [unitType] : undefined,
  });

  return (
    <Input.Group compact>
      <Form.Item
        name={amountName}
        noStyle
        rules={isInteger ? [Rules.onlyIntegers] : [Rules.onlyNumbers]}
        required={required}
      >
        <InputNumber
          min={0}
          step={isInteger ? 1 : 0.1}
          style={{ width: width?.[0] || "30%" }}
          placeholder={intl.formatMessage(messages.selectAmount)}
          disabled={disabled}
          {...amountNumberProps}
        />
      </Form.Item>
      <Form.Item
        name={unitName}
        noStyle
        rules={required ? [Rules.required] : undefined}
        required={required}
      >
        <SelectField
          style={{ width: width?.[1] || "70%" }}
          placeholder={intl.formatMessage(messages.selectUnit)}
          allowClear={false}
          showGroups={true}
          options={unitOptions}
          loading={loadingUnitOptions}
          disabled={disabled}
          {...unitSelectProps}
        />
      </Form.Item>
    </Input.Group>
  );
}
