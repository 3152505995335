import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { formatPdfPath } from "../../lib/formats";
import {
  FinanceInvoiceFragment,
  FinanceInvoiceStatus,
} from "../../lib/graphql";
import { useFinanceInvoiceUpdate } from "../../lib/hooks";
import { routerPush } from "../../lib/routes";
import { ItemSidebar, RevertSidebar, SidebarHeader } from "../shared";
import { ItemForm } from "../shared/ListView/ItemForm";

export function InvoiceSidebar({
  invoice,
}: {
  invoice: FinanceInvoiceFragment;
}) {
  const intl = useIntl();
  const [update] = useFinanceInvoiceUpdate();
  const entityName = intl.formatMessage({ id: "invoices.entityName" });

  return (
    <ItemSidebar
      item={invoice}
      sidebarActions={{
        revert: ({ closeSidebar }) => (
          <RevertSidebar
            closeSidebar={closeSidebar}
            entityName={entityName}
            entity={invoice}
            revert={() =>
              update({
                variables: {
                  id: invoice.id,
                  changes: { status: FinanceInvoiceStatus.Reverted },
                },
              }).then((result) => result.data?.financeInvoiceUpdate)
            }
          />
        ),
        pdf: ({ closeSidebar }) => (
          <>
            <SidebarHeader
              title={<FormattedMessage id="downloadPdf" />}
              onClose={closeSidebar}
            />
            <ItemForm
              item={invoice}
              saveText={<FormattedMessage id="downloadPdf" />}
              onCancel={closeSidebar}
              initialValues={{
                payments: false,
              }}
              fields={[
                {
                  label: (
                    <FormattedMessage
                      id="invoices.includePayments"
                      defaultMessage="Include Payments"
                    />
                  ),
                  name: "payments",
                  type: "switch",
                },
              ]}
              onSave={(params) => {
                const pdfUrl = formatPdfPath(
                  "finance_invoice",
                  invoice.id,
                  params
                );

                return Promise.resolve().then(() => {
                  routerPush(pdfUrl);
                  closeSidebar();
                  return null;
                });
              }}
            />
          </>
        ),
      }}
    />
  );
}
