import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { WorkOrderStatus } from "../../../lib/graphql";
import {
  evictCache,
  useCycleNumberOptions,
  useWorkOrderClone,
  useWorkOrderStatusOptions,
  watchJobStatus,
} from "../../../lib/hooks";
import routes, { routerPush } from "../../../lib/routes";
import DocumentDateFormInput from "../../finance/DocumentDateFormInput";
import { Rules } from "../../form";
import { SidebarHeader } from "../../shared";
import { ItemForm } from "../../shared/ListView/ItemForm";
import { WorkOrderContext } from "./WorkOrderContext";
import { resetTime } from "../../../lib/formats";

export function WorkOrderCloneForm({ onClose }: { onClose: () => void }) {
  const { workOrder } = useContext(WorkOrderContext);
  const [clone, cloneResult] = useWorkOrderClone();

  const statuses = [WorkOrderStatus.Open];
  if (
    [
      WorkOrderStatus.Reverted,
      WorkOrderStatus.Completed,
      WorkOrderStatus.InProgress,
    ].includes(workOrder.status)
  )
    statuses.push(WorkOrderStatus.InProgress);

  return (
    <>
      <SidebarHeader
        title={
          <FormattedMessage
            id="cloneOrder"
            defaultMessage="Copy & Clone Order"
          />
        }
        onClose={onClose}
      />

      <ItemForm
        item={workOrder}
        saveText={
          <FormattedMessage
            id="cloneOrder"
            defaultMessage="Copy & Clone Order"
          />
        }
        onCancel={onClose}
        initialValues={{
          documentDate: resetTime(workOrder.documentDate),
          cycleNumber: workOrder.cycleNumber,
          cropFieldGroup: workOrder.cropFieldGroup,
          copyStatus: statuses.slice(-1)[0],
        }}
        fields={[
          {
            type: "custom",
            render: () => (
              <DocumentDateFormInput
                key="documentDate"
                dateProps={{ showWeeks: workOrder.cropCycle }}
              />
            ),
          },
          {
            label: <FormattedMessage id="workOrders.cycleNumber" />,
            name: "cycleNumber",
            type: "select",
            rules: [Rules.required],
            hidden: !workOrder.activity.trackCycles,
            optionsHook: useCycleNumberOptions,
          },
          {
            label: (
              <FormattedMessage
                id="cloneOrder.copyProgress"
                defaultMessage="Copy & Clone to"
              />
            ),
            name: "copyStatus",
            type: "select",
            optionsHook: useWorkOrderStatusOptions,
            optionsHookFilter: (opt) => statuses.includes(opt.value),
            inputProps: { showSearch: false },
          },
        ]}
        onSave={({ copyStatus, ...changes }) => {
          return clone({
            update: evictCache("workOrders"),
            variables: {
              id: workOrder.id,
              changes,
              copyProgress: copyStatus == WorkOrderStatus.InProgress,
            },
          })
            .then((result) => result.data?.workOrderClone?.jobId)
            .then((jobId) => {
              if (jobId) {
                watchJobStatus(cloneResult.client, jobId, (jobStatus) => {
                  if (jobStatus && jobStatus.work_order_id) {
                    routerPush(
                      jobStatus.work_order_id,
                      routes.agro.workOrders.details
                    );
                    return true;
                  }

                  return false;
                });
              }

              return null;
            });
        }}
      />
    </>
  );
}
