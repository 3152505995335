import { debounce, sumBy } from "lodash";
import {
  distributeProportionally,
  roundUnit,
} from "../../../../../lib/formats";
import { WorkOrderCostCenterMetricFragment } from "../../../../../lib/graphql";
import { WorkOrderCostCenter } from "../costCenters";
import { WorkOrderMetricBuilder } from "./base";

export class CostCenterMetricBuilder extends WorkOrderMetricBuilder {
  public debouncedDistributeIndividualMetrics = debounce(
    this.distributeIndividualMetrics
  );

  protected finder(metricId: string) {
    return (m: WorkOrderCostCenterMetricFragment) =>
      m.metricId == metricId && !m.employeeId && !m.machineId && !m.variantId;
  }

  build(costCenter?: WorkOrderCostCenter) {
    return this.activityMetrics
      .filter(
        (am) =>
          !costCenter || !costCenter.metrics.find(this.finder(am.metric.id))
      )
      .map((m) => ({
        id: "",
        metricId: m.metric.id,
        value: 0,
      }));
  }

  distributeCostCenters(value: number) {
    const costCenters = this.builder.costCenters.get(false);
    return distributeProportionally(
      value,
      costCenters.map((cc) =>
        cc._destroy
          ? 0
          : cc.cropField
          ? this.builder.costCenters.getArea(cc.cropField)
          : cc.progressPercent
      )
    );
  }

  distributeMetric(metricId: string, value?: number | null) {
    if (value == null) return;

    const distribution = this.distributeCostCenters(value);

    distribution.forEach((value, ccIndex) => {
      this.builder.form.setFieldValue(
        this.metricValueFieldName(ccIndex, metricId),
        value
      );

      this.distributeIndividualMetrics(metricId, ccIndex);
    });
  }

  distributeIndividualMetrics(metricId: string, ccIndex: number) {
    const costCenter = this.builder.costCenters.getBy(ccIndex);
    const value = this.getTotalMetric(metricId, costCenter);
    const metricBuilder = this.builder.isProcessing
      ? this.builder.outputs.metrics
      : this.builder.isMachinery
      ? this.builder.machines.metrics
      : this.builder.employees.metrics;

    metricBuilder.distributeMetric(metricId, value, ccIndex);
  }

  recalculate(metricId: string, ccIndex: number) {
    const costCenter = this.builder.costCenters.getBy(ccIndex);
    const value = roundUnit(
      sumBy(
        costCenter.metrics.filter(
          (m) =>
            m.metricId == metricId &&
            (m.employeeId || m.machineId || m.variantId)
        ),
        "value"
      )
    );

    this.builder.form.setFields([
      {
        name: this.metricValueFieldName(ccIndex, metricId),
        value,
      },
    ]);
  }
}
