import { Col, Divider, Row, Typography } from "antd";
import { Form, InputNumber, RuleBuilder, Rules, SelectField } from "../../form";
import { FormattedMessage } from "react-intl";
import { UnitSelect } from "../../units";
import { UnitType } from "../../../lib/graphql";
import { useCallback, useContext, useEffect } from "react";
import { WorkOrderContext } from "./WorkOrderContext";
import {
  formatNumber,
  formatTags,
  formatUnitValue,
  shouldUpdate,
  tagRender,
} from "../../../lib/formats";
import {
  useEquipmentOptions,
  useUnitOptions,
  useWaterSourceOptions,
} from "../../../lib/hooks";
import { Space } from "../../shared";
import { WorkOrderWaterInputs } from "./WorkOrderInputs";
import Icon from "@ant-design/icons";

export function useWaterUsage() {
  const { builder } = useContext(WorkOrderContext);
  const { unitOptions } = useUnitOptions({ types: [UnitType.Volume] });

  useEffect(() => {
    const unit = unitOptions?.find((u) => u.primary)?.unit;
    if (!unit) return;

    builder.waterUsage.init(unit);
  }, [unitOptions, builder]);

  const recalculate = useCallback(
    () => builder.waterUsage.recalculate(),
    [builder]
  );

  const recalculateInputs = useCallback(
    () => builder.waterUsage.recalculateInputs(),
    [builder]
  );

  return { recalculate, recalculateInputs };
}

export function WorkOrderSpraying() {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const formItemProps = { labelCol: { span: 8 }, wrapperCol: { span: 14 } };
  const { recalculate } = useWaterUsage();

  return (
    <Form.Item
      noStyle
      shouldUpdate={shouldUpdate(
        "waterUsage",
        "costCenters",
        ["waterUsage", "waterUnit"],
        ["waterUsage", "outputUnit"],
        ["waterUsage", "applicationUnit"]
      )}
    >
      {() =>
        builder.waterUsage.value && (
          <Row style={{ marginTop: 8 }}>
            {builder.isDataIntake ? (
              <Col xs={24} xl={8}>
                <Form.Item
                  name={["waterUsage", "tanksUsed"]}
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/tank.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.tanksUsed"
                        defaultMessage="Tanks Used"
                      />
                    </Space>
                  }
                  rules={[
                    Rules.gtZero,
                    RuleBuilder.ltEq(workOrder.waterUsage?.tanksRequired),
                  ]}
                  required={false}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 6 }}
                  extra={
                    <Typography.Text
                      style={{ fontSize: "75%" }}
                      type="secondary"
                    >
                      <FormattedMessage
                        id="required"
                        defaultMessage="Required"
                      />
                      : {formatNumber(builder.waterUsage.value.tanksRequired)}
                    </Typography.Text>
                  }
                >
                  {builder.isReadonly ? (
                    formatNumber(workOrder.waterUsage?.tanksUsed || 0)
                  ) : (
                    <InputNumber
                      step={0.1}
                      onChange={() => builder.waterUsage.calculateConsumption()}
                    />
                  )}
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} xl={10}>
                <Form.Item
                  name={["waterUsage", "tankCapacity"]}
                  label={
                    <FormattedMessage
                      id="workOrders.spraying.tankCapacity"
                      defaultMessage="Tank Capacity"
                    />
                  }
                  required={!builder.isDataIntake}
                  rules={[Rules.gtZero]}
                  {...formItemProps}
                >
                  {builder.isReadonly || builder.isDataIntake ? (
                    formatUnitValue(
                      workOrder.waterUsage?.tankCapacity,
                      workOrder.waterUsage?.waterUnit
                    )
                  ) : (
                    <InputNumber
                      min={0}
                      addonAfter={
                        <UnitSelect.FormItem
                          name={["waterUsage", "waterUnit"]}
                          unitTypes={[UnitType.Volume]}
                        />
                      }
                      onChange={recalculate}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  name={["waterUsage", "outputRate"]}
                  label={
                    <FormattedMessage
                      id="workOrders.spraying.outputRate"
                      defaultMessage="Coverage"
                    />
                  }
                  required={!builder.isDataIntake}
                  rules={[Rules.gtZero]}
                  {...formItemProps}
                >
                  {builder.isReadonly || builder.isDataIntake ? (
                    formatUnitValue(
                      workOrder.waterUsage?.outputRate,
                      workOrder.waterUsage?.outputUnit,
                      workOrder.waterUsage?.applicationUnit.abbr
                    )
                  ) : (
                    <InputNumber
                      addonAfter={
                        <UnitSelect.FormItem
                          name={["waterUsage", "outputUnit"]}
                          unitTypes={[UnitType.Volume]}
                          onUnitChange={recalculate}
                        />
                      }
                      per={
                        <UnitSelect.FormItem
                          name={["waterUsage", "applicationUnit"]}
                          labelRenderer={(value) => (
                            <FormattedMessage id="per" values={{ value }} />
                          )}
                          unitTypes={[UnitType.Area]}
                          onUnitChange={recalculate}
                        />
                      }
                      onChange={recalculate}
                    />
                  )}
                </Form.Item>
              </Col>
            )}

            {builder.isDataIntake ? (
              <Col xs={24} lg={12} xl={8}>
                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/water.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.waterUsed"
                        defaultMessage="Water Used"
                      />
                    </Space>
                  }
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 6 }}
                  extra={
                    <Typography.Text
                      style={{ fontSize: "75%" }}
                      type="secondary"
                    >
                      <FormattedMessage
                        id="required"
                        defaultMessage="Required"
                      />
                      :{" "}
                      {formatUnitValue(
                        builder.waterUsage.getWaterRequired(true),
                        builder.waterUsage.value.waterUnit
                      )}
                    </Typography.Text>
                  }
                >
                  {formatUnitValue(
                    builder.waterUsage.value.waterAmount,
                    builder.waterUsage.value.waterUnit
                  )}
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} lg={12} xl={7}>
                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/area.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.coverageArea"
                        defaultMessage="Coverage Area"
                      />
                    </Space>
                  }
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                >
                  {formatUnitValue(
                    builder.waterUsage.coverageArea,
                    workOrder.locality.areaUnit
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/spray.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.sprayRequired"
                        defaultMessage="Spray Required"
                      />
                    </Space>
                  }
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                >
                  {formatUnitValue(
                    builder.waterUsage.sprayRequired,
                    builder.waterUsage.value.waterUnit
                  )}
                </Form.Item>
              </Col>
            )}

            {builder.isDataIntake ? (
              <Col xs={24} lg={12} xl={7}>
                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/spray.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.sprayUsed"
                        defaultMessage="Spray Used"
                      />
                    </Space>
                  }
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                  extra={
                    <Typography.Text
                      style={{ fontSize: "75%" }}
                      type="secondary"
                    >
                      <FormattedMessage
                        id="required"
                        defaultMessage="Required"
                      />
                      :{" "}
                      {formatUnitValue(
                        builder.waterUsage.sprayRequired,
                        builder.waterUsage.value.waterUnit
                      )}
                    </Typography.Text>
                  }
                >
                  {formatUnitValue(
                    builder.waterUsage.sprayUsed,
                    builder.waterUsage.value.waterUnit
                  )}
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} lg={12} xl={7}>
                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/tank.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.tanksRequired"
                        defaultMessage="Tanks Required"
                      />
                    </Space>
                  }
                  name={["waterUsage", "tanksRequired"]}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                >
                  <>{formatNumber(builder.waterUsage.value?.tanksRequired)}</>
                </Form.Item>

                <Form.Item
                  label={
                    <Space>
                      <Icon
                        style={{ verticalAlign: "top" }}
                        component={() => <img src="/images/icons/water.svg" />}
                      />
                      <FormattedMessage
                        id="workOrders.spraying.waterAmount"
                        defaultMessage="Water"
                      />
                    </Space>
                  }
                  required={false}
                  name={["waterUsage", "waterAmount"]}
                  rules={[Rules.gtZero]}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 5 }}
                >
                  {formatUnitValue(
                    builder.waterUsage.value.waterAmount,
                    builder.waterUsage.value.waterUnit
                  )}
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <WorkOrderWaterInputs />
            </Col>

            <Col span={24}>
              <Divider />
            </Col>

            <Col xs={24} md={16}>
              <Form.Item
                name={"equipmentIds"}
                label={<FormattedMessage id="equipment" defaultMessage="PPE" />}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                {builder.isReadonly ? (
                  <>{formatTags(workOrder.equipment.map((s) => s.name))}</>
                ) : (
                  <SelectField
                    optionsHook={useEquipmentOptions}
                    mode="multiple"
                  />
                )}
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name={["waterUsage", "waterSourceIds"]}
                label={
                  <FormattedMessage
                    id="workOrders.spraying.waterSources"
                    defaultMessage="Water Sources"
                  />
                }
                {...formItemProps}
              >
                {builder.isReadonly ? (
                  <>
                    {formatTags(
                      workOrder.waterUsage?.waterSources.map((s) => s.name),
                      "blue"
                    )}
                  </>
                ) : (
                  <SelectField
                    optionsHook={useWaterSourceOptions}
                    mode="multiple"
                    tagRender={(props) =>
                      tagRender({ color: "blue", ...props })
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )
      }
    </Form.Item>
  );
}
