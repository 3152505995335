import { useIntl } from "react-intl";
import { CounterpartyFragment } from "../../lib/graphql";
import { useCounterpartyOptions, useItemSidebarContext } from "../../lib/hooks";
import { SelectField, SelectFieldProps } from "../form";
import { NewCounterpartyForm } from "../inventory";
import { ItemSidebar } from "../shared";

interface CounterpartySelectProps extends SelectFieldProps {
  allowAddNew?: boolean;
  onChangeCounterparty?(counterparty?: CounterpartyFragment): void;
  multiple?: boolean;
}

export function CounterpartySelect({
  allowAddNew,
  onChangeCounterparty,
  onChange,
  multiple,
  ...rest
}: CounterpartySelectProps) {
  const { setCurrentAction } = useItemSidebarContext();
  const intl = useIntl();

  return (
    <>
      <SelectField
        optionsHook={useCounterpartyOptions}
        onChange={(
          value,
          options: ReturnType<typeof useCounterpartyOptions>["options"]
        ) => {
          if (onChange) onChange(value, options);

          if (onChangeCounterparty) {
            const counterparty = options?.find(
              (c) => c.key == value
            )?.counterparty;

            onChangeCounterparty(counterparty);
          }
        }}
        newItemProps={
          allowAddNew
            ? {
                entityName: intl.formatMessage({
                  id: "counterparties.entityName",
                }),
                onClick: () => {
                  setCurrentAction("newCounterparty");
                },
              }
            : undefined
        }
        mode={multiple ? "multiple" : undefined}
        {...rest}
      />

      {allowAddNew && (
        <ItemSidebar
          item={null}
          sidebarActions={{
            newCounterparty: ({ closeSidebar }) => (
              <NewCounterpartyForm onClose={closeSidebar} />
            ),
          }}
        />
      )}
    </>
  );
}
