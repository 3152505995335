import { WorkOrderStatus } from "../../../lib/graphql";
import { useIntl, defineMessages } from "react-intl";
import { CheckOutlined } from "@ant-design/icons";
import { SubmitButton, useFormContext, Form } from "../../form";
import { useContext } from "react";
import { WorkOrderContext } from "./WorkOrderContext";
import { useCurrentUser } from "../../../lib/hooks";
import { shouldUpdateBy } from "../../../lib/formats";

const messages = defineMessages({
  submit: { id: "workOrders.submit", defaultMessage: "submit" },
  submitted: {
    id: "workOrders.submitted",
    defaultMessage: "submitted",
  },
  start: { id: "workOrders.start", defaultMessage: "start" },
  started: {
    id: "workOrders.started",
    defaultMessage: "started",
  },
  complete: { id: "workOrders.complete", defaultMessage: "completeWorkOrder" },
  completed: {
    id: "workOrders.completed",
    defaultMessage: "completed",
  },
  schedule: {
    id: "workOrders.schedule",
    defaultMessage: "Schedule Work Order",
  },
  scheduled: {
    id: "workOrders.scheduled",
    defaultMessage: "Scheduled",
  },
});

export function WorkOrderSubmitButton() {
  const intl = useIntl();
  const { form } = useFormContext();
  const { currentTenant } = useCurrentUser();
  const { workOrder, builder } = useContext(WorkOrderContext);
  if (!workOrder) return null;

  return (
    <Form.Item
      shouldUpdate={shouldUpdateBy(
        (wo) => wo.inputs.length,
        (wo) => wo.tools.length,
        (wo) => wo.tokens.length,
        (wo) => wo.inventoryStatus,
        (wo) => wo.assignedTo
      )}
      noStyle
    >
      {() => {
        let action = {
          nextStatus: WorkOrderStatus.Completed,
          successMessage: messages.completed,
          title: messages.complete,
        };

        if (workOrder.status === WorkOrderStatus.Open && workOrder.assignedTo) {
          action = {
            nextStatus: WorkOrderStatus.Scheduled,
            successMessage: messages.scheduled,
            title: messages.schedule,
          };
        } else if (
          workOrder.status === WorkOrderStatus.Open ||
          workOrder.status === WorkOrderStatus.Scheduled
        ) {
          action = {
            nextStatus: WorkOrderStatus.InProgress,
            successMessage: messages.started,
            title:
              currentTenant.inventoryRequestEnabled && builder.canSubmit
                ? messages.submit
                : messages.start,
          };
        }

        const hasRequests =
          !!currentTenant.inventoryRequestEnabled &&
          (builder.inputs.isRequesting ||
            builder.tools.isRequesting ||
            builder.tokens.isRequesting);

        return (
          <SubmitButton
            type="primary"
            icon={<CheckOutlined />}
            className="btn-complete"
            disabled={
              action.nextStatus === WorkOrderStatus.Completed && hasRequests
            }
            onClick={() => {
              form.setFields([
                { name: "nextStatus", value: action.nextStatus },
                {
                  name: "successMessage",
                  value: action.successMessage,
                },
              ]);
            }}
            data-action="submit-wo"
          >
            <span>{intl.formatMessage(action.title)}</span>
          </SubmitButton>
        );
      }}
    </Form.Item>
  );
}
