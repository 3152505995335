import { List } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { formatDate, formatDocumentLink } from "../../lib/formats";
import {
  FinanceOrderFragment,
  FinanceOrderStatus,
  InvoiceStatus,
} from "../../lib/graphql";
import { useFinanceOrder, useFinanceOrderUpdate } from "../../lib/hooks";
import {
  ChangeLogSidebar,
  ConfirmSidebar,
  ConfirmSidebarProps,
  ItemSidebar,
} from "../shared";
import { OrderCloneForm } from "./orders/CloneForm";
import routes from "../../lib/routes";

function RevertConfirmSidebar({
  id,
  ...confirmProps
}: ConfirmSidebarProps & { id: string }) {
  const order = useFinanceOrder(id, true);
  const loading = !order;

  const documents = order?.invoices
    ? [
        ...order.invoices
          .filter((i) => i.invoiceStatus !== InvoiceStatus.Reverted)
          .map((i) => ({
            date: formatDate(i.documentDate),
            link: formatDocumentLink(i.id, "financeInvoice"),
          })),
      ]
    : [];

  return (
    <ConfirmSidebar
      okButtonProps={{
        danger: true,
        loading,
        disabled: loading || documents.length > 0,
      }}
      okText={<FormattedMessage id="revert" defaultMessage="revert" />}
      {...confirmProps}
    >
      <List
        header={
          <strong>
            <FormattedMessage
              id="revert.nextDocuments"
              defaultMessage="List of related documents"
            />
          </strong>
        }
        locale={{
          emptyText: (
            <FormattedMessage
              id="revert.noNextDocuments"
              defaultMessage="No related documents"
            />
          ),
        }}
        loading={loading}
        dataSource={documents}
        renderItem={(doc) => <List.Item extra={doc.date}>{doc.link}</List.Item>}
      />
    </ConfirmSidebar>
  );
}

export function FinanceOrderSidebar({
  order,
}: {
  order: FinanceOrderFragment;
}) {
  const intl = useIntl();
  const [update] = useFinanceOrderUpdate();
  const entityName = intl.formatMessage({ id: "expenseOrders.entityName" });

  return (
    <ItemSidebar
      item={order}
      sidebarActions={{
        cancel: ({ closeSidebar }) => (
          <ConfirmSidebar
            title={
              <FormattedMessage
                id="cancel.doc.header"
                defaultMessage="cancel {entityName}"
                values={{ entityName }}
              />
            }
            onCancel={closeSidebar}
            okButtonProps={{ danger: true }}
            okText={
              <FormattedMessage id="cancel.doc" defaultMessage="Cancel" />
            }
            cancelText={
              <FormattedMessage id="cancel.doc.return" defaultMessage="Back" />
            }
            onOk={({ showSuccess }) =>
              update({
                variables: {
                  id: order.id,
                  changes: { status: FinanceOrderStatus.Canceled },
                },
              }).then((result) => {
                const updateResult =
                  result.data && result.data.financeOrderUpdate;

                if (updateResult && updateResult.result) {
                  closeSidebar();

                  showSuccess(
                    intl.formatMessage(
                      { id: "cancel.doc.success" },
                      {
                        entityName,
                        id: order.id,
                      }
                    )
                  );
                }
              })
            }
          >
            <FormattedMessage
              id="cancel.doc.confirm"
              defaultMessage="confirm"
            />
          </ConfirmSidebar>
        ),
        revert: ({ closeSidebar }) => (
          <RevertConfirmSidebar
            id={order.id}
            title={
              <FormattedMessage
                id="revert.header"
                defaultMessage="revert {entityName}"
                values={{ entityName }}
              />
            }
            onCancel={closeSidebar}
            onOk={({ showSuccess }) =>
              update({
                variables: {
                  id: order.id,
                  changes: { status: FinanceOrderStatus.Reverted },
                },
              }).then((result) => {
                const updateResult =
                  result.data && result.data.financeOrderUpdate;

                if (updateResult && updateResult.result) {
                  closeSidebar();
                  showSuccess(
                    intl.formatMessage(
                      { id: "revert.success" },
                      {
                        entityName,
                        id: order.id,
                      }
                    )
                  );
                }
              })
            }
          />
        ),
        changeLog: ({ closeSidebar }) => (
          <ChangeLogSidebar
            id={order.id}
            type="Finance::Order"
            onClose={closeSidebar}
          />
        ),
        clone: ({ item, closeSidebar }) => (
          <OrderCloneForm
            item={item}
            entityName={entityName}
            entityUrl={routes.finance.expenseOrders.details}
            onClose={closeSidebar}
          />
        ),
      }}
    />
  );
}
