import {
  InventoryRequestDetailsFragment,
  InventoryStatus,
} from "../../../lib/graphql";
import { Button, Space, Tooltip, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { TableInput } from "../../shared";
import {
  formatUnitValue,
  colors,
  formatUnitValueConversion,
  formatVariantLink,
} from "../../../lib/formats";
import { Form, useFormContext } from "../../form";
import { StockWarehouseSelect } from "../../warehouses";
import {
  AuditOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";

export function InventoryRequestVariants({
  request,
  readonly,
}: {
  request: InventoryRequestDetailsFragment;
  readonly?: boolean;
}) {
  const { form } = useFormContext();

  return (
    <TableInput
      name="variants"
      dataSource={request.variants}
      rowKey={(f) => f.variant.id}
      disabled={readonly}
      hideMenu
      columns={[
        {
          title: <FormattedMessage id="variants.entityName" />,
          dataIndex: ["variant", "id"],
          render: (_, r) => formatVariantLink(r.variant),
        },
        {
          title: (
            <FormattedMessage
              id="variants.qtyRequested"
              defaultMessage="qtyRequested"
            />
          ),
          dataIndex: "totalAmount",
          render: (_, r) =>
            formatUnitValueConversion(r.totalAmount, {
              unit: r.unit,
              conversionFactor: r.conversionFactor,
              conversionUnit: r.variant.variationUnit,
              inverse: true,
            }),
        },
        {
          title: (
            <FormattedMessage
              id="inventoryRequests.currentStock"
              defaultMessage="currentStock"
            />
          ),
          render: (_, r, index) => (
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const stock = getFieldValue([
                  "variants",
                  index,
                  "stock",
                  "onHand",
                ]);

                if (stock === undefined) return;

                return formatUnitValue(stock, r.unit);
              }}
            </Form.Item>
          ),
        },
        {
          title: (
            <FormattedMessage
              id="inventoryRequests.sourceWarehouse"
              defaultMessage="sourceWarehouse"
            />
          ),
          dataIndex: "sourceWarehouse",
          width: 300,
          render: (_, r, index) =>
            readonly || r.status === InventoryStatus.Assigned ? (
              r.sourceWarehouse?.name
            ) : (
              <Form.Item
                name={["variants", index, "sourceWarehouseId"]}
                compact
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_rule, value) {
                      const stock = getFieldValue(["variants", index, "stock"]);
                      const totalAmount = getFieldValue([
                        "variants",
                        index,
                        "totalAmount",
                      ]);

                      const completeRequest = getFieldValue("completeRequest");

                      if (!completeRequest) return Promise.resolve();

                      if (value && stock && stock.onHand >= totalAmount) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        <FormattedMessage
                          id="stock.unsufficient"
                          defaultMessage="unsufficient"
                        />
                      );
                    },
                  }),
                ]}
              >
                <StockWarehouseSelect
                  variant={r.variant}
                  unit={r.unit}
                  localityId={request.workOrder.locality.id}
                  date={request.workOrder.documentDate}
                  labelRenderer={(label, option) => {
                    const stock = option.warehouse.stock.onHand;
                    return (
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => (
                          <>
                            {stock &&
                            stock >=
                              getFieldValue([
                                "variants",
                                index,
                                "totalAmount",
                              ]) ? (
                              <CheckCircleTwoTone
                                twoToneColor={colors.successColor}
                                style={{ marginRight: "8px" }}
                              />
                            ) : (
                              <CloseCircleTwoTone
                                twoToneColor={colors.dangerColor}
                                style={{ marginRight: "8px" }}
                              />
                            )}
                            {label}
                          </>
                        )}
                      </Form.Item>
                    );
                  }}
                  onChangeWarehouse={(warehouse) => {
                    form.setFields([
                      {
                        name: ["variants", index, "stock"],
                        value: warehouse?.stock,
                      },
                    ]);
                  }}
                />
              </Form.Item>
            ),
        },
        {
          title: (
            <FormattedMessage
              id="inventoryRequests.assignedQTY"
              defaultMessage="Assigned QTY"
            />
          ),
          width: 220,
          render: (_, r, index) =>
            readonly || r.status === InventoryStatus.Assigned ? (
              formatUnitValue(r.totalAmount, r.unit, null, {
                maximumFractionDigits: 4,
              })
            ) : (
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const stock = getFieldValue([
                    "variants",
                    index,
                    "stock",
                    "onHand",
                  ]);

                  if (stock === undefined) return;

                  const totalAmount = getFieldValue([
                    "variants",
                    index,
                    "totalAmount",
                  ]);

                  const isInsufficient = stock < totalAmount;

                  return (
                    <Space direction="horizontal">
                      <Typography.Text
                        type={
                          readonly || !isInsufficient ? undefined : "danger"
                        }
                      >
                        {formatUnitValue(
                          isInsufficient ? stock : totalAmount,
                          r.unit,
                          null,
                          { maximumFractionDigits: 4 }
                        )}
                      </Typography.Text>

                      {!readonly && stock > 0 && isInsufficient && (
                        <Tooltip
                          title={
                            <FormattedMessage
                              id="inventoryRequests.fillPartiallyHelp"
                              defaultMessage="Update QTY Requested to Current Stock"
                            />
                          }
                        >
                          <Button
                            type="primary"
                            className="btn-yellow"
                            size="small"
                            icon={<AuditOutlined />}
                            onClick={() =>
                              form.setFields([
                                {
                                  name: ["variants", index, "assignedAmount"],
                                  value: stock,
                                },
                                {
                                  name: ["variants", index, "totalAmount"],
                                  value: stock,
                                },
                              ])
                            }
                          >
                            <span>
                              <FormattedMessage
                                id="inventoryRequests.fillPartially"
                                defaultMessage="Fill Partially"
                              />
                            </span>
                          </Button>
                        </Tooltip>
                      )}
                    </Space>
                  );
                }}
              </Form.Item>
            ),
        },
      ]}
    />
  );
}
