import { debounce, sumBy } from "lodash";
import { WorkOrderBuilder } from "..";
import {
  distributeEqually,
  distributeMatrix,
  roundUnit,
} from "../../../../../lib/formats";
import { WithIDType } from "../../../../../lib/hooks";
import { WorkOrderCostCenter } from "../costCenters";

const DEBOUNCE_WAIT = 300;

export abstract class ProgressBuilder<
  T extends WithIDType & { totalProgress?: number }
> {
  protected builder: WorkOrderBuilder;

  constructor(builder: WorkOrderBuilder) {
    this.builder = builder;
  }

  /**
   * Distributes worker total progress between cost centers (debounced)
   * @param worker - worker to distribute progress
   * @param totalProgress - total progress to distribute
   */
  public debouncedDistributeTotalProgress = debounce(
    this.distributeTotalProgress,
    DEBOUNCE_WAIT
  );

  /**
   * Initializes total progress for workers
   * @param workers - workers to initialize total progress
   * @param force - force initialize total progress
   */
  initTotalProgress(workers: T[], force?: boolean) {
    let newAdded = false;
    const costCenters = this.builder.costCenters.get();

    workers.forEach((e) => {
      const initTotalProgress = e.totalProgress == null || force;
      if (initTotalProgress) e.totalProgress = 0;

      costCenters.forEach((cc) => {
        const progress = this.findOrCreateCostCenterProgress(cc, e);
        if (progress == null) {
          newAdded = true;
        } else if (initTotalProgress) {
          e.totalProgress = (e.totalProgress || 0) + progress;
        }
      });

      if (initTotalProgress) e.totalProgress = roundUnit(e.totalProgress || 0);
    });

    if (newAdded) this.onWorkerCountChanged();
  }

  /**
   * Execute distribution when worker count changed
   */
  onWorkerCountChanged() {
    if (this.builder.progressByGroup) {
      this.redistributeCostCentersProgress();
    } else {
      this.builder.costCenters.recalculateCropFieldTotalProgress();
    }
  }

  /**
   * Reistributes ALL cost centers total progress between workers
   */
  redistributeCostCentersProgress() {
    const workers = this.getWorkers();
    const count = workers.length;
    if (!count) return;

    const costCenters = this.builder.costCenters.get();
    const setProgress = this.progressSetter();

    workers.forEach((e, wIndex) => {
      e.totalProgress = 0;

      costCenters.forEach((cc) => {
        const progress = distributeEqually(cc.totalProgress || 0, count);

        setProgress(cc, e, progress[wIndex]);
        e.totalProgress = roundUnit((e.totalProgress || 0) + progress[wIndex]);
      });
    });
  }

  /**
   * Distributes cost center total progress between workers
   * @param costCenterIndex - cost center index
   * @param val - cost center total progress
   */
  distributeCostCenterProgress(costCenterIndex: number, val?: number | null) {
    if (val == undefined || val === null) return;

    const workers = this.getWorkers();
    const count = workers.length;

    if (count) {
      const progress = distributeEqually(val, count);
      const costCenter = this.builder.costCenters.getBy(costCenterIndex);
      const setProgress = this.progressSetter();

      workers.forEach((e, wIndex) => {
        setProgress(costCenter, e, progress[wIndex]);
      });

      this.initTotalProgress(workers, true);
    }
  }

  /**
   * Distributes worker total progress between cost centers
   * @param worker - worker to distribute progress
   * @param totalProgress - total progress to distribute
   */
  distributeTotalProgress(worker: T, totalProgress?: number | null) {
    if (totalProgress == undefined || totalProgress === null) return;

    const costCenters = this.builder.costCenters.get();
    const progress = distributeEqually(totalProgress, costCenters.length);
    const setProgress = this.progressSetter();

    costCenters.forEach((cc, ccIndex) => {
      const costCenterIndex = this.builder.costCenters.indexOf(cc);
      setProgress(cc, worker, progress[ccIndex]);
      this.recalculateCostCenterProgress(costCenterIndex, false);
    });

    this.builder.costCenters.recalculateProgressPercentage();
  }

  /**
   * Redistributes ALL workers total progress between cost centers
   */
  redistributeTotalProgress() {
    const workers = this.getWorkers();
    const costCenters = this.builder.costCenters.get();
    const setProgress = this.progressSetter();

    workers.forEach((e) => {
      const progress = distributeEqually(
        e.totalProgress || 0,
        costCenters.length
      );

      costCenters.forEach((cc, ccIndex) => {
        setProgress(cc, e, progress[ccIndex]);
      });
    });
  }

  /**
   * Recalculates worker progress when progress percentage changed
   * Apply distribution matrix to worker progress
   */
  recalculateCropFieldWorkerProgress() {
    if (this.builder.outputs.distributed || this.builder.tokens.hasItems)
      return;

    const workers = this.getWorkers();
    if (!workers.length) return;

    const costCenters = this.builder.costCenters.get();
    const setProgress = this.progressSetter();

    const distribution = distributeMatrix(
      workers.map((e) => e.totalProgress || 0),
      costCenters.map((cc) => cc.totalProgress)
    );

    workers.forEach((e, i) => {
      // set cost center worker progress to leave user input value
      costCenters.forEach((cc, ccIndex) => {
        setProgress(cc, e, distribution[i][ccIndex]);
      });
    });
  }

  /**
   *  Recalculates cost center progress by cc workers progress
   * @param costCenterIndex - cost center index
   */
  recalculateCostCenterProgress(
    costCenterIndex: number,
    initTotalProgress = true
  ) {
    const costCenter = this.builder.costCenters.getBy(costCenterIndex) as any;

    this.builder.form.setFields([
      {
        name: ["costCenters", costCenterIndex, "totalProgress"],
        value: roundUnit(sumBy(costCenter[this.field], "progress")),
      },
    ]);

    if (initTotalProgress) this.initTotalProgress(this.getWorkers(), true);
  }

  /**
   * Resets worker progress
   * @param worker - worker to reset progress
   */
  resetProgress(worker: T) {
    const costCenters = this.builder.costCenters.get(false);
    const setProgress = this.progressSetter();

    worker.totalProgress = 0;
    costCenters.forEach((cc) => {
      setProgress(cc, worker, 0);
    });
  }

  /**
   * Progress setter.
   * Why we need it?
   * Because we can't get correct cost center index for newly added cost centers, we have to fetch
   * `allCostCenters` to find correct index
   */
  protected progressSetter() {
    // get all cost centers to find correct index in deleted entities
    const allCostCenters = this.builder.costCenters.get(false);
    return (cc: any, worker: T, progress: number) => {
      const index = this.findWorkerIndex(cc, worker);

      this.builder.form.setFields([
        {
          name: [
            "costCenters",
            allCostCenters.indexOf(cc),
            this.field,
            index,
            "progress",
          ],
          value: progress || 0,
        },
      ]);
    };
  }

  /**
   * Finds or creates cc worker progress
   * @param costCenter - cost center to find worker progress
   * @param e - worker to find progress
   * @returns worker progress
   */
  protected abstract findOrCreateCostCenterProgress(
    costCenter: WorkOrderCostCenter,
    e: T
  ): number | null;

  abstract getWorkers(): T[];
  protected abstract get field(): string;

  protected abstract findWorkerIndex(
    costCenter: WorkOrderCostCenter,
    worker: T
  ): number;
}
