import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderIrrigation } from "../../WorkOrderIrrigation";

export function Irrigation() {
  return {
    key: "irrigation",
    visible: true,
    icon: <Icon component={() => <img src="/images/icons/sprinkle.svg" />} />,
    name: <FormattedMessage id="activities.irrigation" />,
    views: [
      {
        title: (
          <FormattedMessage
            id="workOrders.irrigation"
            defaultMessage="Irrigation Stations & Fields"
          />
        ),
        description: (
          <FormattedMessage
            id="workOrders.irrigation.hint"
            defaultMessage="hint"
          />
        ),
        render: () => <WorkOrderIrrigation />,
      },
    ],
  };
}
