import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderOutputs } from "../../WorkOrderOutputs";
import { WorkOrderProcessOutputs } from "../../WorkOrderProcessOutputs";

export function Outputs(
  visible: boolean,
  readonly: boolean,
  byPercent: boolean
) {
  const title = (
    <FormattedMessage id="workOrders.outputs" defaultMessage="outputs" />
  );
  const description = (
    <FormattedMessage id="workOrders.outputs.hint" defaultMessage="outputs" />
  );

  return {
    key: "outputs",
    visible,
    icon: <Icon component={() => <img src="/images/icons/harvest.svg" />} />,
    name: <FormattedMessage id="workOrders.outputs" defaultMessage="outputs" />,
    viewProperty: "harvestByOutput",
    currentViewIndex: byPercent ? 1 : 0,
    disallowRemove: true,
    views: [
      {
        title,
        description,
        menuItem: (
          <FormattedMessage
            id="workOrders.outputs.byAmount"
            defaultMessage="byAmount"
          />
        ),
        render: () => <WorkOrderOutputs readonly={readonly} mode="amount" />,
      },
      {
        title,
        description,
        menuItem: (
          <FormattedMessage
            id="workOrders.outputs.byPercent"
            defaultMessage="byPercent"
          />
        ),
        render: () => (
          <WorkOrderOutputs readonly={readonly} mode="percentage" />
        ),
      },
    ],
  };
}

export function ProcessOutputs() {
  const name = (
    <FormattedMessage
      id="workOrders.processOutputs"
      defaultMessage="Process Outputs"
    />
  );
  return {
    key: "outputs",
    visible: true,
    icon: (
      <Icon
        component={() => (
          <img src="/images/icons/convert-item.svg" width={42} />
        )}
      />
    ),
    name,
    disallowRemove: true,
    views: [
      {
        title: name,
        description: (
          <FormattedMessage
            id="workOrders.processOutputs.hint"
            defaultMessage="hint"
          />
        ),
        render: () => <WorkOrderProcessOutputs />,
      },
    ],
  };
}
