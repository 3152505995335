import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { TableInput } from "../../shared";
import {
  ItemKind,
  InventoryStatus,
  WorkOrderVariantFragment,
  WorkOrderStatus,
} from "../../../lib/graphql";
import {
  formatUnitValue,
  formatInventoryStatus,
  formatPercent,
  formatVariantLink,
  shouldUpdate,
  shouldUpdateBy,
} from "../../../lib/formats";

import { useContext } from "react";
import { Form, Rules, InputNumber, RuleBuilder } from "../../form";
import { useVariantOptions } from "../../../lib/hooks/inventory/variants";
import { StockWarehouseSelect } from "../../warehouses";
import { Divider, Table, Typography } from "antd";
import { WorkOrderContext } from "./WorkOrderContext";
import { ColumnsType } from "antd/lib/table";
import { useCurrentLocale } from "../../../lib/hooks";

type OutputMode = "percentage" | "amount";

const messages = defineMessages({
  mustFill: {
    id: "workOrders.outputs.mustFill.message",
    defaultMessage: "mustFill",
  },
});

function DistributeStatus({ mode }: { mode: OutputMode }) {
  const { workOrder, builder } = useContext(WorkOrderContext);

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate("outputs")}>
      {() => {
        const toDistribute = builder.costCenters.getProgressToDistribute();
        const distributed = builder.outputs.getTotalAmount();
        const totalPercent = builder.outputs.getTotalPercentage();

        return (
          <Typography.Paragraph>
            <FormattedMessage id="workOrders.totalHarvested" />:{" "}
            <strong>
              {formatUnitValue(toDistribute, workOrder.activity.progressUnit)}
            </strong>
            <Divider type="vertical" />
            <FormattedMessage
              id="workOrders.leftToDistribute"
              defaultMessage="leftToDistribute"
            />
            :{" "}
            <Typography.Text
              strong
              type={toDistribute === distributed ? "success" : "danger"}
            >
              {mode === "amount"
                ? formatUnitValue(
                    toDistribute - distributed,
                    workOrder.activity.progressUnit
                  )
                : formatPercent(1 - totalPercent)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      }}
    </Form.Item>
  );
}

export function WorkOrderOutputs({
  readonly,
  mode,
}: {
  readonly: boolean;
  mode: OutputMode;
}) {
  const { showError } = useCurrentLocale();
  const intl = useIntl();
  const { workOrder, builder } = useContext(WorkOrderContext);
  const harvestUnit = workOrder.activity.progressUnit;

  return (
    <Form.Item
      noStyle
      shouldUpdate={shouldUpdateBy(
        (val) => val.costCenters,
        (val) => val.outputs.length
      )}
    >
      {() => {
        builder.outputs.initOutputs(readonly);

        const allowEdit = !readonly && builder.outputs.allowDistribution;

        const columns: ColumnsType<WorkOrderVariantFragment> = [
          {
            title: (
              <FormattedMessage
                id="workOrders.itemVariantsHarvested"
                defaultMessage="itemVariantsHarvested"
              />
            ),
            dataIndex: "variant",
            width: 300,
            render: (_, v) => formatVariantLink(v.variant),
          },
          {
            title: (
              <FormattedMessage
                id="workOrders.amountToDeliver"
                defaultMessage="amountToDeliver"
              />
            ),
            dataIndex: "totalAmount",
            width: 200,
            render: (_, v, index) =>
              !allowEdit || mode === "percentage" ? (
                <Form.Item
                  noStyle
                  shouldUpdate={shouldUpdate(["outputs", index, "totalAmount"])}
                >
                  {() =>
                    formatUnitValue(
                      builder.outputs.getBy(index).totalAmount,
                      v.unit
                    )
                  }
                </Form.Item>
              ) : (
                <Form.Item
                  compact
                  name={["outputs", index, "totalAmount"]}
                  rules={[Rules.gtZero]}
                >
                  <InputNumber
                    step={0.1}
                    min={0}
                    addonAfter={harvestUnit.abbr}
                    onChange={() => builder.outputs.onAmountChanged(index)}
                  />
                </Form.Item>
              ),
          },
          {
            title: <FormattedMessage id="percent" />,
            dataIndex: "percent",
            width: 200,
            render: (_, _v, index) =>
              !allowEdit || mode === "amount" ? (
                formatPercent(builder.outputs.getPercent(index) / 100)
              ) : (
                <Form.Item compact name={["outputs", index, "percent"]}>
                  <InputNumber
                    step={0.1}
                    min={0}
                    precision={2}
                    addonAfter="%"
                    onChange={() => {
                      builder.outputs.onPercentChanged(index);
                    }}
                  />
                </Form.Item>
              ),
          },
          {
            title: (
              <FormattedMessage
                id="workOrders.destinationWarehouse"
                defaultMessage="destinationWarehouse"
              />
            ),
            width: 300,
            render: (_, v, index) =>
              readonly ? (
                v.returnWarehouse?.name
              ) : (
                <Form.Item
                  name={["outputs", index, "returnWarehouseId"]}
                  rules={[Rules.required]}
                  compact
                >
                  <StockWarehouseSelect
                    variant={v.variant}
                    localityId={workOrder.locality.id}
                  />
                </Form.Item>
              ),
          },
        ];

        if (workOrder.status === WorkOrderStatus.Completed) {
          columns.push({
            title: <FormattedMessage id="workOrders.inventoryStatus" />,
            width: 150,
            fixed: "right",
            dataIndex: "status",
            render: () => {
              return formatInventoryStatus("delivered");
            },
          });
        }

        return (
          <>
            {!readonly && <DistributeStatus mode={mode} />}

            <TableInput
              name="outputs"
              dataSource={workOrder.outputs}
              rowKey={(f) => f.variant.id}
              disabled={readonly}
              rules={[
                RuleBuilder.custom(() => {
                  if (builder.outputs.hasItems || !builder.tokens.hasItems)
                    return true;

                  showError(intl.formatMessage(messages.mustFill));

                  return (
                    <FormattedMessage
                      id="workOrders.outputs.mustFill"
                      defaultMessage="Please fill outputs"
                    />
                  );
                }),
              ]}
              tableSelectProps={{
                mode: "multiple",
                optionsHook: useVariantOptions,
                optionsHookParams: {
                  variables: {
                    filter: { itemKind: [ItemKind.Producible] },
                  },
                },
                placeholder: (
                  <FormattedMessage
                    id="select.variants"
                    defaultMessage="variants"
                  />
                ),
                entityById: (_, { variant }) => {
                  if (variant) {
                    return {
                      id: "",
                      variant,
                      totalAmount: 0,
                      status: InventoryStatus.NotRequested,
                      unit: variant.variationUnit,
                    };
                  }
                },
              }}
              addSorter={(a, b) => builder.outputs.sorter(a, b)}
              onRemove={(v) => builder.outputs.onRemove(v)}
              columns={columns}
              tableProps={{
                bordered: true,
                summary: (outputs) => {
                  if (outputs.filter(Form.undestroyed).length === 0) return;

                  return (
                    <Form.Item noStyle shouldUpdate={shouldUpdate("outputs")}>
                      {() => {
                        const toDistribute =
                          builder.costCenters.getProgressToDistribute();
                        const total = builder.outputs.getTotalAmount();
                        const totalPercent =
                          builder.outputs.getTotalPercentage();

                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              <strong>
                                <FormattedMessage id="total" />
                              </strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              <Form.Item
                                name="outputs.totals"
                                compact
                                rules={[
                                  () => ({
                                    validator() {
                                      if (total == toDistribute)
                                        return Promise.resolve();

                                      return Promise.reject(
                                        <FormattedMessage
                                          id="form.validation.mustSumTo"
                                          defaultMessage="mustSumTo"
                                          values={{
                                            max: total,
                                            now: toDistribute,
                                          }}
                                        />
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <Typography.Text
                                  strong
                                  type={
                                    toDistribute != total ? "danger" : undefined
                                  }
                                >
                                  {formatUnitValue(total, harvestUnit)}
                                </Typography.Text>
                              </Form.Item>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                              <Typography.Text
                                strong
                                type={
                                  toDistribute != total ? "danger" : undefined
                                }
                              >
                                {formatPercent(totalPercent)}
                              </Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={2} />
                          </Table.Summary.Row>
                        );
                      }}
                    </Form.Item>
                  );
                },
              }}
            />
          </>
        );
      }}
    </Form.Item>
  );
}
