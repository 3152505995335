import { WorkOrderBuilder } from "..";
import { WorkOrderCostCenterMetricFragment } from "../../../../../lib/graphql";
import { WorkOrderCostCenter } from "../costCenters";
import { roundUnit } from "../../../../../lib/formats";
import { sumBy } from "lodash";
import { DeepNamePath } from "../../../../form";
import { debounce } from "../../../../../lib/utils";

export abstract class WorkOrderMetricBuilder {
  protected builder: WorkOrderBuilder;

  public debouncedDistributeMetric = debounce(this.distributeMetric);

  constructor(builder: WorkOrderBuilder) {
    this.builder = builder;
  }

  protected abstract finder(
    metricId: string,
    individualId?: string
  ): (metric: WorkOrderCostCenterMetricFragment) => boolean;

  abstract build(
    costCenter?: WorkOrderCostCenter
  ): WorkOrderCostCenterMetricFragment[];

  abstract distributeMetric(
    metricId: string,
    value?: number | null,
    ccIndex?: number
  ): void;

  get activityMetrics() {
    return this.builder.workOrder.activity.activityMetrics;
  }

  initInidividualMetricFields() {}

  initMetricFields() {
    const costCenters = this.builder.costCenters.get();
    costCenters.forEach((cc) => {
      cc.metrics.push(...this.build(cc));
    });

    this.initInidividualMetricFields();
  }

  getMetricValue(
    costCenter: WorkOrderCostCenter,
    metricId: string,
    individualId?: string
  ) {
    return costCenter.metrics.find(this.finder(metricId, individualId))?.value;
  }

  metricValueFieldName(
    ccIndex: number,
    metricId: string,
    individualId?: string
  ): DeepNamePath {
    const metricIndex = this.builder.costCenters
      .getBy(ccIndex)
      .metrics.findIndex(this.finder(metricId, individualId));
    return ["costCenters", ccIndex, "metrics", metricIndex, "value"];
  }

  getTotalMetric(
    metricId: string,
    costCenter?: WorkOrderCostCenter,
    individualId?: string
  ) {
    const costCenters = costCenter
      ? [costCenter]
      : this.builder.costCenters.get();

    return roundUnit(
      sumBy(
        costCenters,
        (cc) => this.getMetricValue(cc, metricId, individualId) || 0
      )
    );
  }
}
