import { sumBy } from "lodash";
import { distributeProportionally } from "../../../../lib/formats";
import { WorkOrderVariantTokenFragment } from "../../../../lib/graphql";
import { WorkOrderToolBuilder } from "./tools";

export class WorkOrderTokenBuilder extends WorkOrderToolBuilder {
  protected get field() {
    return "tokens";
  }

  init() {
    const tokens = this.get();

    tokens.forEach((i) => {
      if (!i.returnedAmount) {
        i.returnedAmount = 0;
      }
    });
  }

  recalculateCropFieldTotals(updateState = false) {
    const costCenters = this.getCostCenters();

    const total = this.totalHarvested();

    const distribution = distributeProportionally(
      total,
      costCenters.map((cc) => cc.progressPercent)
    );

    costCenters.forEach((cc, ccIndex) => {
      if (updateState) {
        this.form.setFields([
          {
            name: this.builder.costCenters.fieldName(ccIndex, "totalProgress"),
            value: distribution[ccIndex],
          },
        ]);
      } else {
        cc.totalProgress = distribution[ccIndex];
      }
    });
  }

  get estimatedTokens() {
    const workerCount = this.form.getFieldValue("workerCount");
    const estimatedNorm = this.form.getFieldValue("estimatedNorm");

    return (workerCount || 0) * (estimatedNorm || 0);
  }

  onTokenChanged() {
    this.recalculateCropFieldTotals(true);
  }

  paidAmount(index: number) {
    const token = this.get(false)[index];
    return Math.max(0, token.totalAmount - (token.returnedAmount || 0));
  }

  totalHarvested() {
    const tokens = this.get();
    return Math.max(
      0,
      sumBy(tokens, (t) => this.tokenHarvested(t))
    );
  }

  totalUnits() {
    const tokens = this.get();
    return sumBy(tokens, (t) => t.totalAmount - (t.returnedAmount || 0));
  }

  getTotalToHarvest(index: number) {
    const token = this.get(false)[index];
    return this.tokenHarvested(token);
  }

  tokenHarvested(token: WorkOrderVariantTokenFragment) {
    return (
      (token.totalAmount - (token.returnedAmount || 0)) *
      (token.variant.token?.quantity || 0)
    );
  }
}
