import { sum, sumBy } from "lodash";
import { WorkOrderBuilder } from ".";
import {
  CropFieldFragment,
  CropFieldMachineFragment,
  MachineCategory,
  WorkOrderCostCenterFragment,
  WorkOrderMachineFragment,
} from "../../../../lib/graphql";
import { Destroy, MachineCalibrations } from "../../../../lib/hooks";
import { WorkOrderBaseBuilder } from "./base";
import { MachineProgress } from "./progress/machineProgress";
import { MachineMetricBuilder } from "./metrics/machineMetrics";

export interface WorkOrderMachine extends Destroy<WorkOrderMachineFragment> {
  totalProgress?: number;
}

export class WorkOrderMachineBuilder extends WorkOrderBaseBuilder<
  Destroy<WorkOrderMachine>
> {
  progress: MachineProgress;
  metrics: MachineMetricBuilder;

  constructor(builder: WorkOrderBuilder) {
    super(builder);

    this.progress = new MachineProgress(builder);
    this.metrics = new MachineMetricBuilder(builder);
  }

  protected get field() {
    return "machines";
  }

  sorter(a: WorkOrderMachine, b: WorkOrderMachine) {
    return a.machine.name.localeCompare(b.machine.name);
  }

  getTotalHours() {
    const machines = this.get();
    return sumBy(machines, (e) => e.workHours);
  }

  getTotalProgress() {
    const machines = this.get();
    return sumBy(machines, (m) => m.totalProgress || 0);
  }

  getCostCenterMachine(
    costCenter: WorkOrderCostCenterFragment,
    machine: WorkOrderMachine
  ) {
    return costCenter.machines.find(
      (ccm) => ccm.machineId === machine.machine.id
    );
  }

  initMachineMetrics() {
    this.metrics.initMetricFields();

    if (!this.builder.isMachinery && !this.builder.isIrrigation) return;

    this.progress.initTotalProgress(this.get());
  }

  getCostCenterMachineIndex(
    costCenter: WorkOrderCostCenterFragment,
    machine: WorkOrderMachine
  ): number {
    return costCenter.machines.findIndex(
      (ccm) => ccm.machineId === machine.machine.id
    );
  }

  useOdometer(machine?: WorkOrderMachine) {
    const machines = machine ? [machine] : this.get();

    return machines.some(
      (m) =>
        m.machine.odometerUnit &&
        m.machine.odometerValue &&
        m.machine.kind.category !== MachineCategory.Portables
    );
  }

  useInstructions(machine?: WorkOrderMachine) {
    const machines = machine ? [machine] : this.get();

    return machines.some((m) =>
      m.machine.calibrations?.some((c) => ["speed", "gear", "rpm"].includes(c))
    );
  }

  useApplicator(machine?: WorkOrderMachine) {
    const machines = machine ? [machine] : this.get();

    return machines.some((m) =>
      m.machine.calibrations?.some((c) => ["pressure", "nozzle"].includes(c))
    );
  }

  onRemove(machine: WorkOrderMachine) {
    super.onRemove(machine);

    const costCenters = this.getCostCenters();
    costCenters.forEach(({ machines }) => {
      const index = machines.findIndex(
        (m) => m.machineId === machine.machine.id
      );
      if (index >= 0) machines.splice(index, 1);
    });

    this.progress.onWorkerCountChanged();
  }

  get useCostCenterProgress() {
    if (!this.builder.isMachinery) return false;

    return this.get().length === 0;
  }

  showCalibration(
    machine: WorkOrderMachine,
    calibration: (typeof MachineCalibrations)[number]
  ) {
    return (
      machine[calibration] ||
      machine.machine.calibrations?.some((c) => c === calibration)
    );
  }

  getCropFieldMachineHoursField(
    machine: WorkOrderMachine,
    cropField: Pick<CropFieldFragment, "id">
  ) {
    const costCenter = this.builder.costCenters.getByCropField(cropField);

    if (!costCenter) return null;

    return [
      "costCenters",
      this.builder.costCenters.indexOf(costCenter),
      "machines",
      this.builder.machines.getCostCenterMachineIndex(costCenter, machine),
      "hours",
    ];
  }

  updateCropFieldMachineHours(
    machine: WorkOrderMachine,
    hours?: number | null
  ) {
    machine.machine.cropFieldMachines.forEach((cfm) => {
      const field = this.getCropFieldMachineHoursField(machine, cfm.cropField);
      if (field) this.form.setFieldValue(field, hours);
    });
  }

  filteredCropFieldMachines(machine: WorkOrderMachine) {
    return machine.machine.cropFieldMachines.filter((cfm) =>
      this.builder.costCenters.getByCropField(cfm.cropField)
    );
  }

  getCapacityUsed(index: number, cropFieldMachine?: CropFieldMachineFragment) {
    const machine = this.getBy(index);
    const cropFieldMachines = cropFieldMachine
      ? [cropFieldMachine]
      : this.filteredCropFieldMachines(machine);

    const used = cropFieldMachines.flatMap((cfm) => {
      const costCenter = this.builder.costCenters.getByCropField(cfm.cropField);

      const hours =
        (costCenter && this.getCostCenterMachine(costCenter, machine)?.hours) ||
        0;

      return hours * (cfm.capacityValue || machine.machine.capacityValue || 0);
    });

    const conversionFactor =
      (machine.machine.capacityTime || 1) *
      (machine.machine.capacityTimeUnit?.conversionFactor || 1);

    return sum(used) / conversionFactor;
  }

  onRemoveCropField(cropField: CropFieldFragment) {
    const costCenter = this.builder.costCenters.getByCropField(cropField);

    if (costCenter) this.builder.costCenters.onRemove(costCenter);
  }
}
