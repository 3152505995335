import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderBuilder } from "..";
import { SectionProps } from "../../../../shared";
import { WorkOrderGenericCostCenters } from "../../costCenters/WorkOrderCostCenters";

export type ProgressMode = "group" | "individual";

export function createCostCenterSection({
  name,
  title,
  description,
  ccTypeText,
  icon,
  render,
  visible = true,
  builder,
}: {
  name: JSX.Element;
  title: JSX.Element;
  description: JSX.Element;
  ccTypeText: JSX.Element;
  icon: JSX.Element;
  visible?: boolean;
  render: (byPercentage: boolean) => JSX.Element;
  builder: WorkOrderBuilder;
}): SectionProps {
  const views = [];

  const values = {
    ccType: ccTypeText,
    laborType: builder.isMachinery ? (
      <FormattedMessage
        id="workOrders.costCenters.description.machine"
        defaultMessage="machine"
      />
    ) : (
      <FormattedMessage
        id="workOrders.costCenters.description.employee"
        defaultMessage="employee"
      />
    ),
    laborGender: builder.isMachinery ? (
      <FormattedMessage
        id="workOrders.costCenters.description.laborTypeMale"
        defaultMessage="del"
      />
    ) : (
      <FormattedMessage
        id="workOrders.costCenters.description.laborTypeFemale"
        defaultMessage="de la"
      />
    ),
  };

  if (builder.isInProgress) {
    views.push({
      title,
      description: builder.allowProgress ? (
        builder.progressByIndividual ? (
          <FormattedMessage
            id="workOrders.costCenters.description.progress.distribute"
            defaultMessage="progress"
            values={values}
          />
        ) : (
          <FormattedMessage
            id="workOrders.costCenters.description.progress"
            defaultMessage="progress"
            values={values}
          />
        )
      ) : (
        <FormattedMessage
          id="workOrders.costCenters.description.hours"
          defaultMessage="hours"
          values={values}
        />
      ),
      menuItem: builder.allowProgress ? (
        <FormattedMessage id="progress" />
      ) : (
        <FormattedMessage id="hours" />
      ),
      render: () => render(false),
    });

    if (!builder.allowProgress || builder.progressByIndividual) {
      views.push({
        title,
        description: builder.allowProgress ? (
          <FormattedMessage
            id="workOrders.costCenters.description.progress.distributePercent"
            defaultMessage="progress"
            values={values}
          />
        ) : (
          <FormattedMessage
            id="workOrders.costCenters.description.hours.distribute"
            defaultMessage="hours"
            values={values}
          />
        ),
        menuItem: <FormattedMessage id="percent" />,
        render: () => render(true),
      });
    }
  } else {
    views.push({
      title,
      description,
      render: () => render(false),
    });
  }

  return {
    key: "cost-centers",
    visible,
    name,
    icon,
    views,
    currentViewIndex: 0,
    disallowRemove: true,
  };
}

export function CostCenters(builder: WorkOrderBuilder) {
  return createCostCenterSection({
    name: (
      <FormattedMessage
        id="workOrders.costCenters"
        defaultMessage="costCenters"
      />
    ),
    title: (
      <FormattedMessage
        id="workOrders.costCenters"
        defaultMessage="costCenters"
      />
    ),
    description: (
      <FormattedMessage
        id="workOrders.costCenters.hint"
        defaultMessage="hint"
      />
    ),
    ccTypeText: (
      <FormattedMessage
        id="costCenters.entityName.lower"
        defaultMessage="cost center"
      />
    ),
    icon: (
      <Icon component={() => <img src="/images/icons/cost-center.svg" />} />
    ),
    render: (byPercentage) => (
      <WorkOrderGenericCostCenters byPercentage={byPercentage} />
    ),
    builder,
  });
}
