import {
  FinanceOrderDetailsFragment,
  FinanceOrderLineItemCostCenterFragment,
  FinanceOrderLineItemFragment,
  HasProfitable,
} from "../../lib/graphql";
import { TableInput } from "../shared";
import { FormattedMessage } from "react-intl";
import {
  formatDetailsLink,
  formatMoneyAmount,
  formatCostCenterName,
  formatPlaceholder,
  formatExpenseItem,
  formatAccountName,
  BLANK_SYMBOL,
  formatPercent,
  roundUnit,
} from "../../lib/formats";
import routes from "../../lib/routes";
import { Form, InputNumber, RuleBuilder, Rules, useFormContext } from "../form";
import { Button, Col, Input, Row } from "antd";
import { CostCenterProfitableSelect } from "./CostCenterSelect";
import {
  useCurrentUser,
  useExpenseItemOptions,
  usePermissions,
} from "../../lib/hooks";
import { ColumnsType } from "antd/lib/table";
import { PlusOutlined } from "@ant-design/icons";
import { Key, useCallback, useState } from "react";
import { useEffectOnce } from "react-use";
import { sumBy } from "lodash";

interface FinanceOrderItemsTableProps {
  order: FinanceOrderDetailsFragment;
  disabled?: boolean;
}

export function FinanceOrderItemsTable({
  order,
  disabled,
}: FinanceOrderItemsTableProps) {
  const { currentTenant } = useCurrentUser();
  const { form } = useFormContext();
  const permissions = usePermissions((s) => s.account);
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>(
    order.lineItems.map((i) => i.expenseItem.id)
  );

  const recalculateAmounts = useCallback(
    (itemIndex: number) => {
      const name = ["lineItems", itemIndex];
      const item: FinanceOrderLineItemFragment = form.getFieldValue(name);

      item.costCenters.forEach((cc: any, index) => {
        form.setFieldValue(
          name.concat("costCenters", index, "amount"),
          roundUnit((item.amount * cc.percent) / 100.0)
        );
      });
    },
    [form]
  );

  const recalculatePercents = useCallback(
    (itemIndex: number) => {
      const name = ["lineItems", itemIndex];
      const item: FinanceOrderLineItemFragment = form.getFieldValue(name);

      item.costCenters.forEach((cc, index) => {
        form.setFieldValue(
          name.concat("costCenters", index, "percent"),
          roundUnit((100 * cc.amount) / item.amount)
        );
      });
    },
    [form]
  );

  const recalculateTotalAmount = useCallback(
    (itemIndex: number) => {
      const name = ["lineItems", itemIndex];
      const item: FinanceOrderLineItemFragment = form.getFieldValue(name);

      form.setFieldValue(
        name.concat("amount"),
        roundUnit(sumBy(item.costCenters, "amount"))
      );
    },
    [form]
  );

  useEffectOnce(() => {
    if (!disabled) {
      order.lineItems.forEach((item, itemIndex) =>
        item.costCenters.forEach((cc, index) => {
          form.setFieldValue(
            ["lineItems", itemIndex, "costCenters", index, "percent"],
            roundUnit((100 * cc.amount) / item.amount)
          );
        })
      );
    }
  });

  const columns: ColumnsType<FinanceOrderLineItemFragment> = [
    {
      title: <FormattedMessage id="expenseItems.entityName" />,
      render: (_, i) => (
        <div>
          {formatDetailsLink({
            id: i.expenseItem.id,
            route: routes.finance.expenseItems.details,
            title: formatExpenseItem(i.expenseItem),
          })}
          <div>
            {currentTenant.features.accounting &&
              permissions?.write &&
              (i.expenseItem.expenseCategory.expenseAccount
                ? formatAccountName(
                    i.expenseItem.expenseCategory.expenseAccount
                  )
                : BLANK_SYMBOL)}
          </div>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="amount" defaultMessage="amount" />,
      width: 190,
      render: (_, i, index) => {
        if (disabled) return formatMoneyAmount(i.amount, order.currencyCode);

        return (
          <Form.Item
            name={["lineItems", index, "amount"]}
            compact
            rules={[Rules.gtZero]}
          >
            <InputNumber
              step={0.01}
              addonBefore={order.currency?.symbol}
              onChange={() => recalculateAmounts(index)}
            />
          </Form.Item>
        );
      },
    },
    {
      title: <FormattedMessage id="description" defaultMessage="description" />,
      render: (_, i, index) => {
        if (disabled) return i.description;

        return (
          <Form.Item name={["lineItems", index, "description"]} compact>
            <Input />
          </Form.Item>
        );
      },
    },
  ];

  if (!disabled) {
    columns.splice(1, 0, {
      title: <FormattedMessage id="costCenters" />,
      width: "35rem",
      render: (_, item, index) => {
        return (
          <>
            <Row gutter={4} wrap={false} align="bottom">
              <Col flex={1}>
                <CostCenterProfitableSelect
                  name={["lineItems", index, "newCostCenters"]}
                  placeholder={formatPlaceholder({ id: "costCenters" })}
                  multiple
                />
              </Col>
              <Col>
                <Button
                  icon={<PlusOutlined />}
                  block
                  onClick={() => {
                    const newName = ["lineItems", index, "newCostCenters"];
                    const name = ["lineItems", index, "costCenters"];
                    const costCenters: HasProfitable[] =
                      form.getFieldValue(newName) || [];
                    const currentCostCenters: HasProfitable[] =
                      form.getFieldValue(name) || [];

                    if (!item.costCenters.length) {
                      costCenters.forEach(
                        (cc: any) =>
                          (cc.percent = roundUnit(100 / costCenters.length))
                      );
                    }

                    form.setFieldValue(name, [
                      ...currentCostCenters,
                      ...costCenters.filter(
                        (cc) =>
                          !currentCostCenters.some(
                            (ccc) =>
                              ccc.costCenter.id == cc.costCenter.id &&
                              ccc.profitableId == cc.profitableId &&
                              ccc.profitableType == cc.profitableType
                          )
                      ),
                    ]);

                    form.setFieldValue(newName, null);

                    setExpandedRowKeys([
                      ...expandedRowKeys,
                      item.expenseItem.id,
                    ]);

                    recalculateAmounts(index);
                  }}
                >
                  <span className="hide-xs">
                    <FormattedMessage id="add" defaultMessage="add" />
                  </span>
                </Button>
              </Col>
            </Row>
            <Form.Item
              name={["lineItems", index, "costCenters"]}
              compact
              rules={[
                RuleBuilder.custom(() => {
                  const lineItem = form.getFieldValue(["lineItems", index]);

                  if (!Form.undestroyed(lineItem)) return true;
                  if (
                    Array.isArray(lineItem.costCenters) &&
                    lineItem.costCenters.filter(Form.undestroyed).length
                  )
                    return true;

                  return <FormattedMessage id="form.validation.presence" />;
                }),
              ]}
            >
              <div />
            </Form.Item>
          </>
        );
      },
    });
  }

  const costCenterColumns = (
    item: FinanceOrderLineItemFragment,
    itemIndex: number
  ) => {
    const columns: ColumnsType<FinanceOrderLineItemCostCenterFragment> = [
      {
        title: <FormattedMessage id="costCenters.entityName" />,
        render: (_, cc) => {
          return formatCostCenterName(cc.costCenter, cc.profitableName);
        },
      },
      {
        title: <FormattedMessage id="percent" />,
        width: 140,
        render: (_, cc, index) => {
          if (disabled || !Form.undestroyed(cc))
            return formatPercent(cc.amount / item.amount);

          return (
            <Form.Item
              name={["lineItems", itemIndex, "costCenters", index, "percent"]}
              compact
              validateTrigger="onBlur"
              rules={[
                RuleBuilder.custom(() => {
                  const costCenters = form
                    .getFieldValue(["lineItems", itemIndex, "costCenters"])
                    .filter(Form.undestroyed);

                  if (index != costCenters.length - 1) return true;

                  const totalPercentage = roundUnit(
                    sumBy(costCenters, "percent"),
                    10
                  );

                  if (totalPercentage == 100) return true;

                  return (
                    <FormattedMessage
                      id="form.validation.mustSumTo"
                      defaultMessage="mustSumTo"
                      values={{
                        max: 100,
                        now: totalPercentage,
                      }}
                    />
                  );
                }),
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                addonAfter="%"
                onChange={() => recalculateAmounts(itemIndex)}
              />
            </Form.Item>
          );
        },
      },
      {
        title: <FormattedMessage id="amount" defaultMessage="amount" />,
        width: 190,
        render: (_, cc, index) => {
          if (disabled) return formatMoneyAmount(cc.amount, order.currencyCode);

          return (
            <Form.Item
              name={["lineItems", itemIndex, "costCenters", index, "amount"]}
              compact
              rules={[Rules.gtZero]}
            >
              <InputNumber
                step={0.01}
                addonBefore={order.currency?.symbol}
                onChange={() => {
                  recalculateTotalAmount(itemIndex);
                  recalculatePercents(itemIndex);
                }}
              />
            </Form.Item>
          );
        },
      },
    ];
    return columns;
  };

  return (
    <TableInput
      name="lineItems"
      disabled={disabled}
      dataSource={order.lineItems}
      rowKey={(i) => i.expenseItem.id}
      tableSelectProps={{
        mode: "multiple",
        showGroups: true,
        placeholder: formatPlaceholder({ id: "expenseItems.entityName" }),
        optionsHook: useExpenseItemOptions,
        entityById: (_, { expenseItem }) =>
          expenseItem && {
            id: "",
            expenseItem,
            costCenters: [],
          },
      }}
      onRemove={(li) =>
        setExpandedRowKeys(
          expandedRowKeys.filter((r) => r !== li.expenseItem.id)
        )
      }
      addIndexColumn
      columns={columns}
      tableProps={{
        size: "small",
        expandable: {
          defaultExpandAllRows: true,
          expandedRowKeys,
          onExpandedRowsChange: (expandedKeys) =>
            setExpandedRowKeys(expandedKeys),
          expandedRowRender: (item, itemIndex) => (
            <TableInput
              name={["lineItems", itemIndex, "costCenters"]}
              dataSource={item.costCenters}
              disabled={disabled}
              rowKey={(cc) => cc.costCenter.id + cc.profitableId || cc.id}
              columns={costCenterColumns(item, itemIndex)}
              onRemove={() => recalculateAmounts(itemIndex)}
            />
          ),
        },
      }}
    />
  );
}
