import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderSummary } from "../../WorkOrderSummary";

export function Summary() {
  return {
    key: "summary",
    visible: true,
    icon: <Icon component={() => <img src="/images/icons/distribute.svg" />} />,
    name: <FormattedMessage id="summary" />,
    views: [
      {
        title: <FormattedMessage id="summary" />,
        render: () => <WorkOrderSummary />,
      },
    ],
    disallowRemove: true,
  };
}
