import { SelectFieldProps, SelectField } from "../../form";
import { useCropFieldOptions } from "../../../lib/hooks";
import { FormattedMessage } from "react-intl";
import { CropFieldsFilterInput } from "../../../lib/graphql";

export function CropFieldSelect({
  filter,
  ...props
}: SelectFieldProps & { filter?: CropFieldsFilterInput }) {
  return (
    <SelectField
      placeholder={
        <FormattedMessage
          id="select.cropField"
          defaultMessage="select.cropField"
        />
      }
      optionsHook={useCropFieldOptions}
      optionsHookParams={{ variables: { filter } }}
      showGroups
      style={{ width: "100%" }}
      {...props}
    />
  );
}
