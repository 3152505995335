import React from "react";
import { Form, SelectField } from "../../form";
import { useUnitOptions } from "../../../lib/hooks";
import { UnitType } from "../../../lib/graphql";
import { FormattedMessage } from "react-intl";
import { Input, InputNumber } from "antd";

export function ConversionInput({ unitType }: { unitType?: UnitType | null }) {
  const { unitOptions, loadingUnitOptions } = useUnitOptions({
    primary: true,
    types: unitType ? [unitType] : undefined,
  });

  return (
    <Input.Group compact>
      <Form.Item name="conversionFactor" noStyle>
        <InputNumber
          name="conversionFactor"
          min={0}
          step={0.1}
          style={{ width: "30%" }}
          placeholder="Factor"
        />
      </Form.Item>
      <Form.Item name="conversionUnitId" noStyle>
        <SelectField
          style={{ width: "70%" }}
          placeholder={<FormattedMessage id="units.entityName" />}
          options={unitOptions}
          loading={loadingUnitOptions}
          showGroups
        />
      </Form.Item>
    </Input.Group>
  );
}
