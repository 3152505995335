import { FormattedMessage } from "react-intl";
import { TaxPlanKind } from "../../../lib/graphql";
import { useTaxPlanOptions } from "../../../lib/hooks";
import { SelectField, SelectFieldProps } from "../../form";

export function TaxPlanSelect({
  kind,
  ...props
}: { kind?: TaxPlanKind } & SelectFieldProps) {
  return (
    <SelectField
      placeholder={
        <FormattedMessage
          id="taxPlans.noTaxPlan"
          defaultMessage="No Tax Plan"
        />
      }
      optionsHook={useTaxPlanOptions}
      optionsHookParams={{
        variables: { filter: { kind } },
      }}
      {...props}
    />
  );
}
