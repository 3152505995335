import { FormattedMessage, useIntl } from "react-intl";
import { LocaleInfo, locales, useCurrentLocale } from "../../../lib/hooks";
import { Divider, Input } from "antd";
import { Form } from "../../form";

interface TranslationsInputProps {
  render(locale: LocaleInfo): React.ReactNode;
}

export default function TranslationsInput({ render }: TranslationsInputProps) {
  const { currentLocale } = useCurrentLocale();

  return (
    <>
      <Divider style={{ fontSize: "14px", fontWeight: 400 }}>
        <FormattedMessage id="translate" defaultMessage="translate" />
      </Divider>

      <div
        style={{
          padding: "4px 8px",
          background: "#efefef",
          borderRadius: "4px",
          marginBottom: "16px",
        }}
      >
        {Object.entries(locales).map(
          ([locale, value]) =>
            locale !== currentLocale && <div key={locale}>{render(value)}</div>
        )}
      </div>
    </>
  );
}

function NameTranslationInput({ locale }: { locale: LocaleInfo }) {
  const intl = useIntl();
  return (
    <Form.Item noStyle name={`name${locale.suffix}`}>
      <Input
        placeholder={intl.formatMessage({ id: "name" })}
        prefix={locale.emoji}
        style={{ margin: "4px 0" }}
      />
    </Form.Item>
  );
}

function DescriptionTranslationInput({ locale }: { locale: LocaleInfo }) {
  const intl = useIntl();
  return (
    <Form.Item noStyle name={`description${locale.suffix}`}>
      <Input.TextArea
        placeholder={intl.formatMessage({ id: "description" })}
        prefix={locale.emoji}
        style={{ margin: "4px 0" }}
      />
    </Form.Item>
  );
}

TranslationsInput.NameInput = NameTranslationInput;
TranslationsInput.DescriptionInput = DescriptionTranslationInput;

TranslationsInput.renderName = () => (
  <TranslationsInput
    key="translations"
    render={(locale) => <NameTranslationInput locale={locale} />}
  />
);
