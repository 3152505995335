import React from "react";
import { BottomPanel, MenuContent } from "../../shared";
import { FormattedMessage } from "react-intl";
import {
  CropFieldDetailsFragment,
  CropFieldVarietyFragment,
} from "../../../lib/graphql";
import { SaveIDFunction, useCurrentUser, Destroy } from "../../../lib/hooks";
import { Form } from "../../form";
import { Row, Col, Radio } from "antd";
import routes, { routerPush } from "../../../lib/routes";
import { CropFieldGroupSidebar } from "../cropFieldGroups";
import { CropFieldBasicForm } from "./CropFieldBasicForm";

type CropFieldFormValues = Pick<
  CropFieldDetailsFragment,
  "name" | "irrigated" | "altitude" | "geoJson" | "tags" | "group"
> & {
  id?: string;
  localityId: string;
  cropId: string;
  cropStageId: string;
  totalArea?: number;
  cropFieldVarieties: Destroy<CropFieldVarietyFragment>[];
};

interface CropFieldFormProps {
  cropField?: CropFieldDetailsFragment;
  onSave(values: CropFieldFormValues): SaveIDFunction;
}

export function CropFieldForm({ cropField, onSave }: CropFieldFormProps) {
  const [form] = Form.useForm();
  const { currentLocality, currentCropCycle } = useCurrentUser();

  return (
    <Form
      preventLeaving
      layout="vertical"
      form={form}
      initialValues={{
        id: cropField?.id,
        name: cropField?.name || "",
        internalId: cropField?.internalId,
        effectivePlants: cropField?.effectivePlants,
        plantDensity: cropField?.plantDensity,
        totalArea: cropField?.totalArea,
        altitude: cropField?.altitude,
        geoJson: cropField?.geoJson,
        tags: cropField?.tags,
        group: cropField?.group,
        irrigated: cropField?.irrigated || true,
        cropId: cropField?.id
          ? cropField.crop.id
          : currentCropCycle?.crops[0].id || "",
        cropStageId: cropField?.cropStage.id || "",
        localityId: cropField?.locality.id || currentLocality?.id || "",
        cropFieldVarieties: cropField?.cropFieldVarieties || [],
        costCenterId: cropField?.costCenter?.id,
      }}
      onSubmit={(field, { setSubmitting, showErrors }) => {
        if (!field.effectivePlants && field.totalArea && field.plantDensity) {
          field.effectivePlants = field.totalArea * field.plantDensity;
        }

        const promise = onSave(field);
        if (!promise) return;

        promise.then((f) => {
          if (!f) return;

          if (f.result) {
            routerPush(f.result.id, routes.agro.cropFields.details);
          } else if (f.errors) {
            showErrors(f.errors);
            setSubmitting(false);
          }
        });
      }}
    >
      <MenuContent
        items={{
          basic: {
            title: <FormattedMessage id="basicInfo" />,
            render: () => (
              <CropFieldBasicForm cropField={cropField} form={form} />
            ),
          },
          attributes: {
            title: (
              <FormattedMessage
                id="cropFields.attributes"
                defaultMessage="attributes"
              />
            ),
            disabled: !cropField,
            render: () => (
              <Row gutter={32}>
                <Col xs={24} md={8}>
                  <Form.Item
                    name="irrigated"
                    label={<FormattedMessage id="cropFields.isIrrigated" />}
                  >
                    <Radio.Group
                      options={[
                        {
                          label: (
                            <FormattedMessage
                              id="cropFields.irrigated"
                              defaultMessage="irrigated"
                            />
                          ),
                          value: true,
                        },
                        {
                          label: (
                            <FormattedMessage
                              id="cropFields.notIrrigated"
                              defaultMessage="notIrrigated"
                            />
                          ),
                          value: false,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ),
          },
        }}
      />

      <CropFieldGroupSidebar
        onSave={(name) => {
          form.setFields([{ name: "group", value: name }]);
        }}
      />

      <BottomPanel
        buttons={[
          BottomPanel.CancelButton({
            route: routes.agro.cropFields.index,
          }),
          BottomPanel.SubmitButton(),
        ]}
      />
    </Form>
  );
}
