import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  OnboardingSeedActivitiesDocument,
  OnboardingSeedActivitiesMutation,
  OnboardingSeedActivitiesMutationVariables,
  OnboardingSeedItemsDocument,
  OnboardingSeedItemsMutation,
  OnboardingSeedItemsMutationVariables,
  OnboardingSeedSalaryStructuresDocument,
  OnboardingSeedSalaryStructuresMutation,
  OnboardingSeedSalaryStructuresMutationVariables,
  OnboardingSeedsActivitiesDocument,
  OnboardingSeedsActivitiesQuery,
  OnboardingSeedsItemsDocument,
  OnboardingSeedsItemsQuery,
  OnboardingSeedsItemsQueryVariables,
  OnboardingSeedsSalaryStructuresDocument,
  OnboardingSeedsSalaryStructuresQuery,
} from "../../graphql";
import { DefaultPayrollInputVariables } from "../hr/salaryStructures";

export function useActivitiesSeeds() {
  const { data, loading } = useQuery<OnboardingSeedsActivitiesQuery>(
    OnboardingSeedsActivitiesDocument
  );

  return { data: data?.onboardingSeedsActivities, loading };
}

export function useSeedActivities(
  options?: MutationHookOptions<
    OnboardingSeedActivitiesMutation,
    OnboardingSeedActivitiesMutationVariables
  >
) {
  return useMutation(OnboardingSeedActivitiesDocument, options);
}

export function useItemsSeeds(
  queryOptions?: QueryHookOptions<
    OnboardingSeedsItemsQuery,
    OnboardingSeedsItemsQueryVariables
  >
) {
  const { data, loading } = useQuery<OnboardingSeedsItemsQuery>(
    OnboardingSeedsItemsDocument,
    queryOptions
  );

  return { data: data?.onboardingSeedsItems, loading };
}

export function useSeedItems(
  options?: MutationHookOptions<
    OnboardingSeedItemsMutation,
    OnboardingSeedItemsMutationVariables
  >
) {
  return useMutation(OnboardingSeedItemsDocument, options);
}

export function useSalaryStructureSeeds() {
  const { data, loading } = useQuery<OnboardingSeedsSalaryStructuresQuery>(
    OnboardingSeedsSalaryStructuresDocument,
    { variables: { inputVariables: DefaultPayrollInputVariables } }
  );

  return { data: data?.onboardingSeedsSalaryStructures, loading };
}

export function useSeedSalaryStructures(
  options?: MutationHookOptions<
    OnboardingSeedSalaryStructuresMutation,
    OnboardingSeedSalaryStructuresMutationVariables
  >
) {
  return useMutation(OnboardingSeedSalaryStructuresDocument, options);
}
