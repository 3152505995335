import React, { useCallback, useState } from "react";
import {
  PlanningDetailsFragment,
  WorkOrderDetailsFragment,
  WorkOrderStatus,
} from "../../../lib/graphql";
import { useCurrentUser } from "../../../lib/hooks";
import { Form } from "../../form";
import { FormInstance } from "antd/lib/form";
import { WorkOrderBuilder, WorkOrderFormValues } from "./builder";
import { SectionProps } from "../../shared";
import { useEffectOnce, useUpdateEffect } from "react-use";

interface WorkOrderContextType {
  workOrder: WorkOrderFormValues;
  builder: WorkOrderBuilder;
  form: FormInstance;
  sections: SectionProps[];
  rebuild: (changes: Partial<WorkOrderFormValues>, full?: boolean) => void;
}

export const WorkOrderContext = React.createContext<WorkOrderContextType>({
  workOrder: {} as WorkOrderFormValues,
  form: {} as FormInstance,
  builder: {} as WorkOrderBuilder,
  sections: [],
  rebuild: () => {},
});

export function WorkOrderContextProvider({
  workOrder,
  planning,
  children,
}: {
  workOrder: WorkOrderDetailsFragment;
  planning?: PlanningDetailsFragment;
  children: React.ReactNode;
}) {
  const [form] = Form.useForm();
  const { user } = useCurrentUser();

  const [builder, setBuilder] = useState(
    () => new WorkOrderBuilder(workOrder, form, user, planning)
  );

  const [sections, setSections] = useState(builder.sections.current);
  builder.sections.onUpdate = () => setSections([...builder.sections.current]);

  const rebuild = useCallback(
    (changes: Partial<WorkOrderDetailsFragment>, full?: boolean) => {
      const newBuilder = new WorkOrderBuilder(
        full ? changes : { ...form.getFieldsValue(true), ...changes },
        form,
        user,
        planning
      );
      if (full) {
        form.setFieldsValue(newBuilder.workOrder);
        newBuilder.init();
      }

      setBuilder(newBuilder);
      setSections(newBuilder.sections.current);
    },
    [form, planning, user]
  );

  useEffectOnce(() => builder.init());
  useUpdateEffect(() => {
    // on complete rebuild sections
    if (workOrder.status == WorkOrderStatus.Completed) rebuild(workOrder, true);
  }, [workOrder.status]);

  return (
    <WorkOrderContext.Provider
      value={{
        form,
        builder,
        workOrder: builder.workOrder,
        sections,
        rebuild,
      }}
    >
      {children}
    </WorkOrderContext.Provider>
  );
}
