import { useLazyQuery } from "@apollo/client";
import {
  SortDirection,
  TagsQuery,
  TagsQueryVariables,
  TagsDocument,
} from "../../graphql";
import { merge } from "lodash";

export function useTagOptions() {
  const [load, { loading, data }] = useLazyQuery<TagsQuery, TagsQueryVariables>(
    TagsDocument,
    {
      variables: { sort: { name: SortDirection.Asc } },
    }
  );

  const options =
    data?.tags?.items.map((g) => ({ key: g.id, label: g.name })) || [];

  const search = (val?: string) => {
    load(
      merge({
        variables: { sort: undefined, filter: { name: { match: val } } },
      })
    );
  };

  return { load, search, loading, options };
}
