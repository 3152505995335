import { FormattedMessage, useIntl } from "react-intl";
import { TransferFragment, TransferStatus } from "../../lib/graphql";
import { useCurrentLocale, useTransferUpdate } from "../../lib/hooks";
import {
  ChangeLogSidebar,
  ConfirmSidebar,
  ItemSidebar,
  RevertSidebar,
} from "../shared";
import { CloneForm } from "./transfers/CloneForm";
import routes from "../../lib/routes";

export function TransferSidebar({
  transfer,
  entityName,
}: {
  transfer: TransferFragment;
  entityName: string;
}) {
  const { showSuccess } = useCurrentLocale();
  const intl = useIntl();
  const [update] = useTransferUpdate();

  return (
    <ItemSidebar
      item={transfer}
      sidebarActions={{
        cancel: ({ closeSidebar }) => (
          <ConfirmSidebar
            title={
              <FormattedMessage
                id="cancel.doc.header"
                defaultMessage="cancel {entityName}"
                values={{ entityName }}
              />
            }
            onCancel={closeSidebar}
            okButtonProps={{ danger: true }}
            okText={
              <FormattedMessage id="cancel.doc" defaultMessage="Cancel" />
            }
            cancelText={
              <FormattedMessage id="cancel.doc.return" defaultMessage="Back" />
            }
            onOk={() =>
              update({
                variables: {
                  id: transfer.id,
                  changes: { status: TransferStatus.Canceled },
                },
              }).then((result) => {
                const updateResult =
                  result.data && result.data.stockTransferUpdate;

                if (updateResult && updateResult.result) {
                  closeSidebar();

                  showSuccess(
                    intl.formatMessage(
                      { id: "cancel.doc.success" },
                      {
                        entityName,
                        id: transfer.id,
                      }
                    )
                  );
                }
              })
            }
          >
            <FormattedMessage
              id="cancel.doc.confirm"
              defaultMessage="confirm"
            />
          </ConfirmSidebar>
        ),
        revert: ({ closeSidebar, setAction }) => (
          <RevertSidebar
            closeSidebar={closeSidebar}
            entityName={entityName}
            entity={transfer}
            revert={() =>
              update({
                variables: {
                  id: transfer.id,
                  changes: { status: TransferStatus.Reverted },
                },
              }).then((result) => {
                setAction("");
                return result.data?.stockTransferUpdate;
              })
            }
          />
        ),
        changeLog: ({ closeSidebar }) => (
          <ChangeLogSidebar
            id={transfer.id}
            type="Inventory::Transfer"
            onClose={closeSidebar}
          />
        ),
        clone: ({ item, closeSidebar }) => (
          <CloneForm
            item={item}
            entityName={entityName}
            entityUrl={
              item.adjustmentType.entryCode == "MV"
                ? routes.inventory.transfers.details
                : routes.inventory.adjustments.details
            }
            onClose={closeSidebar}
          />
        ),
      }}
    />
  );
}
