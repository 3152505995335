import { defineMessages, useIntl } from "react-intl";
import { useContinueOnboardingStep } from "../../../lib/hooks/onboarding";
import ProductTour, { IStep } from "../../shared/ProductTour";

const onboardingMessages = defineMessages({
  newTitle: {
    id: "onboarding.cropCycles.productTour.new.title",
    defaultMessage: "Create a crop cycle",
  },
  newContent: {
    id: "onboarding.cropCycles.productTour.new.content",
    defaultMessage:
      "Click here to create a crop cycle by filling out the form.",
  },
  cropCycleSelectorTitle: {
    id: "onboarding.cropCycles.productTour.selector.title",
    defaultMessage: "Select a crop cycle",
  },
  cropCycleSelectorContent: {
    id: "onboarding.cropCycles.productTour.selector.content",
    defaultMessage: "Change the crop cycle",
  },
});

export function CropCycleProductTour() {
  const intl = useIntl();

  const steps = [
    {
      selector: "[data-new='true']",
      placement: "bottom-left",
      title: intl.formatMessage(onboardingMessages.newTitle),
      content: intl.formatMessage(onboardingMessages.newContent),
    },
    {
      selector: "[data-action='crop-cycle-selector']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.cropCycleSelectorTitle),
      content: intl.formatMessage(onboardingMessages.cropCycleSelectorContent),
    },
  ] as IStep[];

  const { loaded, step: continueOnboardingStep } = useContinueOnboardingStep();

  if (!loaded) return null;

  if (continueOnboardingStep) {
    steps.unshift(continueOnboardingStep);
  }

  return (
    <ProductTour
      hotspot={false}
      localKey="onboarding:crop-cycles"
      steps={steps}
    />
  );
}
