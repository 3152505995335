import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { memoize } from "lodash";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  useFormatMoney,
  formatAccountName,
  BLANK_SYMBOL,
} from "../../../lib/formats";
import { TaxPlanComponentFragment } from "../../../lib/graphql";
import { useFormContext, Rules } from "../../form";
import { TableInputProps, TableInput } from "../../shared";
import { TaxPlanFormValues } from "./TaxPlanForm";

export function TaxPlanComponents({
  plan,
  accounting,
  disabled,
  ...props
}: Omit<
  TableInputProps<TaxPlanComponentFragment>,
  "columns" | "name" | "dataSource"
> & {
  plan: TaxPlanFormValues;
  accounting?: boolean;
}) {
  const { form } = useFormContext();
  const formatMoney = useFormatMoney();
  const [, setUpdate] = useState(0);

  const findSampleResult = memoize((id: string) =>
    plan.sample.find((t) => t.component.id == id)
  );

  const columns: ColumnsType<TaxPlanComponentFragment> = [
    {
      title: <FormattedMessage id="taxComponents.entityName" />,
      render: (_, c) => c.taxComponent.name,
    },
    {
      title: <FormattedMessage id="formula" />,
      width: "25rem",
      render: (_, c) => (
        <Typography.Text code>
          {c.taxComponent.abbr} = {c.formula || c.taxComponent.defaultFormula}
        </Typography.Text>
      ),
    },
    {
      title: (
        <FormattedMessage
          id="taxComponents.includedInPrice"
          defaultMessage="Included In Price"
        />
      ),
      render: (_, c) => (c.includedInPrice ? "✅" : BLANK_SYMBOL),
    },
    {
      title: (
        <FormattedMessage
          id="taxComponents.includeInUnitCost"
          defaultMessage="Include In Unit Cost"
        />
      ),
      render: (_, c) => (c.includeInUnitCost ? "✅" : BLANK_SYMBOL),
    },
    {
      title: (
        <FormattedMessage id="taxPlans.example" defaultMessage="Example" />
      ),
      align: "right",
      render: (_, c) => {
        const result = findSampleResult(c.taxComponent.id);

        if (result?.error)
          return (
            <Typography.Text type="danger">{result.error}</Typography.Text>
          );

        if (result?.value) return formatMoney(result.value);
      },
    },
  ];

  if (accounting) {
    columns.splice(
      1,
      2,
      {
        title: <FormattedMessage id="debit" />,
        width: "20rem",
        render: (_, c) => {
          return (
            c.taxComponent.taxDebitAccount &&
            formatAccountName(c.taxComponent.taxDebitAccount)
          );
        },
      },
      {
        title: <FormattedMessage id="credit" />,
        width: "20rem",
        render: (_, c) => {
          return (
            c.taxComponent.taxCreditAccount &&
            formatAccountName(c.taxComponent.taxCreditAccount)
          );
        },
      }
    );
  }

  return (
    <TableInput
      name="components"
      rules={[Rules.required]}
      dataSource={plan.components}
      rowKey={(c) => c.taxComponent.id}
      addIndexColumn
      disabled={disabled}
      draggable={{
        onDragEnd: ({ active, over }) => {
          const components: TaxPlanComponentFragment[] =
            form.getFieldValue("components");
          const activeIndex = components.findIndex((c) => c.id == active.id);
          const toIndex = components.findIndex((c) => c.id == over?.id);
          components.splice(toIndex, 0, components.splice(activeIndex, 1)[0]);

          form.setFieldValue("components", [...components]);
          setUpdate((val) => val + 1);
        },
      }}
      columns={columns}
      {...props}
    />
  );
}
