import { FormattedMessage, useIntl } from "react-intl";
import { CropSelect } from "..";
import { CropCycleFields } from "../../../lib/graphql";
import {
  evictCache,
  useCropCycleCreate,
  useCurrentUser,
} from "../../../lib/hooks";
import { Rules } from "../../form";
import { NewForm } from "../../shared";

export function NewCropCycleForm({ onClose }: { onClose: () => void }) {
  const intl = useIntl();
  const { currentLocalityId } = useCurrentUser();

  return (
    <NewForm
      entityName={intl.formatMessage({ id: "cropCycles.entityName" })}
      mutation={useCropCycleCreate}
      onMutate={(result) => result.cropCycleCreate}
      mutationOptions={{
        refetchQueries: ["Me"],
        update: evictCache("cropCycles", "onboardingStatus"),
      }}
      formValues={() => ({ localityId: currentLocalityId } as CropCycleFields)}
      onClose={onClose}
      fields={[
        {
          name: "name",
          type: "string",
          rules: [Rules.required],
        },
        {
          label: <FormattedMessage id="crops.entityName" />,
          name: "cropIds",
          type: "custom",
          rules: [Rules.required],
          render: () => <CropSelect mode="multiple" />,
        },
        {
          label: <FormattedMessage id="cropCycles.startedAt" />,
          name: "startedAt",
          type: "date",
          rules: [Rules.required],
        },
        {
          label: <FormattedMessage id="cropCycles.estimatedEndDate" />,
          name: "estimatedEndDate",
          type: "date",
          rules: [Rules.required],
        },
      ]}
    />
  );
}
