import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderBuilder } from "..";
import { WorkOrderCropFields } from "../../WorkOrderCropFields";
import { createCostCenterSection } from "./CostCenters";

export function CropFields(visible: boolean, builder: WorkOrderBuilder) {
  const title = builder.isHarvest ? (
    <FormattedMessage
      id="workOrders.harvestedPerCropField"
      defaultMessage="harvestedPerCropField"
    />
  ) : (
    <FormattedMessage id="workOrders.cropFields" defaultMessage="cropFields" />
  );
  const description = builder.isHarvest ? (
    <FormattedMessage
      id="workOrders.harvestedPerCropField.hint"
      defaultMessage="hint"
    />
  ) : (
    <FormattedMessage id="workOrders.cropFields.hint" defaultMessage="hint" />
  );

  return createCostCenterSection({
    name: (
      <FormattedMessage
        id="workOrders.cropFields"
        defaultMessage="cropFields"
      />
    ),
    title,
    visible,
    description,
    ccTypeText: (
      <FormattedMessage
        id="cropFields.entityName.lower"
        defaultMessage="field"
      />
    ),
    icon: <Icon component={() => <img src="/images/icons/crops.svg" />} />,
    render: (byPercentage) => (
      <WorkOrderCropFields byPercentage={byPercentage} />
    ),
    builder,
  });
}
