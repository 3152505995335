import { WorkOrderCostCenter } from "../costCenters";
import { WorkOrderEmployee } from "../employees";
import { ProgressBuilder } from "./progress";

export class EmployeeProgress extends ProgressBuilder<WorkOrderEmployee> {
  protected get field() {
    return "employees";
  }

  getWorkers() {
    const employees = this.builder.employees.get();

    return employees.filter((e) => this.builder.employees.canSetProgress(e));
  }

  distributeCostCenterProgress(costCenterIndex: number, val?: number | null) {
    super.distributeCostCenterProgress(costCenterIndex, val);

    if (this.builder.workerCountMode)
      this.builder.employees.calculateWorkerCost();
  }

  protected findOrCreateCostCenterProgress(
    costCenter: WorkOrderCostCenter,
    employee: WorkOrderEmployee
  ): number | null {
    const cce = costCenter.employees.find(
      (cce) => cce.employeeId === employee.employee.id
    );

    if (cce) return cce.progress;

    costCenter.employees.push({
      id: "",
      employeeId: employee.employee.id,
      progress: 0,
    });

    return null;
  }

  protected findWorkerIndex(
    costCenter: WorkOrderCostCenter,
    employee: WorkOrderEmployee
  ) {
    return costCenter.employees.findIndex(
      (e) => e.employeeId === employee.employee.id
    );
  }
}
