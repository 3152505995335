import { ExpenseCategoryTreeFragment } from "../../lib/graphql";
import { useExpenseCategories } from "../../lib/hooks";
import { TreeSelectField, TreeSelectFieldProps } from "../form";

export function ExpenseCategorySelect(
  props: Partial<TreeSelectFieldProps<ExpenseCategoryTreeFragment>>
) {
  return (
    <TreeSelectField
      queryHook={useExpenseCategories}
      queryHookParams={(parentCategoryId) => ({
        variables: {
          filter: { parentCategoryId },
          pageSize: 500,
        },
      })}
      buildTreeNode={(item) => ({
        title: item.name,
        pId: item.parentCategory && item.parentCategory.id,
        isLeaf: !item.subcategories || item.subcategories.length === 0,
      })}
      {...props}
    />
  );
}
