import { LockOutlined } from "@ant-design/icons";
import { AutoComplete, FormItemProps, Input, Form as AntForm } from "antd";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  formatEntityNameLabel,
  shouldUpdate,
  translate,
} from "../../lib/formats";
import {
  ItemChanges,
  ItemDetailsFragment,
  ItemFields,
  ItemFragment,
  ItemKind,
  ItemSeedFragment,
  TaxPlanKind,
  UnitType,
} from "../../lib/graphql";
import {
  allowedUnitType,
  useCropVarietyOptions,
  useCurrentUser,
  useDiseasesOptions,
  useEquipmentOptions,
  useItemCategoryOptions,
  useItemKindOptions,
  useToxicityCategoryOptions,
  useUnitOptions,
  useUnitTypeOptions,
} from "../../lib/hooks";
import { useItemsSeeds } from "../../lib/hooks/onboarding/seeds";
import routes from "../../lib/routes";
import { Form, InputNumber, Rules, SelectField } from "../form";
import { ItemCategorySelect } from "../itemCategories";
import { BottomPanel, MenuContent, Popover } from "../shared";
import { ItemFormProps } from "../shared/ListView/ItemForm";
import { ItemIngredientsInput } from "./ItemIngredientsInput";
import { ItemVariantsInput } from "./ItemVariantsInput";
import { useEffectOnce } from "react-use";
import { useWatch } from "antd/lib/form/Form";
import { CropSelect } from "../agro";
import { TaxPlanSelect } from "../finance";
import { UnitSelect } from "../units";
import TagSelect from "../tags/TagSelect";

interface InventoryItemFormProps
  extends Omit<
    ItemFormProps<ItemFragment, ItemFields | ItemChanges>,
    "fields"
  > {
  item?: ItemDetailsFragment;
}

export function InventoryItemForm({
  item,
  initialValues,
  formProps,
  onSave,
  clearOnAction,
}: InventoryItemFormProps) {
  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      preventLeaving
      {...formProps}
      onSubmit={(values, { setSubmitting, form, showErrors }) => {
        onSave(values, form)
          .then((result) => {
            if (!result) return;

            setSubmitting(false);

            if (!result.result) {
              showErrors(result.errors);
            } else if (clearOnAction) {
              form.resetFields();
            }
          })
          .catch(() => setSubmitting(false));
      }}
    >
      <MenuContent
        items={{
          basic: {
            title: <FormattedMessage id="basicInfo" />,
            render: () => <InventoryItemFormFields item={item} />,
          },
          usage: {
            title: <FormattedMessage id="items.usage" />,
            render: () => <InventoryItemUsageFields />,
          },
        }}
      />

      <BottomPanel
        sticky
        buttons={[
          BottomPanel.CancelButton({ route: routes.items.index }),
          BottomPanel.SubmitButton(),
        ]}
      />
    </Form>
  );
}

type ItemOption = {
  label: string;
  value: string;
  item: ItemSeedFragment;
};

export function InventoryItemFormFields({
  item,
  formItemProps = { labelCol: { span: 4 }, wrapperCol: { span: 10 } },
}: {
  item?: ItemDetailsFragment;
  formItemProps?: FormItemProps;
}) {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();
  const { options: unitTypeOptions } = useUnitTypeOptions(true);
  const { unitOptions } = useUnitOptions({});

  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [allowIngredients, setAllowIngredients] = useState(false);

  // preloading item categories to use in category watch
  const { load: loadItemCategories, items: itemCategories } =
    useItemCategoryOptions();

  useEffectOnce(() => {
    loadItemCategories();
  });

  const form = AntForm.useFormInstance();
  // item category can be filled by autocomplete, so we need to watch it
  const itemCategoryId = useWatch("itemCategoryId", form);

  useEffect(() => {
    const category = itemCategories?.find((c) => c.id === itemCategoryId);
    setAllowIngredients(!!category?.allowIngredients);

    if (category?.defaultItemKind && !item?.id) {
      const variationUnitType =
        category.defaultItemKind === ItemKind.Reusable
          ? unitTypeOptions.find((o) => o.key === UnitType.Unitary)?.key
          : null;

      form.setFieldsValue({
        kind: category.defaultItemKind,
        variationUnitType,
      });
    }
  }, [form, itemCategories, itemCategoryId, unitTypeOptions, item?.id]);

  const disableUnitKindChange = item && !item.mayUpdateUnitKind;

  const onSearch = (value: string) => {
    setShowAutocomplete(!!value && value.length > 1);
  };

  const onVariationUnitTypeChange = useCallback(
    (unitType: UnitType) => {
      const primaryUnit = unitOptions.find(
        (u) => u.default && u.unit.unitType === unitType
      )?.unit;
      if (primaryUnit) {
        form.setFieldValue(
          ["variantsAttributes", 0, "variationUnitId"],
          primaryUnit.id
        );
      }
    },
    [form, unitOptions]
  );

  const { data } = useItemsSeeds({ variables: { pageSize: 10000 } });

  return (
    <>
      <Form.Item noStyle dependencies={["name"]}>
        {({ setFields }) => (
          <Form.Item
            label={formatEntityNameLabel(
              <FormattedMessage id="items.entityName" defaultMessage="item" />
            )}
            name="name"
            rules={[Rules.required]}
            {...formItemProps}
          >
            <AutoComplete
              allowClear
              onSearch={onSearch}
              filterOption={(inputValue, option) =>
                option?.label
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onSelect={(_: any, option: ItemOption) => {
                setFields([
                  { name: "name", value: option.label },
                  {
                    name: "itemCategoryId",
                    value: option.item.itemCategory.id,
                  },
                  { name: "kind", value: ItemKind.Consumable },
                  {
                    name: "variationUnitType",
                    value: option.item.variationUnitType,
                  },
                  {
                    name: "itemIngredientsAttributes",
                    value: option.item.itemIngredients.map(
                      (itemIngredient) => ({
                        ingredientId: itemIngredient.ingredient.id,
                        rate: itemIngredient.rate,
                      })
                    ),
                  },
                ]);

                onVariationUnitTypeChange(option.item.variationUnitType);
              }}
              options={
                showAutocomplete
                  ? data?.items.map((item) => ({
                      label: item.name,
                      value: item.id,
                      item,
                    }))
                  : []
              }
            />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="items.itemCategory" />}
        name="itemCategoryId"
        rules={[Rules.required]}
        {...formItemProps}
      >
        <ItemCategorySelect
          autoExpand={false}
          queryHook={() => ({
            items: itemCategories,
          })}
          placeholder={
            <FormattedMessage
              id="select.name"
              values={{
                name: intl.formatMessage({
                  id: "itemCategories.entityName",
                }),
              }}
            />
          }
        />
      </Form.Item>

      {(currentTenant.features.reusableItems ||
        currentTenant.features.advancedActivities) && (
        <Form.Item
          key="kind"
          noStyle
          shouldUpdate={shouldUpdate(["itemCategoryId"])}
        >
          {({ setFields, getFieldValue }) => {
            if (!getFieldValue("itemCategoryId")) return;

            const kind = getFieldValue("kind");

            return (
              <Popover
                readonly={!disableUnitKindChange}
                content={
                  <FormattedMessage
                    id="items.blockedMessageForItemKind"
                    defaultMessage="blockedMessageForItemKind"
                  />
                }
              >
                <Form.Item
                  label={<FormattedMessage id="items.kind" />}
                  name="kind"
                  rules={[Rules.required]}
                  {...formItemProps}
                  extra={kind && translate(`items.kindHints.${kind}`)}
                >
                  <SelectField
                    optionsHook={useItemKindOptions}
                    disabled={disableUnitKindChange}
                    suffixIcon={disableUnitKindChange && <LockOutlined />}
                    placeholder={
                      <FormattedMessage
                        id="select.name"
                        values={{
                          name: intl.formatMessage({ id: "items.kind" }),
                        }}
                      />
                    }
                    onChange={() => {
                      setFields([
                        {
                          name: "variationUnitType",
                          value:
                            getFieldValue("kind") === ItemKind.Reusable
                              ? unitTypeOptions.find(
                                  (o) => o.key === UnitType.Unitary
                                )?.key
                              : null,
                        },
                        {
                          name: ["itemIngredientsAttributes"],
                          value: [],
                        },
                        {
                          name: ["variantsAttributes", 0, "variationValue"],
                          value: 1,
                        },
                        {
                          name: ["variantsAttributes", 0, "variationUnitId"],
                          value:
                            getFieldValue("kind") === ItemKind.Reusable
                              ? unitOptions.find(
                                  (o) => o.type === UnitType.Unitary
                                )?.key
                              : null,
                        },
                      ]);
                    }}
                  />
                </Form.Item>
              </Popover>
            );
          }}
        </Form.Item>
      )}

      <Form.Item
        key="variationUnit"
        noStyle
        shouldUpdate={shouldUpdate("kind", "itemCategoryId")}
      >
        {({ getFieldValue }) => {
          {
            if (!getFieldValue("itemCategoryId") || !getFieldValue("kind"))
              return;

            const displayAllUnitTypes =
              getFieldValue("kind") === ItemKind.Reusable;

            if (displayAllUnitTypes) {
              return (
                <Popover
                  trigger="hover"
                  content={
                    <FormattedMessage
                      id="items.blockedMessage"
                      defaultMessage="blockedMessage"
                      values={{
                        itemType:
                          getFieldValue("kind")[0].toUpperCase() +
                          getFieldValue("kind").slice(1),
                      }}
                    />
                  }
                >
                  <Form.Item
                    label={<FormattedMessage id="items.variationUnitType" />}
                    name="variationUnitType"
                    rules={[Rules.required]}
                    {...formItemProps}
                  >
                    <SelectField
                      disabled={displayAllUnitTypes}
                      options={unitTypeOptions.filter(
                        (u) => u.key === UnitType.Unitary
                      )}
                      suffixIcon={displayAllUnitTypes ? <LockOutlined /> : null}
                      onChange={onVariationUnitTypeChange}
                    />
                  </Form.Item>
                </Popover>
              );
            } else {
              return (
                <Popover
                  readonly={!disableUnitKindChange}
                  content={
                    <FormattedMessage
                      id="items.blockedMessageForItemUnitType"
                      defaultMessage="blockedMessageForItemUnitType"
                    />
                  }
                >
                  <Form.Item
                    label={<FormattedMessage id="items.variationUnitType" />}
                    name="variationUnitType"
                    rules={[Rules.required]}
                    {...formItemProps}
                  >
                    <SelectField
                      disabled={disableUnitKindChange}
                      suffixIcon={disableUnitKindChange && <LockOutlined />}
                      options={unitTypeOptions.filter((u) =>
                        allowedUnitType(u.key as UnitType)
                      )}
                      onChange={onVariationUnitTypeChange}
                      placeholder={
                        <FormattedMessage
                          id="select.name"
                          values={{
                            name: intl.formatMessage({
                              id: "items.variationUnitType",
                            }),
                          }}
                        />
                      }
                    />
                  </Form.Item>
                </Popover>
              );
            }
          }
        }}
      </Form.Item>

      <Form.Item key="crop" noStyle shouldUpdate>
        {({ getFieldValue }) => {
          if (getFieldValue("kind") !== ItemKind.Producible) {
            return null;
          }

          return (
            <Form.Item
              label={<FormattedMessage id="crops.entityName" />}
              name="cropId"
              {...formItemProps}
            >
              <CropSelect />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item key="itemCropVarieties" noStyle shouldUpdate>
        {({ getFieldValue }) => {
          if (
            getFieldValue("kind") !== ItemKind.Producible ||
            !getFieldValue("cropId")
          ) {
            return null;
          }

          return (
            <Form.Item
              label={<FormattedMessage id="cropVarieties" />}
              {...formItemProps}
            >
              <SelectField
                defaultValue={item?.itemCropVarieties.map((v) => ({
                  label: v.cropVariety.name,
                  value: v.cropVariety.id,
                }))}
                mode="tags"
                optionsHook={useCropVarietyOptions}
                optionsHookParams={{
                  variables: { filter: { cropId: getFieldValue("cropId") } },
                }}
                onChange={(value: string[]) => {
                  const newValue = [];

                  for (const id of value) {
                    if (
                      !item?.itemCropVarieties.find(
                        (v) => v.cropVariety.id === id
                      )
                    ) {
                      newValue.push({ cropVarietyId: id });
                    }
                  }

                  for (const existing of item?.itemCropVarieties || []) {
                    if (
                      !newValue.find((v) => v.cropVarietyId === existing.id)
                    ) {
                      newValue.push({
                        id: existing.id,
                        _destroy: !value.find(
                          (v) => v === existing.cropVariety.id
                        ),
                      });
                    }
                  }

                  form.setFieldsValue({
                    itemCropVarietiesAttributes: newValue,
                  });
                }}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <ItemVariantsInput
        key="variants"
        name={["variantsAttributes"]}
        formItemProps={formItemProps}
        item={item}
      />

      <Form.Item key="itemIngredients" noStyle shouldUpdate>
        {({ getFieldValue }) => {
          if (
            !getFieldValue("itemCategoryId") ||
            getFieldValue("kind") !== ItemKind.Consumable ||
            !allowIngredients
          ) {
            return null;
          }

          return (
            <Form.Item
              label={<FormattedMessage id="items.ingredients" />}
              name="itemIngredients"
              {...formItemProps}
            >
              <ItemIngredientsInput name={["itemIngredientsAttributes"]} />
            </Form.Item>
          );
        }}
      </Form.Item>

      {currentTenant.features.taxPlan && (
        <Form.Item
          label={<FormattedMessage id="taxPlans.entityName" />}
          name="taxPlanId"
          {...formItemProps}
        >
          <TaxPlanSelect
            kind={TaxPlanKind.Item}
            placeholder={item?.defaultTaxPlan?.name}
          />
        </Form.Item>
      )}

      <Form.Item key="description" noStyle shouldUpdate>
        {({ getFieldValue }) => {
          if (!getFieldValue("itemCategoryId") || !getFieldValue("kind"))
            return;

          return (
            <Form.Item
              name="description"
              label={<FormattedMessage id="description" />}
              {...formItemProps}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          );
        }}
      </Form.Item>
    </>
  );
}

export function InventoryItemUsageFields() {
  const formItemProps = { wrapperCol: { span: 10 } };

  return (
    <>
      <Form.Item
        name="toxicityCategory"
        label={
          <FormattedMessage id="items.toxicity" defaultMessage="Toxicity" />
        }
        {...formItemProps}
      >
        <SelectField
          optionsHook={useToxicityCategoryOptions}
          showSearch={false}
        />
      </Form.Item>

      <Form.Item
        name="entryPeriod"
        label={
          <FormattedMessage
            id="items.entryPeriod"
            defaultMessage="Re-entry Period"
          />
        }
        {...formItemProps}
      >
        <InputNumber
          addonAfter={
            <Form.Item name="entryPeriodUnitId" compact>
              <UnitSelect
                unitTypes={[UnitType.Time]}
                abbrLabel={false}
                style={{ minWidth: "120px" }}
              />
            </Form.Item>
          }
        />
      </Form.Item>

      <Form.Item
        name="preHarvestInterval"
        label={
          <FormattedMessage
            id="items.preHarvestInterval"
            defaultMessage="Pre-Harvest Interval"
          />
        }
        {...formItemProps}
      >
        <InputNumber
          addonAfter={
            <Form.Item name="preHarvestIntervalUnitId" compact>
              <UnitSelect
                unitTypes={[UnitType.Time]}
                abbrLabel={false}
                style={{ minWidth: "120px" }}
              />
            </Form.Item>
          }
        />
      </Form.Item>

      <Form.Item
        name={"equipmentIds"}
        label={<FormattedMessage id="equipment" defaultMessage="PPE" />}
        {...formItemProps}
      >
        <SelectField optionsHook={useEquipmentOptions} mode="multiple" />
      </Form.Item>

      <Form.Item
        name={"diseaseIds"}
        label={
          <FormattedMessage
            id="items.diseases"
            defaultMessage="Target Diseases"
          />
        }
        {...formItemProps}
      >
        <SelectField
          optionsHook={useDiseasesOptions}
          mode="multiple"
          showGroups
        />
      </Form.Item>

      <Form.Item
        name="tags"
        label={<FormattedMessage id="tags" />}
        {...formItemProps}
      >
        <TagSelect />
      </Form.Item>
    </>
  );
}
