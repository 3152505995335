import Icon from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { WorkOrderBuilder } from "..";
import { WorkOrderInfrastructures } from "../../WorkOrderInfrastructures";
import { WorkOrderProcessingPlants } from "../../WorkOrderProcessingPlants";
import { createCostCenterSection } from "./CostCenters";

export function Infrastructures(builder: WorkOrderBuilder) {
  return createCostCenterSection({
    name: (
      <FormattedMessage
        id="workOrders.infrastructures"
        defaultMessage="infrastructures"
      />
    ),
    title: (
      <FormattedMessage
        id="workOrders.infrastructures.buildings"
        defaultMessage="buildings"
      />
    ),
    description: (
      <FormattedMessage
        id="workOrders.infrastructures.buildings.hint"
        defaultMessage="hint"
      />
    ),
    ccTypeText: (
      <FormattedMessage
        id="infrastructures.entityName.lower"
        defaultMessage="infrastructure"
      />
    ),
    icon: (
      <Icon
        component={() => <img src="/images/icons/building-structure.svg" />}
      />
    ),
    render: (byPercentage) => (
      <WorkOrderInfrastructures byPercentage={byPercentage} />
    ),
    builder,
  });
}

export function ProcessingPlants(builder: WorkOrderBuilder) {
  return createCostCenterSection({
    name: (
      <FormattedMessage
        id="workOrders.infrastructures.processing"
        defaultMessage="Processing Plants"
      />
    ),
    title: (
      <FormattedMessage
        id="workOrders.infrastructures.processing"
        defaultMessage="Processing Plants"
      />
    ),
    description: (
      <FormattedMessage
        id="workOrders.infrastructures.processing.hint"
        defaultMessage="hint"
      />
    ),
    ccTypeText: (
      <FormattedMessage
        id="infrastructures.processingPlants.lower"
        defaultMessage="processing plant"
      />
    ),
    icon: (
      <Icon
        component={() => (
          <img src="/images/icons/processing-plant.svg" width={42} />
        )}
      />
    ),
    render: (byPercentage) => (
      <WorkOrderProcessingPlants byPercentage={byPercentage} />
    ),
    builder,
  });
}
