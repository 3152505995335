import { Button, Tooltip, Typography } from "antd";
import {
  DateTimeType,
  formatUnitValue,
  formatWarehouse,
} from "../../../../lib/formats";
import {
  useCurrentUser,
  useStockWarehouseOptions,
} from "../../../../lib/hooks";
import { WorkOrderVariant } from "../builder/tools";
import { Popover, Space, Table } from "../../../shared";
import { FormattedMessage } from "react-intl";
import { ReconciliationTwoTone, ReloadOutlined } from "@ant-design/icons";
import { useEffectOnce } from "react-use";

interface CurrentStockContentProps {
  variant: WorkOrderVariant;
  localityId?: string;
  date?: DateTimeType;
}
export function CurrentStockContent({
  variant,
  date,
  localityId,
}: CurrentStockContentProps) {
  const { currentTenant } = useCurrentUser();
  const showAllLocalities = !!currentTenant.multifarmInventoryEnabled;

  const { load, loading, options } = useStockWarehouseOptions(
    {
      variables: {
        variantId: variant.variant.id,
        unitId: variant.unit?.id,
        date: date,
        filter: {
          localityId:
            localityId && !showAllLocalities ? [localityId] : undefined,
        },
      },
      fetchPolicy: "no-cache",
    },
    showAllLocalities
  );

  useEffectOnce(() => {
    load();
  });

  return (
    <Table
      size="small"
      loading={loading}
      dataSource={options.filter((o) => o.warehouse.stock.onHand)}
      pagination={false}
      columns={[
        {
          title: <FormattedMessage id="warehouses.entityName" />,
          render: (_, w) =>
            showAllLocalities ? (
              <Typography.Text strong={w.warehouse.locality.id == localityId}>
                {formatWarehouse(w.warehouse)}
              </Typography.Text>
            ) : (
              w.warehouse.name
            ),
        },
        {
          title: (
            <Space>
              <FormattedMessage id="inventoryRequests.currentStock" />
              <Button
                size="small"
                shape="circle"
                icon={<ReloadOutlined />}
                onClick={() => load({})}
              />
            </Space>
          ),
          align: "right",
          render: (_, w) =>
            formatUnitValue(w.warehouse.stock.onHand, variant.unit),
        },
      ]}
    />
  );
}

export function CurrentStockTooltip(props: CurrentStockContentProps) {
  return (
    <Popover content={<CurrentStockContent {...props} />}>
      <Tooltip
        title={<FormattedMessage id="inventoryRequests.currentStock" />}
        placement="bottom"
      >
        <Button size="small" shape="circle" icon={<ReconciliationTwoTone />} />
      </Tooltip>
    </Popover>
  );
}
