import { FormattedMessage } from "react-intl";

import {
  formatDetailsLink,
  formatPlaceholder,
  formatUnitValue,
} from "../../../lib/formats";
import routes from "../../../lib/routes";
import { useContext } from "react";
import {
  ProfitableType,
  InfrastructureCategory,
  InfrastructureFragment,
} from "../../../lib/graphql";
import { useInfrastructureOptions } from "../../../lib/hooks";

import { WorkOrderContext } from "./WorkOrderContext";
import { WorkOrderCostCenters } from "./costCenters";

export function WorkOrderProcessingPlants({
  byPercentage,
}: {
  byPercentage?: boolean;
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const filter = {
    localityId: [workOrder.locality.id],
    category: [InfrastructureCategory.Processing],
  };

  return (
    <WorkOrderCostCenters
      byPercentage={byPercentage}
      tableSelectProps={{
        optionsHook: useInfrastructureOptions,
        showGroups: true,
        optionsHookParams: { variables: { filter, pageSize: 75 } },
        placeholder: formatPlaceholder({
          id: "workOrders.infrastructures.processing",
        }),
        entityById: (
          _,
          {
            infrastructure,
          }: {
            infrastructure: InfrastructureFragment;
          }
        ) => {
          const defaultCostCenter = {
            id: "",
            name: "",
          };

          return {
            id: "",
            dayGoal: 0,
            totalProgress: 0,
            plannedProgress: 0,
            employees: [],
            machines: [],
            variants: [],
            metrics: builder.costCenters.metrics.build(),
            profitableId: infrastructure.id,
            profitableType: ProfitableType.Infrastructure,
            profitableName: infrastructure.name,
            costCenter: infrastructure.costCenter || defaultCostCenter,
            infrastructure,
          };
        },
        beforeAdd: () => {
          builder.costCenters.removeAll();
          builder.outputs.removeProcessingOutputs();
        },
      }}
      columns={[
        {
          title: <FormattedMessage id="name" />,
          dataIndex: "name",
          render: (_, c) =>
            formatDetailsLink({
              id: c.profitableId || "",
              route: routes.infrastructures.details,
              title: c.profitableName,
            }),
        },
        {
          title: (
            <FormattedMessage
              id="costCenters.entityName"
              defaultMessage="costCenter"
            />
          ),
          dataIndex: "costCenter",
          render: (_, c) => c.costCenter.name,
        },
        {
          title: <FormattedMessage id="total" defaultMessage="total" />,
          dataIndex: ["infrastructure", "total"],
          render: (_, c) =>
            formatUnitValue(c.infrastructure?.total, c.infrastructure?.unit),
        },
      ]}
    />
  );
}
