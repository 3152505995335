import React from "react";
import { FinanceInvoiceFragment, InvoiceStatus } from "../../lib/graphql";
import { ActionButton } from "../shared";
import { useItemSidebarContext, usePermissions } from "../../lib/hooks";
import { ItemType } from "antd/lib/menu/hooks/useItems";

export function InvoiceActionButton({
  invoice,
}: {
  invoice: FinanceInvoiceFragment;
}) {
  const { setCurrentAction } = useItemSidebarContext();
  const canRevert = usePermissions((p) => p.financeOrder?.delete);

  const actions: ItemType[] = [ActionButton.CopyLinkMenuItem()];

  if (invoice.invoiceStatus === InvoiceStatus.Completed) {
    actions.push(
      ActionButton.DownloadPDF({ onClick: () => setCurrentAction("pdf") })
    );
  }

  if (invoice.invoiceStatus !== InvoiceStatus.Reverted && canRevert) {
    actions.push(
      { type: "divider" },
      ActionButton.Revert(() => setCurrentAction("revert"))
    );
  }

  return <ActionButton menuItems={actions} />;
}
