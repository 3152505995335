import { InventoryRequestDetailsFragment } from "../../../lib/graphql";
import { FormattedMessage } from "react-intl";
import { TableInput } from "../../shared";
import {
  formatUnitValue,
  colors,
  formatUnitValueConversion,
  formatVariantLink,
} from "../../../lib/formats";
import { Form, Rules, RuleBuilder, InputNumber } from "../../form";
import { StockWarehouseSelect } from "../../warehouses";
import { CheckCircleTwoTone } from "@ant-design/icons";

export function InventoryReturnVariants({
  request,
  readonly,
}: {
  request: InventoryRequestDetailsFragment;
  readonly?: boolean;
}) {
  return (
    <TableInput
      name="variants"
      dataSource={request.variants}
      rowKey={(f) => f.variant.id}
      disabled={readonly}
      tableProps={{
        rowClassName: (r) =>
          r.returnedAmount && r.returnedAmount > 0 ? "" : "hide",
      }}
      hideMenu
      columns={[
        {
          title: <FormattedMessage id="variants.entityName" />,
          dataIndex: ["variant", "id"],
          render: (_, r) => formatVariantLink(r.variant),
        },
        {
          title: (
            <FormattedMessage
              id="variants.qtyToReturn"
              defaultMessage="qtyToReturn"
            />
          ),
          dataIndex: "totalAmount",
          render: (_, r) =>
            r.returnedAmount &&
            formatUnitValueConversion(r.returnedAmount, {
              unit: r.unit,
              conversionFactor: r.conversionFactor,
              conversionUnit: r.variant.variationUnit,
              inverse: true,
            }),
        },
        {
          title: (
            <FormattedMessage
              id="inventoryRequests.returnWarehouse"
              defaultMessage="returnWarehouse"
            />
          ),
          dataIndex: "returnWarehouse",
          width: 300,
          render: (_, r, index) =>
            readonly ? (
              r.returnWarehouse?.name
            ) : (
              <Form.Item
                name={["variants", index, "returnWarehouseId"]}
                compact
              >
                <StockWarehouseSelect
                  variant={r.variant}
                  unit={r.unit}
                  localityId={request.workOrder.locality.id}
                  date={request.workOrder.documentDate}
                  labelRenderer={(label) => {
                    return (
                      <span>
                        <CheckCircleTwoTone
                          twoToneColor={colors.successColor}
                          style={{ marginRight: "8px" }}
                        />

                        {label}
                      </span>
                    );
                  }}
                />
              </Form.Item>
            ),
        },
        {
          title: (
            <FormattedMessage
              id="inventoryRequests.returns"
              defaultMessage="returns"
            />
          ),
          dataIndex: "confirmedReturnedAmount",
          width: 200,
          render: (_: any, v, index: number) =>
            readonly ? (
              formatUnitValue(v.confirmedReturnedAmount, v.unit, null, {
                maximumFractionDigits: 4,
              })
            ) : (
              <Form.Item
                compact
                name={["variants", index, "confirmedReturnedAmount"]}
                rules={
                  v.returnedAmount
                    ? [Rules.gtEqZero, RuleBuilder.ltEq(v.returnedAmount || 0)]
                    : []
                }
              >
                <InputNumber
                  step={0.1}
                  min={0}
                  precision={4}
                  addonAfter={v.unit.abbr}
                />
              </Form.Item>
            ),
        },
      ]}
    />
  );
}
